import React, { useMemo, useState } from 'react'
import { Dialog, DialogContent, DialogFooter, DialogHeader, DialogTrigger } from '../ui/dialog'
import { Button } from '../ui/button'
import { Separator } from '../ui/separator'
import { UserProfile, useAuth } from '@/hooks/use-auth'
import { useTranslation } from 'react-i18next'
import { i18n, t } from 'i18next'
import ReactCountryFlag from 'react-country-flag'
import { getAuthUrl } from '@/lib/utils'
import DefaultProfilePicture from '../../assets/default-profile-picture.png'
import { Input } from '../ui/input'
import { useForm } from 'react-hook-form'
import { Check, UserCircle } from 'lucide-react'
import { Select, SelectContent, SelectItem, SelectTrigger } from '../ui/select'
import { Language, User, updateUser } from '@/api/users'
import { useMutation } from '@tanstack/react-query'

interface ProfileModalProps {
    user: UserProfile
    i18n: i18n
    languages: {
        lng: Language
        label: string
        flag: string
        disabled: boolean
    }[]
    setOpen: React.Dispatch<React.SetStateAction<boolean>>
    setContent: React.Dispatch<React.SetStateAction<'edit' | 'view'>>
}

const getFormattedTimezone = () => {
    const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone
    const city = timezone.split('/')[1].split('_').join(' ')

    const timezoneIdent = new Date()
        .toLocaleTimeString('en-us', { timeZoneName: 'short' })
        .split(' ')[2]

    return `${timezoneIdent} - ${city}`
}

const ProfileModal: React.FC = () => {
    const [open, setOpen] = useState(false)
    const [content, setContent] = useState<'edit' | 'view'>('view')
    const { user } = useAuth()
    const { i18n } = useTranslation()

    const languages: {
        lng: Language
        label: string
        flag: string
        disabled: boolean
    }[] = [
        { lng: 'en', label: t('english'), flag: 'US', disabled: false },
        { lng: 'es', label: t('spanish'), flag: 'ES', disabled: false },
        { lng: 'it', label: t('italian'), flag: 'IT', disabled: true },
        { lng: 'de', label: t('german'), flag: 'DE', disabled: true },
        { lng: 'fr', label: t('french'), flag: 'FR', disabled: true },
        { lng: 'pt', label: t('portuguese'), flag: 'PT', disabled: true },
    ]

    return (
        <Dialog open={open} onOpenChange={setOpen}>
            <DialogTrigger>
                <UserCircle />
            </DialogTrigger>
            <DialogContent className="max-w-modal-md p-0 gap-0">
                {content === 'view' ? (
                    <ProfileView
                        setContent={setContent}
                        setOpen={setOpen}
                        user={user!}
                        i18n={i18n}
                        languages={languages}
                    />
                ) : (
                    <ProfileEdit
                        setContent={setContent}
                        setOpen={setOpen}
                        user={user!}
                        i18n={i18n}
                        languages={languages}
                    />
                )}
            </DialogContent>
        </Dialog>
    )
}

const ProfileView: React.FC<ProfileModalProps> = ({ user, i18n, languages }) => {
    const { flag, label } = useMemo(
        () => languages.find((lang) => lang.lng === i18n.language) ?? languages[0],
        [i18n.language, languages.find, languages[0]]
    )
    const roles = user?.groups.map((group) => group.name).join(', ')

    const changeLanguage = (lng: Language) => {
        i18n.changeLanguage(lng)
    }

    return (
        <>
            <DialogHeader className="p-lg pb-0">Profile</DialogHeader>
            <div className="grid grid-cols-4 gap-md p-lg">
                <div className="col-span-1 flex justify-center">
                    <img className="w-full h-fit" src={DefaultProfilePicture} />
                </div>
                <div className="col-span-3 flex flex-col justify-center text-gray-950 gap-sm text-base">
                    <div className="flex flex-row justify-between">
                        <p className="text-gray-450">First Name</p>
                        <p>{user?.firstName}</p>
                    </div>
                    <div className="flex flex-row justify-between">
                        <p className="text-gray-450">Last Name</p>
                        <p>{user?.lastName}</p>
                    </div>
                    <div className="flex flex-row justify-between">
                        <p className="text-gray-450">{roles.length > 1 ? 'Roles' : 'Role'}</p>
                        <p title={roles} className="line-clamp-1 text-ellipsis max-w-72">
                            {roles ?? '---'}
                        </p>
                    </div>
                    <Separator />
                    <div className="flex flex-row justify-between">
                        <p className="text-gray-450">Work Email</p>
                        <p>{user?.email ?? '---'}</p>
                    </div>
                    {/* <div className="flex flex-row justify-between">
                        <p className="text-gray-450">Work Phone</p>
                        <p>{user?.phoneNumber ?? '---'}</p>
                    </div> */}
                    <Separator />
                    <div className="flex flex-row justify-between">
                        <p className="text-gray-450">Time zone</p>
                        <p>{getFormattedTimezone()}</p>
                    </div>
                    <div className="flex flex-row justify-between items-center">
                        <p className="text-gray-450">Language</p>
                        {/* <div className="flex flex-row items-center gap-1">
                            <ReactCountryFlag
                                countryCode={flag}
                                svg
                                style={{
                                    width: '1.25em',
                                    height: '1.25em',
                                    marginRight: '0.5em',
                                    verticalAlign: 'middle',
                                }}
                            />
                            {label}
                        </div> */}
                        <Select
                            key={i18n.language}
                            value={label}
                            disabled
                            onValueChange={(e) => changeLanguage(e as Language)}
                        >
                            <SelectTrigger className="max-w-48">
                                <div className="flex flex-row items-center">
                                    <ReactCountryFlag
                                        countryCode={flag}
                                        svg
                                        style={{
                                            width: '1.25em',
                                            height: '1.25em',
                                            marginRight: '0.5em',
                                            verticalAlign: 'middle',
                                        }}
                                    />
                                    {label}
                                </div>
                            </SelectTrigger>
                            <SelectContent>
                                {languages.map(({ lng, label, flag, disabled }) => (
                                    <SelectItem key={lng} disabled={disabled} value={lng}>
                                        <div className="flex flex-row justify-between items-center w-full">
                                            <div className="flex flex-row gap-2 items-center w-full">
                                                <ReactCountryFlag
                                                    countryCode={flag}
                                                    svg
                                                    style={{
                                                        width: '1.25em',
                                                        height: '1.25em',
                                                        marginRight: '0.5em',
                                                        verticalAlign: 'middle',
                                                    }}
                                                />
                                                {label}
                                            </div>
                                            {i18n.language === lng && (
                                                <Check className="ml-2 h-4 w-4" />
                                            )}
                                        </div>
                                    </SelectItem>
                                ))}
                            </SelectContent>
                        </Select>
                    </div>
                    <Separator />
                    <div className="flex flex-row justify-between">
                        <p className="text-gray-450">Appearance</p>
                        <p>Light</p>{' '}
                    </div>
                    <Separator />
                    <a
                        className="self-end text-error"
                        href={
                            import.meta.env.DEV
                                ? 'https://localhost:5001/auth/logout'
                                : `${getAuthUrl()}/MicrosoftIdentity/Account/SignOut`
                        }
                    >
                        {t('logout')}
                    </a>
                </div>
            </div>
            {/* <DialogFooter className="border-t-[1px] p-lg">
                <Button type="button" onClick={() => setContent('edit')}>
                    Edit Profile
                </Button>
            </DialogFooter> */}
        </>
    )
}

const ProfileEdit: React.FC<ProfileModalProps> = ({
    user,
    i18n,
    languages,
    setContent,
    setOpen,
}) => {
    const { register, handleSubmit, formState, reset } = useForm<UserProfile>({
        defaultValues: user,
    })

    const handleSave = async (data: UserProfile) => {
        const { profile, ...rest } = data
        const user = rest

        user.language = i18n.language as Language
        console.log(user)
        await updateProfileMutation.mutate(user as User)
    }

    const handleCancel = () => {
        i18n.changeLanguage(user?.language ?? 'en')
        reset()
        setContent('view')
    }

    const changeLanguage = (lng: Language) => {
        i18n.changeLanguage(lng)
    }

    const { flag, label } = useMemo(
        () => languages.find((lang) => lang.lng === i18n.language)!,
        [i18n.language, languages.find]
    )

    const updateProfileMutation = useMutation({
        mutationFn: (data: User) => updateUser(data),
        onSuccess: () => {
            // navigate({ to: '/$projectId/tools', params: { projectId: params.projectId } })
            // queryClient.invalidateQueries({ queryKey: [QueryKeys.ALL_TOOLS] })
            // toast({
            //     title: 'Success!',
            //     description: 'Tool successfully created.',
            //     variant: 'default',
            // })
            setOpen(false)
            setContent('view')
        },
        onError: () => {
            // toast({
            //     title: t('error_title'),
            //     description: t('error_description_generic'),
            //     variant: 'destructive',
            // })
        },
    })

    return (
        <form onSubmit={handleSubmit(handleSave)}>
            <DialogHeader className="p-lg pb-0">Edit Profile</DialogHeader>
            <div className="flex flex-col justify-center w-full gap-md p-lg text-gray-950 text-base">
                <div className="flex flex-col gap-md w-full">
                    <p>Profile Picture</p>
                    <img className="w-20 h-fit" src={DefaultProfilePicture} />
                </div>
                <Separator />
                <div className="flex flex-row w-full justify-center gap-md">
                    <div className="flex flex-col gap-1 w-full">
                        <p>First name</p>
                        <Input
                            {...register('firstName', { required: true })}
                            placeholder="First name"
                        />
                    </div>
                    <div className="flex flex-col gap-1 w-full">
                        <p>Last name</p>
                        <Input
                            {...register('lastName', { required: true })}
                            placeholder="Last name"
                        />
                    </div>
                </div>
                <div className="flex flex-col gap-1 w-full">
                    <p>Work email</p>
                    <Input {...register('email', { required: true })} placeholder="Work email" />
                </div>
                <Separator />
                <div className="flex flex-row w-full justify-center gap-md">
                    <div className="flex flex-col gap-1 w-full">
                        <p>Language</p>
                        <Select
                            key={i18n.language}
                            value={label}
                            onValueChange={(e) => changeLanguage(e as Language)}
                        >
                            <SelectTrigger>
                                <div className="flex flex-row items-center">
                                    <ReactCountryFlag
                                        countryCode={flag}
                                        svg
                                        style={{
                                            width: '1.25em',
                                            height: '1.25em',
                                            marginRight: '0.5em',
                                            verticalAlign: 'middle',
                                        }}
                                    />
                                    {label}
                                </div>
                            </SelectTrigger>
                            <SelectContent>
                                {languages.map(({ lng, label, flag, disabled }) => (
                                    <SelectItem key={lng} disabled={disabled} value={lng}>
                                        <div className="flex flex-row justify-between items-center w-full">
                                            <div className="flex flex-row gap-2 items-center w-full">
                                                <ReactCountryFlag
                                                    countryCode={flag}
                                                    svg
                                                    style={{
                                                        width: '1.25em',
                                                        height: '1.25em',
                                                        marginRight: '0.5em',
                                                        verticalAlign: 'middle',
                                                    }}
                                                />
                                                {label}
                                            </div>
                                            {i18n.language === lng && (
                                                <Check className="ml-2 h-4 w-4" />
                                            )}
                                        </div>
                                    </SelectItem>
                                ))}
                            </SelectContent>
                        </Select>
                    </div>
                    <div className="flex flex-col gap-1 w-full">
                        <p>Timezone</p>
                        <Input defaultValue={getFormattedTimezone()} disabled />
                    </div>
                </div>
            </div>
            <DialogFooter className="border-t-[1px] p-lg">
                <Button variant="secondary" onClick={() => handleCancel()}>
                    {t('cancel')}
                </Button>
                <Button disabled={!formState.isValid || !formState.isDirty}>{t('save')}</Button>
            </DialogFooter>
        </form>
    )
}

export default ProfileModal
