import React from 'react'
import { useTranslation } from 'react-i18next'
import { cn } from '@/lib/utils'
import { Link } from '@tanstack/react-router'
import { DropdownMenuItem } from '../ui/dropdown-menu'
import { CustomSubMenuFooterProps } from './CustomSubMenuFooter'

const CustomDropdownMenuFooter: React.FC<CustomSubMenuFooterProps> = ({ menuLabel }) => {
    const { t } = useTranslation()

    switch (menuLabel) {
        case 'Projects':
            return (
                <DropdownMenuItem
                    className="focus:bg-primary-100 focus:text-primary"
                    key={menuLabel}
                    asChild
                >
                    <Link
                        to="/projects"
                        className={cn(
                            'flex rounded-[8px] items-center text-sm hover:bg-primary-100 transition-colors h-9 text-primary cursor-pointer'
                        )}
                        activeProps={{
                            className: 'text-primary bg-primary-100 hover:bg-accent',
                        }}
                        activeOptions={{
                            exact: false,
                        }}
                    >
                        {t('view_all_projects')}
                    </Link>
                </DropdownMenuItem>
            )
        default:
            return null
    }
}

export default CustomDropdownMenuFooter
