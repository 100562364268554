import { cn } from '@/lib/utils'
import {
    ColumnDef,
    ColumnSort,
    flexRender,
    getCoreRowModel,
    getPaginationRowModel,
    getSortedRowModel,
    useReactTable,
} from '@tanstack/react-table'
import { Dispatch, SetStateAction } from 'react'
import { DataTablePagination } from './pagination/tablePagination'
import { Table, TableBody, TableCell, TableHead, TableHeader, TableRow } from './ui/table'
import { ChevronDown, ChevronUp } from 'lucide-react'

interface DataTableProps<TData, TValue> {
    columns: ColumnDef<TData, TValue>[]
    data: TData[]
    onRowClick?: (row: any) => void
    pagination?: boolean
    rowsPerPage?: number
    serverPagination?: boolean
    totalCount?: number
    onPaginationChange?: Dispatch<
        SetStateAction<{
            pageSize: number
            pageIndex: number
        }>
    >
    onSortingChange?: Dispatch<
        SetStateAction<
            {
                id: string
                desc: boolean
            }[]
        >
    >
    paginationState?: { pageSize: number; pageIndex: number }
    sortingState?: ColumnSort[]
    limit?: number
    className?: string
    /**
     * If set to true, pagination will be reset to the first page when page-altering state changes
     * eg. data is updated, filters change, grouping changes, etc.
     * Note: This option defaults to false if serverPagination is set to true
     * https://tanstack.com/table/v8/docs/api/features/pagination#autoresetpageindex
     */
    autoResetPageIndex?: boolean
}

export function DefaultDataTable<TData, TValue>({
    columns,
    data,
    onRowClick,
    pagination,
    rowsPerPage,
    serverPagination,
    totalCount,
    onPaginationChange,
    onSortingChange,
    paginationState,
    sortingState,
    limit,
    className,
    autoResetPageIndex,
}: DataTableProps<TData, TValue>) {
    const pageCount = Math.ceil((totalCount ?? 0) / (limit ?? 1))

    const table = useReactTable(
        serverPagination
            ? {
                  data: data,
                  columns,
                  getCoreRowModel: getCoreRowModel(),
                  manualPagination: true,
                  manualSorting: true,
                  onPaginationChange: onPaginationChange,
                  onSortingChange: onSortingChange,
                  pageCount: pageCount,
                  state: {
                      pagination: paginationState,
                      sorting: sortingState,
                  },
              }
            : pagination
              ? {
                    data: data,
                    columns,
                    getCoreRowModel: getCoreRowModel(),
                    getSortedRowModel: getSortedRowModel(),
                    getPaginationRowModel: getPaginationRowModel(),
                    autoResetPageIndex: autoResetPageIndex,
                    initialState: {
                        pagination: {
                            pageSize: rowsPerPage ?? 10,
                        },
                    },
                }
              : {
                    data: data,
                    columns,
                    getCoreRowModel: getCoreRowModel(),
                    getSortedRowModel: getSortedRowModel(),
                }
    )

    return (
        <div className={cn('flex flex-col gap-2', className)}>
            <div className="rounded-md flex flex-col justify-between">
                <Table>
                    <TableHeader className="bg-background cursor-pointer sticky top-0">
                        {table.getHeaderGroups().map((headerGroup) => (
                            <TableRow key={headerGroup.id}>
                                {headerGroup.headers.map((header) => {
                                    return (
                                        <TableHead
                                            key={header.id}
                                            {...(header.column.getCanSort()
                                                ? {
                                                      onClick:
                                                          header.column.getToggleSortingHandler(),
                                                  }
                                                : {})}
                                        >
                                            <div
                                                className={
                                                    'flex items-center select-none cursor-pointer'
                                                }
                                            >
                                                {header.isPlaceholder
                                                    ? null
                                                    : flexRender(
                                                          header.column.columnDef.header,
                                                          header.getContext()
                                                      )}
                                                {{
                                                    asc: <ChevronUp />,
                                                    desc: <ChevronDown />,
                                                }[header.column.getIsSorted() as string] ?? null}
                                            </div>
                                        </TableHead>
                                    )
                                })}
                            </TableRow>
                        ))}
                    </TableHeader>
                    <TableBody>
                        {table.getRowModel().rows?.length ? (
                            table.getRowModel().rows.map((row) => (
                                <TableRow
                                    key={row.id}
                                    data-state={row.getIsSelected() && 'selected'}
                                    className={cn(
                                        'hover:bg-background-hover dark:hover:bg-muted/50',
                                        onRowClick && 'hover:cursor-pointer'
                                    )}
                                >
                                    {row.getVisibleCells().map((cell) => (
                                        <TableCell
                                            onClick={() =>
                                                cell.column.id !== 'actions' &&
                                                onRowClick &&
                                                onRowClick(row)
                                            }
                                            className="py-2 text-[13px]"
                                            key={cell.id}
                                        >
                                            {flexRender(
                                                cell.column.columnDef.cell,
                                                cell.getContext()
                                            )}
                                        </TableCell>
                                    ))}
                                </TableRow>
                            ))
                        ) : (
                            <TableRow>
                                <TableCell colSpan={columns.length} className="h-24 text-center">
                                    No results.
                                </TableCell>
                            </TableRow>
                        )}
                    </TableBody>
                </Table>
            </div>
            {!!(
                (pagination || serverPagination) &&
                totalCount &&
                rowsPerPage &&
                totalCount >= rowsPerPage
            ) && (
                <DataTablePagination
                    table={table}
                    rowsPerPage={rowsPerPage}
                    totalCount={totalCount}
                />
            )}
        </div>
    )
}
