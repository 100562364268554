import React from 'react'
import { ExternalLink, Pin } from 'lucide-react'
import { cn, getChatUrl } from '@/lib/utils'
import { DropdownMenuItem } from '../ui/dropdown-menu'
import { Link } from '@tanstack/react-router'
import { useSideNavStore } from '@/stores/SideNavStore'
import { CustomSubMenuItemProps } from './CustomSubMenuItem'

const CustomDropdownMenuItem: React.FC<CustomSubMenuItemProps> = ({
    menuLabel,
    subMenu,
    visibleProjects,
}) => {
    const { path, params, id, disabled, label } = subMenu
    const { setRecentProjectIds, recentProjectIds } = useSideNavStore()
    const chatUrl = getChatUrl()

    switch (menuLabel) {
        case 'Projects': {
            const project = visibleProjects?.find((project) => project.id === id)
            if (!project) return null

            const RECENT_PROJECT_IDS_KEY = 'recentProjectIds'
            const LAST_ACCESSED_PROJECT_KEY = 'lastAccessedProject'

            const updateRecentProjects = (selectedProjectId: string) => {
                const updatedRecent = [
                    selectedProjectId,
                    ...recentProjectIds.filter((id) => id !== selectedProjectId),
                ].slice(0, 5)

                setRecentProjectIds(updatedRecent)

                localStorage.setItem(RECENT_PROJECT_IDS_KEY, JSON.stringify(updatedRecent))
                localStorage.setItem(LAST_ACCESSED_PROJECT_KEY, selectedProjectId)
            }
            return (
                <DropdownMenuItem
                    className="focus:bg-primary-100 focus:text-primary"
                    disabled={disabled}
                    key={label}
                    asChild
                >
                    <Link
                        className={
                            'flex justify-between rounded-[8px] items-center text-sm hover:bg-primary-100 hover:text-primary px-2 h-9 cursor-pointer'
                        }
                        activeProps={{
                            className:
                                'text-primary bg-primary-100 hover:bg-primary-100 hover:text-primary',
                        }}
                        activeOptions={{
                            exact: false,
                        }}
                        to={path}
                        params={params && params}
                        onClick={() =>
                            updateRecentProjects && updateRecentProjects(params?.projectId ?? '')
                        }
                    >
                        <p className="max-w-[180px] truncate">{label}</p>
                        {project && project.isPinned ? (
                            <Pin className={cn('w-4 h-4 text-primary-900')} />
                        ) : undefined}
                    </Link>
                </DropdownMenuItem>
            )
        }
        case 'Apps': {
            return (
                <DropdownMenuItem
                    className="focus:bg-primary-100 focus:text-primary"
                    disabled={disabled}
                    key={label}
                    asChild
                >
                    <a
                        className={cn(
                            'flex rounded-[8px] justify-between items-center text-sm hover:bg-primary-100 transition-colors px-2 h-8 select-none'
                        )}
                        href={chatUrl}
                        target="_blank"
                        rel="noreferrer"
                    >
                        Airia Chat
                        <ExternalLink className="w-4 h-4 text-inherit" />
                    </a>
                </DropdownMenuItem>
            )
        }
        default:
            return null
    }
}

export default CustomDropdownMenuItem
