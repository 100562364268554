import { MessageType } from '@/api/conversation'
import { getPipelineExecutionLogs } from '@/api/pipelineExecution'
import Coins from '@/assets/icons/coins.svg?react'
import { ImagePreviewDialog } from '@/components/chat/ImagePreviewDialog.tsx'
import { capitalizeFirstLetter, getBase64String, isBase64Image } from '@/lib/utils.ts'
import { AlertTriangle, Braces, Clock } from 'lucide-react'
import { Fragment, memo, useState } from 'react'
import { useTranslation } from '@/lib/i18n'
import { ExecutionLogsDialog } from './executionLogsDialog'
import { preprocessMessage } from './preprocessMessage'
import {
    Tooltip,
    TooltipContent,
    TooltipProvider,
    TooltipTrigger,
} from '@/components/ui/tooltip.tsx'
import { PipelinePlaygroundExecutions } from '@/api/PipelinePlaygroundExecutions'

export const IndividualMessage = ({
    role,
    index,
    conversationId,
    id,
    message,
    images,
    createdAt,
    requestCompleted,
    pipelineIndex,
    pipelinePlaygroundExecutions,
    duration,
    totalTokens,
}: MessageType & {
    conversationId: string
    index: number
    pipelineIndex?: number
    pipelinePlaygroundExecutions?: PipelinePlaygroundExecutions
}) => {
    const { t } = useTranslation()
    const [openPreviewDialog, setOpenPreviewDialog] = useState(false)
    const [previewImageUrl, setPreviewImageUrl] = useState('')

    const [openExecutionLogsDialog, setOpenExecutionLogsDialog] = useState(false)
    const [executionLogs, setExecutionLogs] = useState('')
    const isBackupPipeline = pipelinePlaygroundExecutions?.getExecutionResults(
        pipelineIndex ?? 0
    )?.[index]?.isBackupPipeline

    const renderBase64Image = () => {
        if (message && isBase64Image(message) && role !== 'user') {
            return (
                <div
                    onClick={() => {
                        setPreviewImageUrl(getBase64String(message))
                        setOpenPreviewDialog(true)
                    }}
                >
                    <img
                        src={getBase64String(message)}
                        className="w-[300px] pt-2"
                        alt={t('imgAlt.messageImg')}
                    />
                </div>
            )
        } else {
            return null
        }
    }

    const getExecutionLogs = async (index: number, pipelineIndex?: number) => {
        const pipelineExecutionResults = pipelinePlaygroundExecutions?.getExecutionResults(
            pipelineIndex ?? 0
        )
        if (!pipelineExecutionResults || pipelineExecutionResults.length === 0) {
            return { error: t('execution_logs.no_results') }
        }

        if (!pipelineExecutionResults[index]) {
            return { error: t('execution_logs.no_result_for_index', { index }) }
        }

        const { stream, executionId, report } = pipelineExecutionResults[index]

        if (stream && executionId) {
            try {
                const logs = await getPipelineExecutionLogs(executionId)
                return logs
            } catch (_error) {
                return { error: t('execution_logs.fetch_failed') }
            }
        } else if (report) {
            return report
        } else {
            return { error: t('execution_logs.no_data') }
        }
    }

    const showReport = async (index: number, pipelineIndex?: number) => {
        const data = await getExecutionLogs(index, pipelineIndex)
        if (data) {
            setExecutionLogs(JSON.stringify(data, null, 4))
        }
        setOpenExecutionLogsDialog(true)
    }

    const getCreatedAt = () => {
        if (createdAt) {
            const parsedDate = Date.parse(createdAt)
            if (!isNaN(parsedDate)) {
                return new Date(createdAt).toLocaleString()
            }
        }
        return new Date().toLocaleString()
    }

    const numberFormatter = Intl.NumberFormat('en-US')

    return (
        <div
            className="py-4 px-2 flex gap-1 whitespace-pre-wrap text-pretty"
            key={`div-${id}-${conversationId}`}
        >
            <Fragment key={`${index}-${role}-${conversationId}`}>
                <div className="flex flex-col" key={`chat-${index}`}>
                    <div className="flex items-center gap-2">
                        <h4 className="font-bold " key={`sendName-${index}`}>
                            {capitalizeFirstLetter(role ?? '')}
                        </h4>
                        {role == 'assistant' && (
                            <>
                                {totalTokens != 0 && (
                                    <span className="flex flex-row gap-0.5 justify-center items-center">
                                        <Coins className="w-4" stroke="currentcolor" />
                                        {numberFormatter.format(totalTokens ?? 0)}
                                    </span>
                                )}
                                <span className="flex flex-row gap-0.5 justify-center items-center">
                                    <Clock className="w-4" stroke="currentcolor" />
                                    {numberFormatter.format(Math.round((duration ?? 0) * 1000))}ms
                                </span>
                                {isBackupPipeline && (
                                    <TooltipProvider>
                                        <Tooltip>
                                            <TooltipTrigger>
                                                <AlertTriangle className="w-4 h-4 text-yellow-500" />
                                            </TooltipTrigger>
                                            <TooltipContent>
                                                {
                                                    'This is a result from a backup pipeline. The main pipeline failed.'
                                                }
                                            </TooltipContent>
                                        </Tooltip>
                                    </TooltipProvider>
                                )}
                            </>
                        )}
                    </div>
                    <div>
                        {preprocessMessage(message ?? '', role ?? 'user')}
                        {renderBase64Image()}
                    </div>
                    <div className="flex items-center gap-2">
                        <p className="text-xs text-left">{getCreatedAt()}</p>
                    </div>
                    {images && images.length > 0 ? (
                        images?.map((url, index) => (
                            <div
                                key={index}
                                onClick={() => {
                                    setPreviewImageUrl(url)
                                    setOpenPreviewDialog(true)
                                }}
                            >
                                <img
                                    src={url}
                                    alt={t('imgAlt.messageImg')}
                                    className="w-1/2 pt-2"
                                />
                            </div>
                        ))
                    ) : (
                        <></>
                    )}
                </div>
                {role === 'user' && requestCompleted && (
                    <div className="flex flex-row ml-auto" title={t('download_report')}>
                        <Braces
                            className="w-4"
                            onClick={() => {
                                showReport(index, pipelineIndex)
                            }}
                        />
                    </div>
                )}
            </Fragment>
            <ImagePreviewDialog
                open={openPreviewDialog}
                setOpen={setOpenPreviewDialog}
                imageUrl={previewImageUrl}
            />
            <ExecutionLogsDialog
                open={openExecutionLogsDialog}
                setOpen={setOpenExecutionLogsDialog}
                report={executionLogs}
            />
        </div>
    )
}

export const MemoizedIndividualMessage = memo(
    (
        props: MessageType & {
            conversationId: string
            index: number
            pipelineIndex?: number
            pipelinePlaygroundExecutions?: PipelinePlaygroundExecutions
        }
    ) => {
        return <IndividualMessage {...props} />
    }
)
