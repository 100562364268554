import { mapFormsToConnectors } from '@/api/connectors.tsx'
import { getDataSourceForms } from '@/api/datasets'
import { ConnectorsLibrary } from '@/components/featuredConnectors/ConnectorsLibrary.tsx'
import { createFileRoute } from '@tanstack/react-router'

export const Route = createFileRoute('/_mainLayout/integrations/datasources/')({
    component: DataSources,
    loader: () => getDataSourceForms(),
})

function DataSources() {
    const forms = Route.useLoaderData()
    const connectors = mapFormsToConnectors(forms)
    return <ConnectorsLibrary connectors={connectors} />
}
