import { getAllPipelines } from '@/api/pipelinesConfig'
import { getAllProjects } from '@/api/projects'
import { QueryKeys } from '@/constants/QueryKeys'
import { HandleAuthenticatedError } from '@/lib/HandleErrors'
import { type StepType, useTour } from '@reactour/tour'
import { useQuery } from '@tanstack/react-query'
import { useNavigate } from '@tanstack/react-router'
import { useEffect, useState } from 'react'

interface OnboardingState {
    enabled: boolean
    skipped: boolean
}

export function useOnboarding() {
    const [onboarding, setOnboarding] = useState<OnboardingState>({
        enabled: false,
        skipped: false,
    })
    const [onScreenSteps, setOnScreenSteps] = useState<StepType[]>([])
    const { setIsOpen, setCurrentStep, currentStep, steps } = useTour()
    const navigate = useNavigate()

    const AllProjectsQuery = useQuery({
        queryKey: [QueryKeys.PROJECTS],
        queryFn: async () => await getAllProjects().catch((e) => HandleAuthenticatedError(e)),
    })

    const PipelinesQuery = useQuery({
        queryKey: [QueryKeys.ALL_PIPELINES, AllProjectsQuery.data?.[0].id],
        queryFn: () => getAllPipelines({ projectId: AllProjectsQuery.data?.[0].id ?? '' }),
        enabled: AllProjectsQuery.isSuccess,
    })

    // removed until pipeline seeding is ironed out
    // const checkIfNewUser = (pipelinesData: PipelineList) => {
    //     if (AllProjectsQuery.isSuccess && PipelinesQuery.isSuccess) {
    //         return (
    //             pipelinesData &&
    //             pipelinesData.items.length === 1 &&
    //             pipelinesData.items[0].name === 'Sample - Ask me anything'
    //         )
    //     }
    // }

    // biome-ignore lint/correctness/useExhaustiveDependencies: <explanation>
    useEffect(() => {
        const storedOnboarding = localStorage.getItem('platform-onboarding')
        if (AllProjectsQuery.isSuccess && PipelinesQuery.isSuccess) {
            if (storedOnboarding == null) {
                setOnboarding(() => ({ enabled: true, skipped: false }))
                localStorage.setItem(
                    'platform-onboarding',
                    JSON.stringify({ enabled: true, skipped: false })
                )
            } else {
                setOnboarding(JSON.parse(storedOnboarding))
            }
        }
    }, [AllProjectsQuery.isSuccess, PipelinesQuery.isSuccess, PipelinesQuery.data])

    useEffect(() => {
        const pathName = window.location.pathname
        if (
            PipelinesQuery.isSuccess &&
            onboarding.enabled &&
            !onboarding.skipped &&
            !pathName.includes('login') &&
            !pathName.includes('legal')
        ) {
            setTimeout(() => {
                setIsOpen(true)
                setCurrentStep(0)
            }, 250)
            navigate({ to: '/' })
        }
    }, [onboarding, navigate, setIsOpen, setCurrentStep, PipelinesQuery.isSuccess])

    useEffect(() => {
        if (onboarding.enabled) {
            const onScreenSteps = steps.filter(({ selector }) => {
                return selector
                    ? document.querySelector(selector as string)
                        ? !!document.querySelector(selector as string)
                        : false
                    : false
            })
            setOnScreenSteps(onScreenSteps)
        }
    }, [currentStep, window.location.pathname])

    useEffect(() => {
        const storedOnboarding = localStorage.getItem('platform-onboarding')
        if (storedOnboarding) {
            const { skipped } = JSON.parse(storedOnboarding) as OnboardingState
            if (onboarding.enabled && !skipped) {
                localStorage.setItem(
                    'platform-onboarding',
                    JSON.stringify({ ...onboarding, enabled: currentStep !== 12 })
                )
                if (
                    onScreenSteps.find((step) => step?.selector === steps[currentStep]?.selector) ||
                    currentStep === 0 ||
                    currentStep === 10 ||
                    currentStep === 11
                ) {
                    setIsOpen(true)
                } else {
                    setIsOpen(false)
                }
            }
        }
    }, [currentStep, onboarding, onScreenSteps])

    return {
        onboarding,
    }
}
