import { toast } from '@/components/ui/use-toast'
import { t } from 'i18next'
import { getAuthUrl } from './utils'
import { NotFoundError } from '@tanstack/react-router'

interface ApiError {
    status?: number
    message?: string
}

export function isApiError(error: unknown): error is ApiError {
    return typeof error === 'object' && error !== null && 'message' in error
}

export function isNotFound(error: unknown): error is NotFoundError {
    return typeof error === 'object' && error !== null && 'status' in error && error.status === 404
}

export const HandleDeleteError = (error: unknown, objectType: string) => {
    if (isApiError(error)) {
        switch (true) {
            case error.message === 'Unauthorized':
            case error.message === '401':
            case error.status == 401:
                window.location.href = `/auth/logon?ReturnUrl=${encodeURIComponent(window.location.href)}`
                break
            case error.message === '422':
            case error.status == 422:
                toast({
                    title: t('error_title'),
                    description:
                        error.message ||
                        `We are unable to delete this ${objectType} since it is actively in use.`,
                    variant: 'destructive',
                })
                break
            default:
                toast({
                    title: t('error_title'),
                    description: `${error.message}`,
                    variant: 'destructive',
                })
        }
    } else {
        console.error('An unknown error occurred', error)
    }
}

export const HandleAuthenticatedError = (error: unknown) => {
    if (isApiError(error)) {
        switch (true) {
            case error.message === 'Unauthorized':
            case error.message === '401':
            case error.status === 401:
                window.location.href = `${getAuthUrl()}/auth/logon?ReturnUrl=${encodeURIComponent(window.location.href)}`
                break
            default:
                toast({
                    title: t('error_title'),
                    description: `Something went wrong.`,
                    variant: 'destructive',
                })
        }
    } else {
        console.error('An unknown error occurred', error)
    }
}

export interface ErrorResponse {
    status?: number
    message?: string
}

export const handleError = (error: unknown, context?: string) => {
    if (isApiError(error)) {
        switch (true) {
            case error.status === 401:
            case error.message === 'Unauthorized':
            case error.message === '401':
                window.location.href = `${getAuthUrl()}/auth/logon?ReturnUrl=${encodeURIComponent(window.location.href)}`
                break

            case error.status === 422:
            case error.message?.toLowerCase().includes('in use'):
                toast({
                    title: t('error_title'),
                    description: context
                        ? `Unable to process ${context}. It may be in use.`
                        : 'Unable to process the request. The item may be in use.',
                    variant: 'destructive',
                })
                break

            case error.status === 404:
                toast({
                    title: t('error_title'),
                    description: 'The requested resource was not found.',
                    variant: 'destructive',
                })
                break

            case error.status === 403:
                toast({
                    title: t('error_title'),
                    description: 'You do not have permission to perform this action.',
                    variant: 'destructive',
                })
                break

            case (error?.status ?? 0) >= 500:
                toast({
                    title: t('error_title'),
                    description: 'A server error occurred. Please try again later.',
                    variant: 'destructive',
                })
                break

            default:
                toast({
                    title: t('error_title'),
                    description: error.message || 'An unexpected error occurred.',
                    variant: 'destructive',
                })
                break
        }
    } else {
        console.error('An unknown error occurred', error)
    }
}
