import AiriaLogoBlack from '@/assets/airia-logo-black.svg?react'
import AiriaLogo from '@/assets/airia-logo.svg?react'
import AiriaLogoNoText from '@/assets/airia-logo-no-text.svg?react'
import { cn } from '@/lib/utils'
import { Menu } from '@/components/sidenav/Menu'
import { SideNavToggle } from './SideNavToggle'
import { Link } from '@tanstack/react-router'
import { useTheme } from '@/lib/ThemeProvider'
import { useState } from 'react'

export function Sidebar({ open, setOpen }: { open: boolean; setOpen: (open: boolean) => void }) {
    const { theme } = useTheme()
    const [hover, setHover] = useState(false)

    return (
        <aside
            onMouseEnter={() => setHover(true)}
            onMouseLeave={() => setHover(false)}
            className={cn(
                'fixed top-0 left-0 z-20 h-screen bg-background-sidenav',
                'transition-all duration-300 ease-in-out pt-6 pb-2',
                open ? 'w-[260px]' : 'w-[84px]'
            )}
        >
            <div
                className={cn(
                    'h-[33px] -mr-5 pr-5 py-10 absolute top-0 -right-3 w-full flex items-center justify-end transition-opacity duration-300',
                    open && (hover ? 'opacity-100' : 'opacity-0')
                )}
            >
                <SideNavToggle isOpen={open} setIsOpen={setOpen} />
            </div>
            <div className={'relative h-full flex flex-col dark:shadow-zinc-800 px-4'}>
                <div
                    className={cn(
                        'transition-all duration-150 ease-in-out',
                        open ? 'opacity-100 translate-x-0' : 'opacity-0 -translate-x-full'
                    )}
                >
                    <Link
                        to="/"
                        className="flex rounded-lg items-center text-foreground transition-colors"
                    >
                        {theme === 'dark' ? (
                            <AiriaLogo className="h-[33px] w-24 self-start" />
                        ) : (
                            <AiriaLogoBlack className="h-[33px] w-24 self-start" />
                        )}
                    </Link>
                </div>
                <div
                    className={cn(
                        'absolute top-0 left-0 transition-all duration-150 ease-in-out w-full h-[33px] flex items-center justify-center',
                        open ? 'opacity-0 scale-0' : 'opacity-100 scale-100'
                    )}
                >
                    <Link
                        to="/"
                        className="flex rounded-lg items-center text-foreground transition-colors justify-center"
                    >
                        <AiriaLogoNoText className="h-7 w-7" />
                    </Link>
                </div>
                <Menu isOpen={open} setIsOpen={setOpen} />
            </div>
        </aside>
    )
}
