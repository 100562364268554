import React from 'react'
import Azure from '@/assets/azure.svg?react'
import OpenAi from '@/assets/icons/openai.svg?react'
import Airia from '@/assets/models/Airia.svg?react'
import Anthropic from '@/assets/models/Anthropic.svg?react'
import Google from '@/assets/models/Google.svg?react'
import Mistral from '@/assets/models/Mistral.svg?react'
import Ollama from '@/assets/models/Ollama.svg?react'
import HuggingFace from '@/assets/models/huggingFace.svg?react'
import AI21 from '@/assets/models/AI21.svg?react'
import Cohere from '@/assets/models/Cohere.svg?react'
import Perplexity from '@/assets/models/Perplexity.svg?react'
import Replicate from '@/assets/models/Replicate.svg?react'
import TogetherAI from '@/assets/models/TogetherAI.svg?react'

type IconSize = 'xs' | 'sm' | 'md' | 'lg'

interface ProviderIconProps {
    provider: string
    className?: string
    size?: IconSize
}

const ProviderIcon: React.FC<ProviderIconProps> = ({ provider, className = '', size = 'lg' }) => {
    const sizeClasses = {
        xs: 'w-3 h-3',
        sm: 'w-4 h-4',
        md: 'w-5 h-5',
        lg: 'w-6 h-6',
    }
    const iconClassName = `provider-icon ${sizeClasses[size]} flex items-center justify-center dark:bg-white rounded-md p-1 ${className}`
    const svgClassName = 'w-full h-full object-contain'

    const renderIcon = (Icon: React.FC<React.SVGProps<SVGSVGElement>>) => (
        <div className={iconClassName}>
            <Icon className={svgClassName} />
        </div>
    )

    switch (typeof provider === 'string' ? provider.toLowerCase() : 'default') {
        case 'ai21':
            return renderIcon(AI21)
        case 'airia':
            return renderIcon(Airia)
        case 'anthropic':
            return renderIcon(Anthropic)
        case 'azure':
            return renderIcon(Azure)
        case 'cohere':
            return renderIcon(Cohere)
        case 'google':
            return renderIcon(Google)
        case 'hugging face':
            return renderIcon(HuggingFace)
        case 'mistral':
            return renderIcon(Mistral)
        case 'ollama':
            return renderIcon(Ollama)
        case 'perplexity':
            return renderIcon(Perplexity)
        case 'replicate':
            return renderIcon(Replicate)
        case 'togetherai':
            return renderIcon(TogetherAI)
        case 'openai':
            return (
                <div className={`${iconClassName} openai-icon`}>
                    <OpenAi className={`${svgClassName} scale-95`} />
                </div>
            )
        default:
            return renderIcon(Airia)
    }
}

export default ProviderIcon
