import { useMemo, useState } from 'react'
import { PaginationState } from '@tanstack/react-table'

interface PaginationOptions {
    totalItems: number
    initialPage?: number
    itemsPerPage?: number
}

export function useFeedPagination({
    totalItems,
    initialPage = 1,
    itemsPerPage = 25,
}: PaginationOptions) {
    const [pagination, setPagination] = useState<PaginationState>({
        pageIndex: initialPage - 1,
        pageSize: itemsPerPage,
    })
    const maxPage = useMemo(() => Math.ceil(totalItems / itemsPerPage), [totalItems, itemsPerPage])

    const next = () => {
        setPagination((prev) => ({
            ...prev,
            pageIndex: Math.min(prev.pageIndex + 1, maxPage),
        }))
    }

    const prev = () => {
        setPagination((prev) => ({
            ...prev,
            pageIndex: Math.min(prev.pageIndex - 1, 1),
        }))
    }

    const jump = (page: number) => {
        const pageNumber = Math.max(1, page)
        setPagination((prev) => ({
            ...prev,
            pageIndex: Math.min(pageNumber, maxPage),
        }))
    }

    return {
        pagination,
        setPagination,
        maxPage,
        next,
        prev,
        jump,
    }
}
