import React from 'react'
import FeedHeader, { paginationAndSortingParams } from '@/components/pagination/FeedHeader.tsx'
import { ColumnDef } from '@tanstack/react-table'
import { FeedDataTable } from '@/components/home/feed/feedDataTable/FeedDataTable.tsx'
import { formatCurrency, formatDate, getTimezone } from '@/lib/utils.ts'
import { useQuery } from '@tanstack/react-query'
import { QueryKeys } from '@/constants/QueryKeys.ts'
import { getAllProjects } from '@/api/projects.ts'
import AiriaIcon from '@/assets/icons/airia-icon.svg?react'
import millify from 'millify'
import {
    Tooltip,
    TooltipContent,
    TooltipProvider,
    TooltipTrigger,
} from '@/components/ui/tooltip.tsx'
import ProviderIcon from '@/components/modelLibrary/ProviderIcon.tsx'
import { DataVectorSearchExecution, getDataProcessingFeed } from '@/api/feed.ts'

interface FeedTableProps {
    queryParams: paginationAndSortingParams
}

export const DataProcessingFeedTable: React.FC<FeedTableProps> = ({ queryParams }) => {
    const projectsQuery = useQuery({
        queryKey: [QueryKeys.PROJECTS],
        queryFn: () => getAllProjects(),
    })

    const vectorSearchQuery = useQuery({
        queryKey: [QueryKeys.VECTOR_SEARCH_FEED, queryParams],
        queryFn: () => getDataProcessingFeed(queryParams),
    })

    const columns: ColumnDef<DataVectorSearchExecution>[] = [
        {
            accessorKey: 'createdAt',
            header: `Timestamp (GMT${getTimezone()})`,
            cell: ({ row }) => <p>{formatDate(row.original.createdAt)}</p>,
        },
        {
            accessorKey: 'projectId',
            header: 'Project',
            cell: ({ row }) => {
                const project = projectsQuery.data?.find(
                    (project) => project.id === row.original.projectId
                )
                return (
                    <div className={'flex gap-1 items-center'}>
                        {project?.projectIcon ? (
                            <img
                                src={project.projectIcon}
                                alt={project.name}
                                height={16}
                                width={16}
                            />
                        ) : (
                            <AiriaIcon className="w-4 h-4" />
                        )}
                        <span
                            className={
                                ' whitespace-nowrap overflow-hidden max-w-[200px] text-ellipsis inline-block'
                            }
                        >
                            {project?.name}
                        </span>
                    </div>
                )
            },
        },
        {
            accessorKey: 'databaseProvider',
            header: 'Database Provider',
        },
        {
            accessorKey: 'executionSourceType',
            header: 'Source',
        },
        {
            header: 'Embedding Provider',
            accessorKey: 'embeddingProvider',
            cell: ({ row }) => (
                <div className={'flex gap-1 items-center'}>
                    <ProviderIcon provider={row.original.embeddingProvider} className="w-4 h-4" />
                    {row.original.embeddingProvider}
                </div>
            ),
        },
        {
            accessorKey: 'inputType',
            header: 'Input Type',
        },
        {
            accessorKey: 'retrievalType',
            header: 'Retrieval Type',
            cell: ({ row }) => (
                <span>
                    {row.original.retrievalType && row.original.retrievalType !== ''
                        ? row.original.retrievalType
                        : 'RAG'}
                </span>
            ),
        },
        {
            accessorKey: 'vectorSearchQueryResultsCount',
            header: 'Queried Results',
        },
        {
            accessorKey: 'vectorSearchResponseResultsCount',
            header: 'Returned Results',
        },
        {
            header: 'Data Size',
            accessorKey: 'vectorSearchResponseResultsSizeInKb',
            cell: ({ row }) => {
                const totalSizeInBytes =
                    row.original.vectorSearchResponseResultsSizeInMb * 1_048_576
                return (
                    <span className={'text-foreground'}>
                        {millify(totalSizeInBytes, {
                            units: ['B', 'KB', 'MB', 'GB', 'TB'],
                            space: true,
                        })}
                    </span>
                )
            },
        },
        {
            accessorKey: 'totalEmbeddingsTokenAmountConsumed',
            header: 'Embeddings Consumption',
            cell: ({ row }) => (
                <Tooltip>
                    <TooltipTrigger>
                        <span>{row.original.totalEmbeddingsTokenAmountConsumed}</span>
                        {row.original.balanceUsed && (
                            <TooltipContent>
                                <div className={'flex flex-col'}>
                                    <span>Total Cost: </span>
                                    <span>{formatCurrency(+row.original.balanceUsed)}</span>
                                </div>
                            </TooltipContent>
                        )}
                    </TooltipTrigger>
                </Tooltip>
            ),
        },
        {
            accessorKey: 'duration',
            header: 'Duration',
            cell: ({ row }) => {
                if (row.original.duration && row.original.duration !== '') {
                    const parts = row.original.duration.split(':')
                    const hours = Number.parseInt(parts[0], 10)
                    const minutes = Number.parseInt(parts[1], 10)
                    const seconds = Number.parseFloat(parts[2])
                    const totalMilliseconds = (hours * 3600 + minutes * 60 + seconds) * 1000
                    const duration = millify(totalMilliseconds, {
                        precision: 2,
                        units: ['ms', 's', 'm', 'h'],
                    })

                    return <p className="text-gray-550]">{duration}</p>
                }
            },
        },
    ]
    return (
        <TooltipProvider>
            <FeedDataTable
                columns={columns}
                data={vectorSearchQuery.data?.items ?? []}
                totalCount={vectorSearchQuery.data?.totalCount ?? 0}
                Header={
                    <FeedHeader
                        type="processing"
                        headerTitle="Data Processing"
                        queryParams={queryParams}
                        refreshQueryKeys={[QueryKeys.VECTOR_SEARCH_FEED, queryParams]}
                        totalCount={vectorSearchQuery.data?.totalCount ?? 0}
                    />
                }
            />
        </TooltipProvider>
    )
}
