import {
    Toast,
    ToastClose,
    ToastDescription,
    ToastProvider,
    ToastTitle,
    ToastViewport,
} from '@/components/ui/toast'
import { useToast } from '@/components/ui/use-toast'
import { CheckCircle } from 'lucide-react'

export function Toaster() {
    const { toasts } = useToast()

    return (
        <ToastProvider>
            {toasts.map(({ id, title, description, action, variant, ...props }) => (
                <Toast key={id} variant={variant} {...props}>
                    <div className="grid gap-1">
                        {title && <ToastTitle>{title}</ToastTitle>}
                        {description && (
                            <ToastDescription>
                                {variant !== 'success' ? (
                                    description
                                ) : (
                                    <div className="flex flex-row gap-2 items-center">
                                        <CheckCircle className="w-4 h-4 text-[#27AE1C]" />
                                        {description}
                                    </div>
                                )}
                            </ToastDescription>
                        )}
                    </div>
                    {action}
                    <ToastClose variant={variant} />
                </Toast>
            ))}
            <ToastViewport className="top-[60px] right-4 flex flex-col gap-2 w-[390px] max-w-[100vw] m-0 list-none z-[9999] outline-none" />
        </ToastProvider>
    )
}
