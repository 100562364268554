import { Button } from '@/components/ui/button'
import { Input } from '@/components/ui/input'
import { useToast } from '@/components/ui/use-toast'
import { QueryKeys } from '@/constants/QueryKeys'
import { useQuery } from '@tanstack/react-query'
import { ArrowUp, LoaderCircle } from 'lucide-react'
import { useEffect, useRef, useState } from 'react'
import { useTranslation } from '@/lib/i18n'
import { HelpCenterChatMessage } from './helpCenterChatMessage'
import { v4 as uuidv4 } from 'uuid'
import { useChat } from '@/hooks/use-chat'
import { Pipeline } from '@/api/pipelinesConfig'
import { getHelpCenterWebSocketUrl } from '@/api/helpCenterChat.ts'

export const HelpCenterChat = () => {
    const { t } = useTranslation()
    const { toast } = useToast()
    const temporaryPipelineId = uuidv4()
    const tempVersion = {
        id: uuidv4(),
        pipelineId: temporaryPipelineId,
        versionNumber: '1.00',
        majorVersion: 1,
        minorVersion: 0,
        isDraftVersion: false,
        isLatest: true,
        steps: [],
    }

    const [tempMessage, setTempMessage] = useState('')
    const [loading, setLoading] = useState(true)
    const [pipeline] = useState<Pipeline>({
        id: temporaryPipelineId,
        description: 'Temporary Pipeline for Help Center. This is a placeholder.',
        name: 'Temporary Help Center Pipeline',
        executionName: 'temporary_help_center_pipeline',
        createdAt: new Date().toISOString(),
        updatedAt: new Date().toISOString(),
        projectId: '',
        activeVersionId: tempVersion.id,
        activeVersion: tempVersion,
        versions: [tempVersion],
        executionStats: {
            successCount: 0,
            failureCount: 0,
        },
    })
    const conversationGuidRef = useRef<string>(uuidv4())

    const GetWebsocketUrlQuery = useQuery({
        queryKey: [QueryKeys.GET_WS_URL, conversationGuidRef],
        queryFn: async () => getHelpCenterWebSocketUrl(conversationGuidRef.current),
    })

    const { sendMessage, messages, isPipelineRunning } = useChat({
        conversationId: conversationGuidRef.current,
        websocketUrl: GetWebsocketUrlQuery?.data?.webSocketUrl
            ? `${GetWebsocketUrlQuery?.data?.webSocketUrl}&includeConversations=false`
            : '',
        pipeline: pipeline,
        temporaryPrompt: '',
        imageArray: [],
        stream: true,
        debug: false,
        isTemporary: false,
        isHelpCenter: true,
        setLoading,
    })

    const endOfMessageRef = useRef<null | HTMLDivElement>(null)

    const goToBottom = () => {
        endOfMessageRef.current?.scrollIntoView({ behavior: 'auto' })
    }

    useEffect(() => {
        setLoading(isPipelineRunning)
    }, [isPipelineRunning])

    useEffect(() => {
        setLoading(isPipelineRunning)
    }, [messages])

    useEffect(() => {
        goToBottom()
    }, [messages])

    const sendToChat = async (message: string) => {
        if (loading || message.trim() == '') return
        try {
            await sendMessage({
                message,
                handleCleanUp: () => {
                    setTempMessage('')
                },
            })
        } catch (e) {
            console.log(e)
            toast({
                title: t('help_center.error'),
                description: t('help_center.error_description'),
            })
        }
    }

    return (
        <div className="flex flex-col w-full p-0 bg-background">
            <div className="w-full h-[500px] overflow-scroll">
                {messages.map((message, index) => (
                    <HelpCenterChatMessage key={index} {...message} />
                ))}
                {loading && (
                    <div className="w-full justify-center flex flex-col">
                        <LoaderCircle className="self-center w-12 h-12 animate-spin" />
                    </div>
                )}
                <div ref={endOfMessageRef} />
            </div>
            <div className="flex flex-col w-full p-2 gap-2">
                <div className="flex flex-row w-full p-2 gap-2">
                    <Input
                        value={tempMessage}
                        placeholder={t('help_center.how_to_help')}
                        onChange={(e) => setTempMessage(e.target.value)}
                        onKeyDown={(e) => {
                            if (e.key === 'Enter' && !e.shiftKey && !loading) {
                                e.preventDefault()
                                sendToChat(tempMessage)
                            }
                        }}
                    />
                    <Button className="rounded-full" onClick={() => sendToChat(tempMessage)}>
                        <ArrowUp className="w-4"></ArrowUp>
                    </Button>
                </div>
            </div>
        </div>
    )
}
