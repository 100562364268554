import React from 'react'
import { IngestionFeedStatus } from '@/api/ingestion.ts'
import { Check, Loader, X } from 'lucide-react'

interface IngestionStatusBadgeProps {
    status: IngestionFeedStatus
}

const IngestionStatusBadge: React.FC<IngestionStatusBadgeProps> = ({ status }) => {
    const getBgColor = () => {
        switch (status) {
            case IngestionFeedStatus.Indexing:
            case IngestionFeedStatus.Ingesting:
            case IngestionFeedStatus.Storing:
            case IngestionFeedStatus.Validating:
                return 'bg-warning-muted'
            case IngestionFeedStatus.Finished:
                return 'bg-success-muted'
            case IngestionFeedStatus.Failed:
                return 'bg-error-muted'
            default:
                return 'bg-gray-150'
        }
    }

    const getIcon = () => {
        switch (status) {
            case IngestionFeedStatus.Indexing:
            case IngestionFeedStatus.Ingesting:
            case IngestionFeedStatus.Storing:
            case IngestionFeedStatus.Validating:
                return <Loader className={'h-3 w-3'} />
            case IngestionFeedStatus.Finished:
                return <Check className={'h-3 w-3'} />
            case IngestionFeedStatus.Failed:
                return <X className={'h-3 w-3'} />
            default:
                return <></>
        }
    }

    return (
        <div className={'flex'}>
            <div className={`flex gap-2 px-1 rounded-md items-center text-xs ${getBgColor()}`}>
                {getIcon()}
                {status}
            </div>
        </div>
    )
}

export default IngestionStatusBadge
