import { LIBRARY_LANGUAGES, LIBRARY_MODELS, MODEL_DESCRIPTION } from '@/api/library.ts'
import ClipboardCheck from '@/assets/icons/clipboard-check.svg?react'
import DataFlow from '@/assets/icons/dataflow.svg?react'
import Download from '@/assets/icons/download.svg?react'
import User from '@/assets/icons/user.svg?react'
import ProviderIcon from '@/components/modelLibrary/ProviderIcon.tsx'
import { Button } from '@/components/ui/button.tsx'
import { Separator } from '@/components/ui/separator.tsx'
import { customComponents } from '@/lib/MarkdownStyles.tsx'
import { createFileRoute, useRouter } from '@tanstack/react-router'
import { t } from 'i18next'
import { ArrowLeft, Star } from 'lucide-react'
import millify from 'millify'
import Markdown from 'react-markdown'

export const Route = createFileRoute('/_mainLayout/library/models/$modelId')({
    component: ModelDetails,
    loader: ({ params }) => {
        const model = LIBRARY_MODELS.find((m) => m.id === params.modelId)
        if (!model) throw new Error('Model not found')
        return model
    },
})

function ModelDetails() {
    const router = useRouter()
    const queryParams = Route.useSearch()
    const model = Route.useLoaderData()
    const { projectId } = Route.useParams()
    const currencyFormatter = Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' })

    const parameters = [
        'Max Tokens',
        'Temperature',
        'Top P',
        'Frequency Penalty',
        'Presence Penalty',
        'Top K',
    ]

    const tags = ['Multimodal', 'Image to Text', 'Video']

    const onBack = () => {
        router.navigate({ to: '/library/models' })
    }

    return (
        <div className={'flex flex-col gap-4 p-6'}>
            <div className={'flex items-center text-sm mb-8 cursor-pointer'} onClick={onBack}>
                <ArrowLeft className={'h-4 w-4 mr-4'} />
                {t('back_to_library')}
            </div>
            <div className={'flex gap-6'}>
                <div className={'flex flex-col gap-6 p-4'}>
                    <div className={'flex'}>
                        <div className={'mr-4'}>
                            <ProviderIcon provider={model.provider} />
                        </div>
                        <div className={'flex flex-col w-full'}>
                            <div className={'flex justify-between items-center'}>
                                <div className={'flex'}>
                                    <h1 className={'text-2xl font-medium'}>{model.name}</h1>
                                </div>
                                {model.rating && (
                                    <div className={'flex'}>
                                        {Array.from({ length: model.rating - 1 }, (_, i) => (
                                            <Star key={i} />
                                        ))}
                                    </div>
                                )}
                            </div>
                            <div className={'flex gap-4 my-2'}>
                                <div className={'flex items-center'}>
                                    <User className={'h-4 w-4 mr-1'} />
                                    <span className={'text-primary'}>{model.provider}</span>
                                </div>
                                <div className={'flex items-center'}>
                                    <Download className={'h-4 w-4 mr-1'} />
                                    <span>{millify(model.downloads)}</span>
                                </div>
                            </div>
                            <div className={'flex gap-2 flex-wrap my-2'}>
                                {model.tags.map((tag) => (
                                    <div
                                        className={
                                            'bg-card rounded-full px-2 py-1 flex items-center'
                                        }
                                    >
                                        <span>{tag}</span>
                                    </div>
                                ))}
                            </div>
                        </div>
                    </div>
                    <Markdown components={customComponents}>{MODEL_DESCRIPTION}</Markdown>
                </div>
                <div className={'min-w-[33%] border border-border rounded-lg'}>
                    <div className={'bg-card p-6'}>
                        <div className={'grid grid-cols-3 gap-4'}>
                            <div className={'flex flex-col'}>
                                <h4 className={'text-xl'}>54.28t/s</h4>
                                <span className={'text-sm text-accent-foreground'}>throughput</span>
                            </div>
                            <div className={'flex flex-col'}>
                                <h4 className={'text-xl'}>
                                    {currencyFormatter.format(model.inputTokenPrice)}
                                </h4>
                                <span className={'text-sm text-accent-foreground'}>
                                    Input usage
                                </span>
                            </div>
                            <div className={'flex flex-col'}>
                                <h4 className={'text-xl'}>
                                    {currencyFormatter.format(model.outputTokenPrice)}
                                </h4>
                                <span className={'text-sm text-accent-foreground'}>
                                    Output usage
                                </span>
                            </div>
                        </div>
                        <div className={'flex justify-between gap-2 mt-6'}>
                            <Button
                                variant={'outline'}
                                className={'w-full border-primary text-primary'}
                                onClick={() =>
                                    router.navigate({
                                        to: '/$projectId/',
                                        params: { projectId },
                                    })
                                }
                            >
                                <div className={'flex items-center gap-2.5'}>
                                    <span>Use In New Project</span>
                                    <DataFlow className={'w-4 h-4'} />
                                </div>
                            </Button>
                            <Button
                                className={'w-full'}
                                onClick={() =>
                                    router.navigate({
                                        //@ts-ignore
                                        to: '/$projectId/pipelines',
                                        params: { projectId },
                                        search: { ...queryParams },
                                    })
                                }
                            >
                                <div className={'flex items-center gap-2.5'}>
                                    <span>Add To Project</span>
                                    <ClipboardCheck className={'w-4 h-4'} />
                                </div>
                            </Button>
                        </div>
                    </div>
                    <div className={'flex flex-col gap-4 py-2 px-6'}>
                        <h4 className={'text-lg'}>Supported Parameters</h4>
                        <div className={'flex flex-wrap gap-2'}>
                            {parameters.map((param) => (
                                <div className={'px-2 py-1 rounded-full bg-card'}>
                                    <span>{param}</span>
                                </div>
                            ))}
                        </div>
                    </div>
                    <Separator className={'my-8 bg-border'} />
                    <div className={'flex flex-col py-2 px-5'}>
                        <h4 className={'text-lg'}>Model Tags</h4>
                        <div className={'flex flex-wrap gap-2 mt-8'}>
                            {tags.map((param) => (
                                <div
                                    className={'px-2 py-1 rounded-full bg-primary/20 text-primary'}
                                >
                                    <span>{param}</span>
                                </div>
                            ))}
                        </div>
                    </div>
                    <Separator className={'my-8 bg-border'} />
                    <div className={'flex flex-col py-2 px-5'}>
                        <h4 className={'text-lg'}>Languages</h4>
                        <div className={'flex flex-wrap gap-2 mt-8'}>
                            {LIBRARY_LANGUAGES.map((param) => (
                                <div className={'px-2 py-1 rounded-full bg-card'}>
                                    <span>{param}</span>
                                </div>
                            ))}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
