import { uploadImage } from '@/api/attachments.ts'
import { Button } from '@/components/ui/button.tsx'
import { Input } from '@/components/ui/input.tsx'
import { Separator } from '@/components/ui/separator.tsx'
import { Pencil, Upload } from 'lucide-react'
import React, { ChangeEvent, useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import { createProject, type NewProjectForm } from '@/api/projects.ts'
import { QueryKeys } from '@/constants/QueryKeys.ts'
import { useQueryClient } from '@tanstack/react-query'
import { Label } from '@/components/ui/label.tsx'
import { ScrollArea, ScrollBar } from '@/components/ui/scroll-area.tsx'
import projectIcons from '@/components/administration/project-icons.tsx'
import { handleError } from '@/lib/HandleErrors.tsx'
import { convertSvgToFile } from '@/lib/utils.ts'
import { useTranslation } from '@/lib/i18n.ts'

interface NewProjectProps {
    onSuccess?: (projectId: string) => void
}

const NewProjectForm: React.FC<NewProjectProps> = ({ onSuccess }) => {
    const { t } = useTranslation()
    const [selectedIcon, setSelectedIcon] = useState<string>('')
    const [iconElement, setIconElement] = useState<HTMLElement | null>(null)
    const { getValues, handleSubmit, setValue, register, reset } = useForm<NewProjectForm>()
    const queryClient = useQueryClient()
    const [fileToUpload, setFileToUpload] = useState<{
        file: File
        previewUrl: string
    }>()

    useEffect(() => {
        if (fileToUpload?.previewUrl) {
            setSelectedIcon('custom')
        } else {
            setSelectedIcon('')
        }
    }, [fileToUpload?.previewUrl])

    useEffect(() => {
        if (selectedIcon) {
            const element = document.getElementById(`project-icon-${selectedIcon}`)
            setIconElement(element)
            if (!element) {
                console.warn(`Icon element with id "project-icon-${selectedIcon}" not found`)
            }
        } else {
            setIconElement(null)
        }
    }, [selectedIcon])

    const onPresetIconClick = (iconId: string) => {
        const newSelectedIcon = selectedIcon === iconId ? '' : iconId
        setSelectedIcon(newSelectedIcon)
    }

    const uploadPresetIcon = async () => {
        if (iconElement) {
            const file = convertSvgToFile(iconElement)
            const previewUrl = URL.createObjectURL(file)
            try {
                const res = await uploadImage({ file })
                setValue('projectIconId', res.id)
            } catch (error) {
                console.error('Error uploading image:', error)
            }

            URL.revokeObjectURL(previewUrl)
        }
    }

    const handleAddIcon = async (e: ChangeEvent<HTMLInputElement>) => {
        const files = e.target.files
        if (!files || files.length === 0) return

        const file = files[0]
        if (!file.type.startsWith('image/')) {
            console.error('Invalid file type. Only images are allowed.')
            return
        }

        const previewUrl = URL.createObjectURL(file)
        const newItem = {
            file,
            previewUrl,
        }
        setFileToUpload(newItem)
        try {
            const res = await uploadImage({ file: newItem.file })
            setValue('projectIconId', res.id)
            setSelectedIcon('custom')
        } catch (error) {
            console.error('Error uploading image:', error)
        }

        URL.revokeObjectURL(previewUrl)
    }

    const handleCreateProject = async (data: NewProjectForm) => {
        try {
            if (selectedIcon !== 'custom' && iconElement) {
                await uploadPresetIcon()
                data.projectIconId = getValues('projectIconId')
            }
            const createdProject = await createProject(data)
            await queryClient.invalidateQueries({ queryKey: [QueryKeys.PROJECTS] })
            reset(undefined)
            onSuccess && onSuccess(createdProject.id)
        } catch (error) {
            handleError(error, 'Project')
        }
    }

    return (
        <form onSubmit={handleSubmit(handleCreateProject)}>
            <div className={'flex flex-col gap-4'}>
                <div>
                    <Label htmlFor="name" className={'text-gray-450 font-semibold'}>
                        Project Name
                    </Label>
                    <Input
                        id="name"
                        type="text"
                        className={'p-4'}
                        {...register('name', { required: true })}
                    />
                </div>
                <div className={'flex flex-col gap-1'}>
                    <Label htmlFor="description" className={'text-gray-450 font-semibold'}>
                        Project Description
                    </Label>
                    <textarea
                        id="description"
                        rows={5}
                        className={'border border-border rounded-md p-4 resize-none'}
                        {...register('description')}
                    />
                </div>
                <div className={'flex flex-col'}>
                    <Label htmlFor={'appIconUpload'} className={'font-semibold'}>
                        Icon
                    </Label>
                    <span>Upload or select an icon for your new project.</span>
                    <div className={'flex gap-2 mt-2'}>
                        <div
                            className={`${selectedIcon === 'custom' ? 'border-secondary' : 'border-transparent'} border-2 p-0.5 rounded-xl`}
                        >
                            <Button
                                variant="ghost"
                                size={'icon'}
                                onClick={() => document?.getElementById('appIconUpload')?.click()}
                                className={'border-dashed border-2 border-gray-350 h-11 w-11'}
                            >
                                {fileToUpload?.previewUrl ? (
                                    <div className={'relative'}>
                                        <img
                                            src={fileToUpload?.previewUrl}
                                            alt={t('imgAlt.projectIcon')}
                                            className={'w-6 h-6'}
                                        />
                                        <div
                                            className={
                                                'absolute flex justify-center items-center -bottom-5 -right-5 h-8 w-8 p-2 rounded-full bg-background shadow'
                                            }
                                        >
                                            <Pencil className={'h-4 w-4'} />
                                        </div>
                                    </div>
                                ) : (
                                    <Upload className={'h-6 w-6text-gray-350 m-2'} />
                                )}
                            </Button>
                        </div>
                        <Separator orientation={'vertical'} />
                        <input
                            type="file"
                            id="appIconUpload"
                            style={{ display: 'none' }}
                            onChange={handleAddIcon}
                        />
                        <ScrollArea>
                            <div className={'flex'}>
                                {projectIcons.map((p) => (
                                    <div
                                        className={`cursor-pointer border-2 p-0.5 rounded-xl ${selectedIcon === p.id ? 'border-secondary' : 'border-transparent'}`}
                                        onClick={() => onPresetIconClick(p.id)}
                                    >
                                        <p.icon
                                            key={p.id}
                                            id={`project-icon-${p.id}`}
                                            className={'h-11 w-11'}
                                        />
                                    </div>
                                ))}
                            </div>
                            <ScrollBar orientation={'horizontal'} />
                        </ScrollArea>
                    </div>
                </div>
            </div>
            <div className={'flex justify-end mt-6'}>
                <Button type="submit">Create</Button>
            </div>
        </form>
    )
}
export default NewProjectForm
