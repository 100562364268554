import { getAllGroups, Group } from '@/api/groups.ts'
import { Button } from '@/components/ui/button.tsx'
import {
    Command,
    CommandEmpty,
    CommandGroup,
    CommandInput,
    CommandItem,
    CommandList,
    CommandSeparator,
} from '@/components/ui/command.tsx'
import { Popover, PopoverContent, PopoverTrigger } from '@/components/ui/popover.tsx'
import { QueryKeys } from '@/constants/QueryKeys.ts'
import { cn } from '@/lib/utils.ts'
import { useQuery } from '@tanstack/react-query'
import { Check, ChevronDown, ExternalLink } from 'lucide-react'
import type React from 'react'
import { useEffect, useRef, useState } from 'react'
import { ScrollArea } from '../ui/scroll-area'
import { t } from 'i18next'

interface SelectRoleProps {
    selectedRolesIds: string[]
    setSelectedRolesIds: (ids: string[]) => void | Promise<void>
    placeholder?: string
    showLinkToAboutRoles?: boolean
    onToggle?: (open: boolean) => void
}

export const RoleSelect: React.FC<SelectRoleProps> = ({
    selectedRolesIds,
    setSelectedRolesIds,
    placeholder,
    showLinkToAboutRoles,
    onToggle,
}) => {
    const [roleSelectOpen, setRoleSelectOpen] = useState(false)

    useEffect(() => {
        if (onToggle) {
            onToggle(roleSelectOpen)
        }
    }, [roleSelectOpen])

    const triggerRef = useRef<HTMLButtonElement>(null)
    const [triggerWidth, setTriggerWidth] = useState<number>()

    useEffect(() => {
        if (triggerRef.current) {
            setTriggerWidth(triggerRef.current.offsetWidth)
        }
    }, [triggerRef.current])

    const selectRoles = (roles: Group[]): Group[] => {
        const roleNameMap: { [key: string]: string | undefined } = {
            ['Legal Admin']: 'User',
            ['Platform Editor']: 'Admin',
            ['Admin']: 'Platform Editor',
            ['User']: 'Legal Admin',
        }
        return roles
            .map((role) => ({ ...role, name: roleNameMap[role.name] ?? '' }))
            .filter((role) => role.name !== '')
    }

    const allRolesQuery = useQuery({
        queryKey: [QueryKeys.GROUPS],
        queryFn: () => getAllGroups(),
        select: (data) => selectRoles(data.items),
    })

    const [isUpdatingSelectedRoles, setIsUpdatingSelectedRoles] = useState(false)
    const loadingOrUpdatingMessage = allRolesQuery.isLoading
        ? t('loading').toString()
        : isUpdatingSelectedRoles
          ? t('roles_select_updating').toString()
          : undefined

    const renderSelectedRoles = () => {
        if (loadingOrUpdatingMessage) {
            return <div>{loadingOrUpdatingMessage}</div>
        }
        const selectedRoles = (allRolesQuery.data ?? []).filter(
            (role) => selectedRolesIds.indexOf(role.id) > -1
        )
        return selectedRoles.length === 0 ? (
            placeholder ? (
                placeholder
            ) : (
                t('roles_select_placeholder')
            )
        ) : (
            <div
                className={cn(
                    'flex gap-1 items-center whitespace-nowrap overflow-hidden text-ellipsis w-full'
                )}
            >
                {selectedRoles.map((r) => r.name).join(', ')}
            </div>
        )
    }

    const onSelectRoleId = (roleId: string) => {
        const newSelectedRoleIds =
            selectedRolesIds.indexOf(roleId) > -1
                ? selectedRolesIds.filter((id) => id !== roleId)
                : [...selectedRolesIds, roleId]

        const result = setSelectedRolesIds(newSelectedRoleIds)
        if (result instanceof Promise) {
            setIsUpdatingSelectedRoles(true)
            result.finally(() => {
                setIsUpdatingSelectedRoles(false)
            })
        }
        setRoleSelectOpen(false)
    }

    return (
        <Popover
            open={!allRolesQuery.isLoading && !isUpdatingSelectedRoles && roleSelectOpen}
            onOpenChange={(open) => setRoleSelectOpen(!isUpdatingSelectedRoles && open)}
        >
            <PopoverTrigger asChild>
                <Button
                    ref={triggerRef}
                    variant="outline"
                    role="combobox"
                    className={cn('w-full justify-between')}
                >
                    {renderSelectedRoles()}
                    <ChevronDown className="ml-2 h-4 w-4 shrink-0 opacity-50" />
                </Button>
            </PopoverTrigger>
            <PopoverContent className="p-0" style={{ width: triggerWidth }}>
                <Command
                    filter={(_, search, keywords) => {
                        if (keywords?.join(' ').toLowerCase().includes(search.toLowerCase())) {
                            return 1
                        }
                        return 0
                    }}
                >
                    <CommandInput placeholder="Search role..." />
                    <CommandList>
                        <ScrollArea>
                            <CommandEmpty>
                                <p>
                                    {loadingOrUpdatingMessage
                                        ? loadingOrUpdatingMessage
                                        : t('no_data_available')}
                                </p>
                            </CommandEmpty>
                            <CommandGroup className="">
                                {(allRolesQuery.data ?? []).map((role) => (
                                    <CommandItem
                                        key={role.id}
                                        value={role.id}
                                        keywords={[role.name]}
                                        onSelect={onSelectRoleId}
                                    >
                                        <Check
                                            className={cn(
                                                'mr-2 h-4 w-4',
                                                selectedRolesIds.indexOf(role.id) > -1
                                                    ? 'opacity-100'
                                                    : 'opacity-0'
                                            )}
                                        />
                                        {role.name}
                                    </CommandItem>
                                ))}
                                {showLinkToAboutRoles && (
                                    <>
                                        <CommandSeparator />
                                        <CommandItem
                                            onSelect={() => {
                                                window.location.href = '/docs/roles'
                                            }}
                                        >
                                            <Check className={cn('mr-2 h-4 w-4 opacity-0')} />
                                            <b>{t('roles_learn_more')}</b>
                                            <b>
                                                <ExternalLink className="ml-1 h-3 w-4" />
                                            </b>
                                        </CommandItem>
                                    </>
                                )}
                            </CommandGroup>
                        </ScrollArea>
                    </CommandList>
                </Command>
            </PopoverContent>
        </Popover>
    )
}
