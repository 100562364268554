import DefaultProjectIcon from '@/assets/icons/projects/default-project-icon.svg?react'
import MessageProject from '@/assets/icons/projects/message-project-icon.svg?react'
import ShieldProject from '@/assets/icons/projects/shield-project-icon.svg?react'
import CarProject from '@/assets/icons/projects/car-project-icon.svg?react'
import BookProject from '@/assets/icons/projects/book-project-icon.svg?react'
import ChartProject from '@/assets/icons/projects/chart-project-icon.svg?react'
import WorldProject from '@/assets/icons/projects/world-project-icon.svg?react'
import FlagProject from '@/assets/icons/projects/flag-project-icon.svg?react'
import SmileProject from '@/assets/icons/projects/smile-project-icon.svg?react'

const projectIcons = [
    {
        id: '1',
        icon: DefaultProjectIcon,
    },
    {
        id: '2',
        icon: MessageProject,
    },
    {
        id: '3',
        icon: ShieldProject,
    },
    {
        id: '4',
        icon: CarProject,
    },
    {
        id: '5',
        icon: BookProject,
    },
    {
        id: '6',
        icon: ChartProject,
    },
    {
        id: '7',
        icon: WorldProject,
    },
    {
        id: '8',
        icon: FlagProject,
    },
    {
        id: '9',
        icon: SmileProject,
    },
]

export default projectIcons
