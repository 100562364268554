import {
    type ColumnDef,
    flexRender,
    getCoreRowModel,
    getSortedRowModel,
    type OnChangeFn,
    type PaginationState,
    type Row,
    useReactTable,
} from '@tanstack/react-table'
import {
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableHeader,
    TableRow,
} from '@/components/ui/table.tsx'
import { useFeedPagination } from '@/components/home/feed/feedDataTable/use-feed-pagination.ts'
import { DataTablePagination } from '@/components/pagination/tablePagination.tsx'
import { useNavigate, useRouter, useSearch } from '@tanstack/react-router'
import { useEffect, type ReactNode } from 'react'
import { useSorting } from '@/hooks/use-sorting.ts'
import { MoveDown, MoveUp } from 'lucide-react'

interface DataTableProps<TData, TValue> {
    columns: ColumnDef<TData, TValue>[]
    data: TData[]
    totalCount: number
    onRowClick?: (row: Row<TData>) => void
    Header?: ReactNode
    initialSortBy?: keyof TData
    initialSortDirection?: 'ASC' | 'DESC'
}

export function FeedDataTable<TData, TValue>({
    columns,
    data,
    totalCount,
    onRowClick,
    Header,
    initialSortBy,
    initialSortDirection,
}: DataTableProps<TData, TValue>) {
    const navigate = useNavigate()
    const router = useRouter()
    const search = useSearch({ strict: false })
    const { pagination, setPagination, maxPage } = useFeedPagination({
        totalItems: totalCount,
    })
    const { sortingState, onSortingChange, field, order } = useSorting({
        // @ts-ignore
        initialField: (search['sortBy'] ?? initialSortBy) ? initialSortBy : 'createdAt',
        initialOrder:
            // @ts-ignore
            (search['sortDirection'] ?? initialSortDirection) ? initialSortDirection : 'DESC',
    })
    const table = useReactTable({
        data,
        columns,
        getCoreRowModel: getCoreRowModel(),
        getSortedRowModel: getSortedRowModel(),
        onSortingChange,
        state: { pagination, sorting: sortingState },
        onPaginationChange: setPagination as OnChangeFn<PaginationState>,
        manualPagination: true,
        pageCount: maxPage,
    })
    // biome-ignore lint/correctness/useExhaustiveDependencies: <explanation>
    useEffect(() => {
        navigate({
            search: {
                ...search,
                pageNumber: pagination.pageIndex + 1,
                pageSize: pagination.pageSize,
                sortBy: field,
                sortDirection: order,
            },
        })
    }, [pagination, router.history.location, sortingState])

    return (
        <div className="h-full flex flex-col">
            {Header && Header}
            <div className="flex-grow overflow-auto">
                <Table className="w-full">
                    <TableHeader className="sticky top-0 z-10 bg-background hover:bg-background">
                        {table.getHeaderGroups().map((headerGroup) => (
                            <TableRow key={headerGroup.id} className={'hover:bg-background'}>
                                {headerGroup.headers.map((header) => (
                                    <TableHead
                                        key={header.id}
                                        onClick={header.column.getToggleSortingHandler()}
                                        className={`whitespace-nowrap p-2 ${header.column.getIsSorted() ? 'hover:bg-primary-100' : ''} min-w-[150px]`}
                                    >
                                        <div
                                            className={`flex justify-between items-center select-none cursor-pointer ${header.column.getIsSorted() ? 'text-primary font-bold' : 'text-gray-350'}`}
                                        >
                                            {header.isPlaceholder
                                                ? null
                                                : flexRender(
                                                      header.column.columnDef.header,
                                                      header.getContext()
                                                  )}
                                            {{
                                                asc: <MoveUp className={'h-4 w-4'} />,
                                                desc: <MoveDown className={'h-4 w-4'} />,
                                            }[header.column.getIsSorted() as string] ?? null}
                                        </div>
                                    </TableHead>
                                ))}
                            </TableRow>
                        ))}
                    </TableHeader>
                    <TableBody>
                        {table.getRowModel().rows.length ? (
                            table.getRowModel().rows.map((row) => (
                                <TableRow
                                    key={row.id}
                                    data-state={row.getIsSelected() && 'selected'}
                                    onClick={() => onRowClick && onRowClick(row)}
                                    className={'hover:bg-primary-100'}
                                >
                                    {row.getVisibleCells().map((cell) => (
                                        <TableCell
                                            key={cell.id}
                                            className="whitespace-nowrap p-2 pr-8"
                                        >
                                            {flexRender(
                                                cell.column.columnDef.cell,
                                                cell.getContext()
                                            )}
                                        </TableCell>
                                    ))}
                                </TableRow>
                            ))
                        ) : (
                            <TableRow>
                                <TableCell colSpan={columns.length} className="h-24 text-center">
                                    No results.
                                </TableCell>
                            </TableRow>
                        )}
                    </TableBody>
                </Table>
            </div>
            <DataTablePagination
                table={table}
                totalCount={totalCount}
                rowsPerPage={pagination.pageSize}
                setPagination={setPagination}
            />
        </div>
    )
}
