import { getPipelineFeedDetails, type PipelineFeedResult } from '@/api/feed'
import { Dialog, DialogContent, DialogHeader, DialogTitle } from '@/components/ui/dialog'
import { QueryKeys } from '@/constants/QueryKeys'
import { cn, downloadFile } from '@/lib/utils'
import { useQuery } from '@tanstack/react-query'
import { themes } from 'prism-react-renderer'
import React, { useState } from 'react'
import { useMemo } from 'react'
import { useEffect, useRef } from 'react'
import { CodeBlock } from 'react-code-block'
import { toast } from '@/components/ui/use-toast'
import { Button } from '@/components/ui/button'
import { t } from 'i18next'
import { Clipboard, Download } from 'lucide-react'
import { getAllProjects, Project } from '@/api/projects.ts'
import AiriaIcon from '@/assets/icons/airia-icon.svg?react'

interface PipelineExecutionModalProps {
    isOpen: boolean
    onClose: () => void
    callDetails: PipelineFeedResult
}

const PipelineExecutionModal: React.FC<PipelineExecutionModalProps> = ({
    isOpen,
    onClose,
    callDetails,
}) => {
    const chatEndRef = useRef<HTMLDivElement>(null)

    const feedDetailsQuery = useQuery({
        queryKey: [QueryKeys.FEED_EXECUTION_DETAILS, callDetails.id],
        queryFn: () => getPipelineFeedDetails(callDetails.id),
    })

    const projectsQuery = useQuery({
        queryKey: [QueryKeys.PROJECTS],
        queryFn: () => getAllProjects(),
    })

    const date = useMemo(() => new Date(callDetails.createdAt), [callDetails.createdAt])
    const [project, setProject] = useState<Project>()

    // biome-ignore lint/correctness/useExhaustiveDependencies: <explanation>
    useEffect(() => {
        setTimeout(() => {
            chatEndRef.current?.scrollIntoView({ behavior: 'instant' })
        }, 50)
    }, [isOpen])

    useEffect(() => {
        if (projectsQuery.data && projectsQuery.data.length > 0) {
            setProject(projectsQuery.data.find((p) => p.id === callDetails.projectId))
        }
    }, [projectsQuery.data])

    const copyToClipboard = async () => {
        await navigator.clipboard.writeText(JSON.stringify(feedDetailsQuery.data, null, 4))
        toast({
            title: 'Copy to clipboard',
            description: 'Report copied to clipboard',
        })
    }

    const download = () => {
        downloadFile(
            JSON.stringify(feedDetailsQuery.data, null, 4),
            `${callDetails.pipelineExecutionName}-${date.toISOString()}.json`,
            'text/json'
        )
    }

    return (
        <Dialog open={isOpen} onOpenChange={onClose}>
            <DialogContent className="max-w-modal-lg max-h-[1000px]">
                <DialogHeader>
                    <DialogTitle>Execution details</DialogTitle>
                </DialogHeader>
                <div className="flex flex-col space-y-4">
                    <table className="w-full text-sm">
                        <thead>
                            <tr className="text-left text-gray-500">
                                <th>Timestamp</th>
                                <th>Response Time</th>
                                <th>Project</th>
                                <th>Successful</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td>
                                    <div className="flex flex-col">
                                        <p>{`${date.toLocaleDateString(undefined, { dateStyle: 'short' })} at ${date.toLocaleTimeString(undefined, { timeStyle: 'short' })}`}</p>
                                    </div>
                                </td>
                                <td>
                                    <div className="flex flex-col">
                                        <p>{`${date.toLocaleDateString(undefined, { dateStyle: 'short' })} at ${date.toLocaleTimeString(undefined, { timeStyle: 'short' })}`}</p>
                                    </div>
                                </td>
                                <td>
                                    <div className="flex flex-col">
                                        {project ? (
                                            <div className={'flex gap-1 items-center'}>
                                                {project?.projectIcon ? (
                                                    <img
                                                        src={project.projectIcon}
                                                        alt={project?.name}
                                                        height={16}
                                                        width={16}
                                                    />
                                                ) : (
                                                    <AiriaIcon className="w-4 h-4" />
                                                )}
                                                <span
                                                    className={
                                                        ' whitespace-nowrap overflow-hidden max-w-[200px] text-ellipsis inline-block'
                                                    }
                                                >
                                                    {project?.name}
                                                </span>
                                            </div>
                                        ) : (
                                            <span>-</span>
                                        )}
                                    </div>
                                </td>
                                <td>
                                    <div className="flex flex-col">
                                        <p>{callDetails.success}</p>
                                    </div>
                                </td>
                            </tr>
                        </tbody>
                    </table>

                    <div className="mt-4">
                        <h3 className="font-semibold mb-2">Pipeline Result</h3>
                        <div className="space-y-4 h-[400px] overflow-y-auto px-4">
                            {feedDetailsQuery.data && (
                                <div className={cn('p-4 rounded-lg bg-blue-50 h-full')}>
                                    <CodeBlock
                                        language="js"
                                        code={JSON.stringify(feedDetailsQuery.data, null, 4)}
                                        theme={themes.vsLight}
                                    >
                                        <CodeBlock.Code
                                            as="div"
                                            className="mt-1 p-2 h-full rounded-xl text-wrap whitespace-pre-wrap break-all overflow-y-scroll bg-transparent"
                                        >
                                            <div className="table-row overflow-auto">
                                                <CodeBlock.LineNumber className="table-cell pr-4 w-12 text-xs text-gray-500 text-right select-none" />
                                                <CodeBlock.LineContent className="table-cell text-sm">
                                                    <CodeBlock.Token />
                                                </CodeBlock.LineContent>
                                            </div>
                                        </CodeBlock.Code>
                                    </CodeBlock>
                                </div>
                            )}
                        </div>
                        <div className="flex w-full flex-row justify-end gap-1 mt-3 -mb-3">
                            <Button
                                type="button"
                                variant="secondary"
                                className="text-primary border-primary flex justify-between gap-1"
                                onClick={copyToClipboard}
                            >
                                {t('copy_text')}
                                <Clipboard className="w-4" />
                            </Button>
                            <Button
                                type="button"
                                className="flex justify-between gap-1"
                                onClick={download}
                            >
                                {t('download_json')}
                                <Download className="w-4" />
                            </Button>
                        </div>
                    </div>
                </div>
            </DialogContent>
        </Dialog>
    )
}

export default PipelineExecutionModal
