import type { QueryKeys } from '@/constants/QueryKeys'
import {
    type InfiniteData,
    type UseInfiniteQueryResult,
    useInfiniteQuery,
} from '@tanstack/react-query'
import { useState } from 'react'
import type { FetchFunction, PageData } from './types'

const PAGE_SIZE = 10

export function useInfiniteQueryCreator<T>(
    key: QueryKeys,
    fetchFunction: FetchFunction<T>,
    projectId?: string,
    sortBy?: string,
    sortDirection?: string
): [UseInfiniteQueryResult<InfiniteData<PageData<T>, number>, Error>, (newFilter: string) => void] {
    const [filter, setFilter] = useState<string>('')

    const query = useInfiniteQuery<
        PageData<T>,
        Error,
        InfiniteData<PageData<T>, number>,
        [QueryKeys, string, string],
        number
    >({
        queryKey: [key, projectId ?? '', filter],
        refetchOnMount: true,
        queryFn: async ({ pageParam = 1 }) => {
            const res = await fetchFunction({
                projectId,
                pageSize: PAGE_SIZE,
                pageNumber: pageParam,
                sortBy: sortBy,
                sortDirection: sortDirection,
                ...(filter ? { filter } : {}),
            })
            return res
        },
        getNextPageParam: (lastPage, allPages, pageParam) => {
            if (!lastPage) return undefined
            const nextPage = allPages.length + 1
            const pageCount = Math.ceil((lastPage.totalCount ?? 0) / PAGE_SIZE)
            if (nextPage > pageCount) return undefined
            return pageParam + 1
        },
        initialPageParam: 1,
    })

    const updateFilter = (newFilter: string) => {
        setFilter(newFilter)
    }

    return [query, updateFilter]
}
