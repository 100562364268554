import { CreateDeployment, createDeployment } from '@/api/deployments'
import { getPipelineById } from '@/api/pipelinesConfig'
import { getAllUserPrompts, UserPrompt } from '@/api/userPrompts'
import { Button } from '@/components/ui/button'
import { Dialog, DialogContent, DialogHeader } from '@/components/ui/dialog'
import { Input } from '@/components/ui/input'
import { Label } from '@/components/ui/label'
import MultiSelect from '@/components/ui/MultiSelect'
import { Textarea } from '@/components/ui/textarea'
import { toast } from '@/components/ui/use-toast'
import { QueryKeys } from '@/constants/QueryKeys'
import { cn } from '@/lib/utils'
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query'
import { createFileRoute, useNavigate, useRouter } from '@tanstack/react-router'
import { t } from 'i18next'
import { Controller, useForm } from 'react-hook-form'

export const Route = createFileRoute(
    '/_mainLayout/$projectId/_projectLayout/pipelines/deploy/$pipelineId'
)({
    loader: ({ params }) => {
        return getPipelineById(params.pipelineId)
    },
    component: () => {
        const router = useRouter()
        const params = Route.useParams()
        const queryParams = Route.useSearch()
        const data = Route.useLoaderData()

        const onCloseButtonClick = () => {
            if (window.history.length > 2) {
                router.history.back()
            } else {
                router.navigate({
                    to: '/$projectId/pipelines',
                    params: { projectId: params.projectId },
                    search: { ...queryParams },
                })
            }
        }
        return (
            <Dialog modal open onOpenChange={onCloseButtonClick}>
                <DialogContent className={'max-w-modal-sm max-h-modal px-4 py-3'}>
                    <DialogHeader>Deploy "{data.name}"</DialogHeader>
                    <NewDeployment />
                </DialogContent>
            </Dialog>
        )
    },
})

function NewDeployment() {
    const params = Route.useParams()
    const { register, handleSubmit, formState, control } = useForm<CreateDeployment>({
        defaultValues: {
            name: '',
            projectId: params.projectId,
            description: '',
            pipelineId: params.pipelineId,
            userPromptIds: [],
        },
    })

    const userPromptQuery = useQuery({
        queryKey: [QueryKeys.ALL_USER_PROMPTS],
        queryFn: () => getAllUserPrompts(),
    })

    const queryParams = Route.useSearch()
    const queryClient = useQueryClient()
    const navigate = useNavigate()

    const deploymentMutation = useMutation({
        mutationFn: createDeployment,
        onSuccess: () => {
            queryClient.invalidateQueries({
                queryKey: [QueryKeys.ALL_DEPLOYMENTS],
            })
            queryClient.invalidateQueries({
                queryKey: [QueryKeys.ALL_PIPELINES_INFINITE],
            })
            queryClient.invalidateQueries({
                queryKey: [QueryKeys.ALL_PIPELINES],
            })
            toast({
                title: t('newDeployment.successTitle'),
                description: t('newDeployment.successDescription'),
            })
        },
    })
    const onSubmit = async (data: CreateDeployment) => {
        try {
            await deploymentMutation.mutateAsync(data)
            navigate({
                to: '/$projectId/pipelines',
                params: { projectId: params.projectId },
                search: { ...queryParams },
            })
        } catch (error) {
            console.error('Error:', error)
            console.log('Error stringified:', JSON.stringify(error))
        }
    }

    return (
        <div className={'grid grid-cols-4'}>
            <div></div>
            <div className={'col-span-4'}>
                <div onSubmit={handleSubmit(onSubmit)}>
                    <div className="flex flex-col gap-4">
                        <div>
                            <Label htmlFor="name">Name:</Label>
                            <Input
                                className={cn(deploymentMutation.isError && 'border-error-message')}
                                {...register('name', { required: true })}
                                id={'name'}
                            />
                            {deploymentMutation.isError && (
                                <p className="text-sm text-error-message pt-1">
                                    {deploymentMutation.error.message}
                                </p>
                            )}
                        </div>
                    </div>
                    <div className="flex flex-col gap-4">
                        <div>
                            <Label htmlFor="name">User Prompts:</Label>
                            <Controller
                                control={control}
                                name="userPromptIds"
                                render={({ field }) => (
                                    <MultiSelect<UserPrompt>
                                        options={userPromptQuery.data ?? []}
                                        placeholder="Select"
                                        searchLabel="Search"
                                        onChange={field.onChange}
                                        value={field.value}
                                        displayKey="name"
                                        valueKey="userPromptId"
                                    />
                                )}
                            />
                        </div>
                    </div>
                    <div className="flex flex-col gap-4">
                        <div>
                            <Label htmlFor="name">Description:</Label>
                            <Textarea
                                className={cn(
                                    deploymentMutation.isError && 'border-error-message',
                                    'resize-none max-h-[100px]'
                                )}
                                {...register('description', { required: true })}
                                id={'description'}
                                required
                            />
                        </div>
                    </div>
                    <Button
                        className="mt-4"
                        disabled={!formState.isValid || deploymentMutation.isPending}
                        type="button"
                        onClick={handleSubmit(onSubmit)}
                    >
                        Submit
                    </Button>
                </div>
            </div>
            <div></div>
        </div>
    )
}
