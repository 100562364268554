import { ArrowLeftToLine, ArrowRightFromLine } from 'lucide-react'
import { Button } from '@/components/ui/button'

interface SidebarToggleProps {
    isOpen: boolean | undefined
    setIsOpen?: (open: boolean) => void
}

export function SideNavToggle({ isOpen, setIsOpen }: SidebarToggleProps) {
    return (
        <Button
            onClick={() => setIsOpen?.(!isOpen)}
            className="cursor-pointer z-[200] w-6 h-6 rounded-full p-1 hover:bg-primary-muted hover:text-primary bg-background shadow-[0px_4px_34px_10px_rgba(0,0,0,0.05)] hover:shadow-[0px_2px_20px_0px_rgba(0,0,0,0.10)]"
            variant="ghost"
            size="icon"
        >
            {isOpen ? <ArrowLeftToLine size={10} /> : <ArrowRightFromLine size={10} />}
        </Button>
    )
}
