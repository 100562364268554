import { ColumnDef } from '@tanstack/react-table'
import { Check } from 'lucide-react'
import React from 'react'
import { useTranslation } from '@/lib/i18n'
import { DefaultDataTable } from '../DefaultDataTable'

interface NodeTableProps {
    data: Array<any>
    nodeType: string
}

const NodeTable: React.FC<NodeTableProps> = ({ data, nodeType }) => {
    const { t } = useTranslation()

    const columns: ColumnDef<any>[] = [
        {
            accessorKey: nodeType === 'models' ? 'displayName' : 'name',
            header: t('name'),
        },
        {
            accessorKey: 'permissions',
            header: t('permissions'),
            cell: ({ row }) => {
                return (
                    <div className="flex flex-row gap-5">
                        <div className="flex flex-row gap-1">
                            View:{' '}
                            {row.original?.permissions?.canView ? (
                                <Check className="text-[#00E132]" />
                            ) : (
                                <Check className="text-[#00E132]" />
                            )}
                        </div>
                        <div className="flex flex-row gap-1">
                            Edit:{' '}
                            {row.original?.permissions?.canEdit ? (
                                <Check className="text-[#00E132]" />
                            ) : (
                                <Check className="text-[#00E132]" />
                            )}
                        </div>
                        <div className="flex flex-row gap-1">
                            Delete:{' '}
                            {row.original?.permissions?.canDelete ? (
                                <Check className="text-[#00E132]" />
                            ) : (
                                <Check className="text-[#00E132]" />
                            )}
                        </div>
                    </div>
                )
            },
        },
    ]

    return <DefaultDataTable data={data} columns={columns} />
}

export default NodeTable
