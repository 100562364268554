import React from 'react'
import { useTranslation } from 'react-i18next'
import { cn } from '@/lib/utils'
import { Link } from '@tanstack/react-router'

export interface CustomSubMenuFooterProps {
    menuLabel: string
}

const CustomSubMenuFooter: React.FC<CustomSubMenuFooterProps> = ({ menuLabel }) => {
    const { t } = useTranslation()

    switch (menuLabel) {
        case 'Projects':
            return (
                <Link
                    to="/projects"
                    className={cn(
                        'flex rounded-[8px] items-center text-sm hover:bg-primary-100 transition-colors px-2 h-8 ml-4 text-primary'
                    )}
                    activeProps={{
                        className: 'text-primary bg-primary-100 hover:bg-accent',
                    }}
                    activeOptions={{
                        exact: false,
                    }}
                >
                    {t('view_all_projects')}
                </Link>
            )
        default:
            return null
    }
}

export default CustomSubMenuFooter
