import React from 'react'
import { useState } from 'react'
import { useQuery } from '@tanstack/react-query'
import type { ColumnDef } from '@tanstack/react-table'
import type { paginationAndSortingParams } from '@/components/pagination/paginationHeader'
import { Link } from '@tanstack/react-router'
import { type StepExecutionContext, getPipelineFeed, type PipelineFeedResult } from '@/api/feed'
import PipelineExecutionModal from '@/components/home/feed/PipelineExecutionModal.tsx'
import { QueryKeys } from '@/constants/QueryKeys.ts'
import { getAllProjects } from '@/api/projects.ts'
import AiriaIcon from '@/assets/icons/airia-icon.svg?react'
import { FeedDataTable } from '@/components/home/feed/feedDataTable/FeedDataTable.tsx'
import ProviderIcon from '@/components/modelLibrary/ProviderIcon.tsx'
import { Check, X } from 'lucide-react'
import { formatDate, getTimezone, formatDuration } from '@/lib/utils.ts'
import FeedHeader from '@/components/pagination/FeedHeader'
import { t } from 'i18next'
import { Badge } from '@/components/ui/badge'

export interface FeedTableProps {
    queryParams: paginationAndSortingParams
}

export const ExecutionFeedTable: React.FC<FeedTableProps> = ({ queryParams }) => {
    const [selectedCall, setSelectedCall] = useState<
        PipelineFeedResult | StepExecutionContext | null
    >(null)
    const [isModalOpen, setIsModalOpen] = useState(false)

    const feedExecutionsQuery = useQuery({
        queryKey: [QueryKeys.EXECUTION_FEED_ALL, queryParams],
        queryFn: () =>
            getPipelineFeed(
                queryParams.pageSize && queryParams.pageNumber
                    ? { ...queryParams }
                    : { pageSize: 25, pageNumber: 1 }
            ),
    })

    const projectsQuery = useQuery({
        queryKey: [QueryKeys.PROJECTS],
        queryFn: () => getAllProjects(),
    })

    const handleRowClick = (row: { original: PipelineFeedResult }) => {
        setSelectedCall(row.original)
        setIsModalOpen(true)
    }

    const closeModal = () => {
        setIsModalOpen(false)
        setSelectedCall(null)
    }

    const columns: ColumnDef<PipelineFeedResult>[] = [
        {
            accessorKey: 'createdAt',
            header: `Timestamp (GMT${getTimezone()})`,
            cell: ({ row }) => <p>{formatDate(row.original.createdAt)}</p>,
        },
        {
            header: 'Model Provider',
            accessorKey: 'providerType',
            enableSorting: false,
            cell: ({ row }) => {
                const length = row.original.executionSteps?.length || 0
                if (length !== 0) {
                    return (
                        <div className="flex flex-row gap-1 items-center text-primary-900">
                            <ProviderIcon
                                provider={row.original.providerType}
                                className="w-4 h-4"
                            />
                            <p>
                                {row.original?.executionSteps[0].provider}
                                {length > 1 && ','}
                            </p>
                            {length > 1 && <p>+{length}</p>}
                        </div>
                    )
                } else {
                    return <span>-</span>
                }
            },
        },
        {
            header: 'Model Name',
            accessorKey: 'requestModel',
            enableSorting: false,
            cell: ({ row }) => {
                const length = row.original.executionSteps?.length || 0
                if (length !== 0) {
                    return (
                        <div className="flex flex-row gap-1 text-primary-900">
                            <p>
                                {row.original?.executionSteps[0].modelName}
                                {length > 1 && ','}
                            </p>
                            {length > 1 && <p>+{length - 1}</p>}
                        </div>
                    )
                } else {
                    return <span>-</span>
                }
            },
        },
        {
            accessorKey: 'projectId',
            header: 'Project',
            enableSorting: false,
            cell: ({ row }) => {
                const project = projectsQuery.data?.find(
                    (project) => project.id === row.original.projectId
                )
                return project ? (
                    <div className={'flex gap-1 items-center'}>
                        {project?.projectIcon ? (
                            <img
                                src={project.projectIcon}
                                alt={project.name}
                                height={16}
                                width={16}
                            />
                        ) : (
                            <AiriaIcon className="w-4 h-4" />
                        )}
                        <span
                            className={
                                ' whitespace-nowrap overflow-hidden max-w-[200px] text-ellipsis inline-block'
                            }
                        >
                            {project?.name}
                        </span>
                    </div>
                ) : (
                    <span>-</span>
                )
            },
        },
        {
            accessorKey: 'temporary',
            header: 'Source',
            cell: ({ row }) => {
                if (row.original?.temporary) {
                    return <span>{t('playground')}</span>
                }
                return <span>{t('pipeline') as string}</span>
            },
        },
        {
            accessorKey: 'pipelineName',
            header: 'Pipeline',
            enableSorting: false,
            cell: ({ row }) =>
                row.original?.pipelineName &&
                row.original?.projectId && (
                    <Link
                        to={'/$projectId/pipelines/$pipelineId/$versionNumber'}
                        onClick={(e) => e.stopPropagation()}
                        params={{
                            projectId: row.original.projectId,
                            pipelineId: row.original.pipelineId,
                            versionNumber: `${row.original.pipelineVersionNumber ?? 1}`,
                        }}
                        className="flex items-center text-primary hover:underline whitespace-nowrap overflow-hidden max-w-[200px] truncate"
                    >
                        {row.original.pipelineName}
                        {row.original.pipelineVersionNumber &&
                            ` (v${row.original.pipelineVersionNumber})`}
                    </Link>
                ),
        },
        {
            header: 'API Key',
            accessorKey: 'apiKeyName',
            enableSorting: false,
            cell: ({ row }) => {
                const length = row.original.executionSteps?.length || 0
                if (length !== 0) {
                    return (
                        <div className="flex flex-row gap-1 text-primary-900">
                            <p className="text-gray-550">
                                {row.original.executionSteps[0].apiKeyName !== null &&
                                row.original.executionSteps[0].apiKeyName !== ''
                                    ? row.original.executionSteps[0].apiKeyName
                                    : 'AiriaKey'}
                            </p>
                            {length > 1 && <p>+{length - 1}</p>}
                        </div>
                    )
                } else {
                    return <span>-</span>
                }
            },
        },
        {
            header: 'Response Time',
            accessorKey: 'duration',
            cell: ({ row }) => {
                const duration = formatDuration(row.original.duration)
                return duration ? <p className="text-gray-550">{duration}</p> : null
            },
        },
        {
            header: 'Total Tokens',
            accessorKey: 'totalTokens',
        },
        {
            header: 'Prompt',
            accessorKey: 'systemPrompt',
            enableSorting: false,
            cell: ({ row }) => {
                const length = row.original.executionSteps?.length || 0
                if (length !== 0) {
                    return (
                        <div className="flex flex-row gap-1 text-primary-900">
                            {row.original.executionSteps[0].systemPrompt}
                            {row.original.executionSteps[0].systemPromptVersionNumber &&
                                ` (v${row.original.executionSteps[0].systemPromptVersionNumber})`}
                            {length > 1 && <p>+{length - 1}</p>}
                        </div>
                    )
                } else {
                    return <span>-</span>
                }
            },
        },
        {
            header: 'Status',
            accessorKey: 'status',
            enableSorting: false,
            cell: ({ row }) => {
                const status = row.original.success
                const statusText = status ? 'Success' : 'Failed'
                const statusColor = status ? 'bg-success-muted' : 'bg-error-muted'
                return (
                    <div className={'flex'}>
                        <div
                            className={`flex gap-2 px-1 rounded-md items-center text-xs ${statusColor}`}
                        >
                            {status ? <Check className={'h-3 w-3'} /> : <X className={'h-3 w-3'} />}
                            {statusText}
                        </div>
                    </div>
                )
            },
        },
        {
            id: 'tags',
            header: 'Tags',
            cell: ({ row }) => {
                return (
                    <div className="flex flex-row gap-2">
                        {row.original.tags.slice(0, 3).map(
                            (tag) =>
                                tag !== '' && (
                                    <Badge
                                        key={tag}
                                        className="rounded-lg bg-gray-250 text-gray-450 text-xs hover:text-gray-450 cursor-default"
                                    >
                                        {tag}
                                    </Badge>
                                )
                        )}
                        {row.original.tags.length > 3 && (
                            <Badge className="rounded-lg bg-gray-250 text-gray-450 text-xs hover:text-gray-450 cursor-default">
                                {`+${row.original.tags.length - 3}`}
                            </Badge>
                        )}
                    </div>
                )
            },
        },
    ]

    return (
        <>
            <FeedDataTable
                columns={columns}
                data={feedExecutionsQuery.data?.items ?? []}
                totalCount={feedExecutionsQuery.data?.totalCount ?? 0}
                onRowClick={handleRowClick}
                Header={
                    <FeedHeader
                        type="execution"
                        headerTitle="Pipeline Executions"
                        queryParams={queryParams}
                        refreshQueryKeys={[QueryKeys.EXECUTION_FEED_ALL, queryParams]}
                        totalCount={feedExecutionsQuery.data?.totalCount ?? 0}
                        additionalFilteringParameters={[
                            {
                                column: 'Source',
                                internalColumn: 'temporary',
                                value: [],
                                multiple: false,
                                valueFormatter(values) {
                                    return values.map((value) => {
                                        if (value === 'Pipeline') {
                                            return false
                                        }
                                        return true
                                    })
                                },
                            },
                        ]}
                        additionalPrepopulatedValues={[
                            { column: 'Source', values: ['Pipeline', 'Playground'] },
                        ]}
                    />
                }
            />

            {selectedCall && (
                <PipelineExecutionModal
                    isOpen={isModalOpen}
                    onClose={closeModal}
                    callDetails={selectedCall as PipelineFeedResult}
                />
            )}
        </>
    )
}
