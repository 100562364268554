import { useState } from 'react'
import {
    Command,
    CommandEmpty,
    CommandGroup,
    CommandInput,
    CommandItem,
    CommandList,
} from '../ui/command'
import { ScrollArea } from '../ui/scroll-area'
import { Checkbox } from '../ui/checkbox'
import { Popover, PopoverContent, PopoverTrigger } from '../ui/popover'
import { ChevronDown, ChevronUp } from 'lucide-react'
import { cn } from '@/lib/utils'

export type MultiSelectProps<T extends Record<string, any>> = {
    options: T[]
    placeholder: string
    searchLabel?: string
    className?: string
    onChange?: (event: string[]) => void
    value: string[]
    displayKey: keyof T
    valueKey: keyof T
}

function MultiSelect<T extends Record<string, any>>({
    options,
    placeholder,
    searchLabel,
    className,
    onChange,
    value,
    displayKey,
    valueKey,
}: MultiSelectProps<T>) {
    const [openComboBox, setOpenComboBox] = useState(false)

    const handleSetValue = (val: string) => {
        const checkVal = value?.includes(val)
        if (checkVal) {
            onChange?.([...value.filter((value) => value !== val)])
        } else {
            onChange?.([...value, val])
        }
    }

    return (
        <Popover open={openComboBox} onOpenChange={setOpenComboBox}>
            <PopoverTrigger
                className={cn(
                    'min-w-24 py-[10px] px-3 border-[1px] rounded-md rounded-l-none border-gray-250 h-9 flex items-center select-none cursor-pointer',
                    value?.length === 0 && 'text-gray-350',
                    className
                )}
                asChild
            >
                <div className="flex flex-row items-center justify-between gap-1">
                    <p className="text-sm">
                        {value?.length === 0 || value == null
                            ? placeholder
                            : value?.length > 1
                              ? `${options.find((option) => option[valueKey] === value[0])?.[displayKey]}, + ${value?.length - 1}`
                              : options.find((option) => option[valueKey] === value[0])?.[
                                    displayKey
                                ]}
                    </p>
                    {openComboBox ? (
                        <ChevronUp className="w-4 h-4 text-gray-350" />
                    ) : (
                        <ChevronDown className="w-4 h-4 text-gray-350" />
                    )}
                </div>
            </PopoverTrigger>
            <PopoverContent align="start" className="p-0">
                <Command
                    filter={(_, search, keywords) => {
                        if (keywords?.join(' ').toLowerCase().includes(search.toLowerCase())) {
                            return 1
                        }
                        return 0
                    }}
                >
                    <CommandInput placeholder={searchLabel ?? 'Search...'} />
                    <CommandList>
                        <ScrollArea>
                            <CommandEmpty>No results found.</CommandEmpty>
                            <CommandGroup className="">
                                {options.map((option) => (
                                    <CommandItem
                                        key={option[valueKey]}
                                        value={option[valueKey]}
                                        keywords={[option[displayKey]]}
                                        onSelect={(currentValue) => {
                                            handleSetValue(currentValue)
                                        }}
                                        className={cn(
                                            'aria-selected:bg-primary-100 aria-selected:text-primary flex items-center',
                                            value?.includes(option[valueKey]) &&
                                                'bg-primary-100 text-primary'
                                        )}
                                    >
                                        <Checkbox
                                            checked={value?.includes(option[valueKey])}
                                            className="w-4 h-4 mr-2 text-primary"
                                        />
                                        {option[displayKey]}
                                    </CommandItem>
                                ))}
                            </CommandGroup>
                        </ScrollArea>
                    </CommandList>
                </Command>
            </PopoverContent>
        </Popover>
    )
}

export default MultiSelect
