import type { paginationAndSortingParams } from '@/components/pagination/FeedHeader'
import apiRequest from '@/lib/apiClient.ts'

export type AiOperationsExecution = {
    executionDateTime: string
    tenantId: string
    providerType: string
    modelName: string
    pipelineId: string
    pipelineName: string
    pipelineVersion: string
    projectId: string
    projectName: string
    executionId: string
    executionSourceType: string
    executionUser?: string
    totalTokenAmountConsumed: string
    inputTokenAmountConsumed: string
    outputTokenAmountConsumed: string
    credentialsType: string
    totalTokenCountConsumed: number
    inputTokenCountConsumed: number
    outputTokenCountConsumed: number
    balanceUsed: string
}

export interface GetAllExecutionsResponseV1 {
    items: AiOperationsExecution[]
    totalCount: number
}

export interface ConsumptionFilterOptions {
    projectIds?: string[]
    executionDate?: 'thisMonth' | 'lastMonth' | 'last90Days' | number
}

export interface ExecutionQueryParams {
    descending?: string
    startTime?: string
    endTime?: string
    limit?: string
    offset?: string
    projectIds?: string
    executionSourceTypes?: string
    providerTypes?: string
    credentialsTypes?: string
}

export const getAllExecutions = async (
    queryParams?: paginationAndSortingParams & {
        startTime?: number
        endTime?: number
        projectIds?: string
        executionSourceTypes?: string
        providerType?: string
        credentialsTypes?: string
    }
): Promise<GetAllExecutionsResponseV1> => {
    const apiQueryParams: Partial<ExecutionQueryParams> = {
        descending: String(queryParams?.sortDirection === 'DESC'),
        startTime:
            (queryParams?.startTime?.toString() ?? queryParams?.startDate)
                ? new Date(queryParams?.startDate as string).getTime().toString()
                : undefined,
        endTime:
            (queryParams?.endTime?.toString() ?? queryParams?.endDate)
                ? new Date(queryParams?.endDate as string).getTime().toString()
                : undefined,
        limit: queryParams?.pageSize?.toString(),
    }

    if (queryParams?.pageNumber && queryParams?.pageSize) {
        apiQueryParams.offset = String(queryParams.pageSize * (queryParams.pageNumber - 1))
    }

    if (queryParams?.projectId) {
        apiQueryParams.projectIds = queryParams.projectId
    }

    if (queryParams?.executionSourceTypes) {
        apiQueryParams.executionSourceTypes = queryParams.executionSourceTypes
    }

    if (queryParams?.providerType) {
        apiQueryParams.providerTypes = queryParams.providerType
    }

    if (queryParams?.credentialsTypes) {
        apiQueryParams.credentialsTypes = queryParams.credentialsTypes
    }

    // Remove undefined values
    const cleanedParams = Object.fromEntries(
        Object.entries(apiQueryParams).filter(([_, v]) => v !== undefined)
    )

    return apiRequest('/api/marketplace/v1/AIOperationExecutions', {
        queryParams: cleanedParams,
    })
}

export const downloadOperationsCsv = (
    queryParams?: paginationAndSortingParams & {
        startTime?: number
        endTime?: number
        projectIds?: string
        executionSourceTypes?: string
        credentialsTypes?: string
    }
): Promise<string> => {
    const apiQueryParams: Partial<ExecutionQueryParams> = {
        descending: String(queryParams?.sortDirection === 'DESC'),
        startTime:
            (queryParams?.startTime?.toString() ?? queryParams?.startDate)
                ? new Date(queryParams?.startDate as string).getTime().toString()
                : undefined,
        endTime:
            (queryParams?.endTime?.toString() ?? queryParams?.endDate)
                ? new Date(queryParams?.endDate as string).getTime().toString()
                : undefined,
        limit: queryParams?.pageSize?.toString(),
    }

    if (queryParams?.pageNumber && queryParams?.pageSize) {
        apiQueryParams.offset = String(queryParams.pageSize * (queryParams.pageNumber - 1))
    }

    if (queryParams?.projectId) {
        apiQueryParams.projectIds = queryParams.projectId
    }

    if (queryParams?.executionSourceTypes) {
        apiQueryParams.executionSourceTypes = queryParams.executionSourceTypes
    }

    if (queryParams?.credentialsTypes) {
        apiQueryParams.credentialsTypes = queryParams.credentialsTypes
    }

    // Remove undefined values
    const cleanedParams = Object.fromEntries(
        Object.entries(apiQueryParams).filter(([_, v]) => v !== undefined)
    )
    return apiRequest('/api/marketplace/v1/AIOperationExecutions/export/csv', {
        headers: {
            'Content-Type': 'text/csv',
        },
        queryParams: cleanedParams,
    })
}
