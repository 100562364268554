import type React from 'react'
import { useEffect, useState } from 'react'
import { Button } from '@/components/ui/button.tsx'
import { downloadOperationsCsv } from '@/api/aiOperationsExecution.ts'
import { downloadCsv } from '@/lib/utils.ts'
import { downloadExecutionsCsv, downloadGatewayCsv } from '@/api/feed'
import { downloadIngestionCsv } from '@/api/datasets'
import type { paginationAndSortingParams } from '@/components/pagination/FeedHeader'
import { ArrowDownFromLine } from 'lucide-react'
import { toast } from '../ui/use-toast'

interface FeedExportProps {
    queryParams: paginationAndSortingParams
    type: 'ingestion' | 'gateway' | 'execution' | 'consumption' | 'processing'
}

const FeedExport: React.FC<FeedExportProps> = ({ queryParams, type }) => {
    const [disabled, setDisabled] = useState(false)
    const toUTC = (date: Date) => date.getTime()
    const [csvParams, setCsvParams] = useState<
        paginationAndSortingParams & {
            startTime?: number
            endTime?: number
        }
    >({ ...queryParams, pageSize: 1000 })

    const getExportAPI = (
        type: string,
        csvParams: paginationAndSortingParams & {
            startDate?: string | number | undefined
            endDate?: string | number | undefined
        }
    ) => {
        switch (type) {
            case 'ingestion':
                return downloadIngestionCsv(csvParams)
            case 'gateway':
                return downloadGatewayCsv(csvParams)
            case 'execution':
                return downloadExecutionsCsv(csvParams)
            case 'consumption':
                return downloadOperationsCsv(csvParams)
            default:
                return null
        }
    }

    const onExportClick = () => {
        setDisabled(true)
        getExportAPI(type, csvParams)
            ?.then((data) => {
                downloadCsv(data, `${type}-${Date.now()}.csv`)
            })
            .catch(() => {
                toast({
                    title: 'Error exporting CSV',
                    variant: 'destructive',
                    description: 'An error occurred while exporting CSV',
                })
            })
    }

    // biome-ignore lint/correctness/useExhaustiveDependencies: <explanation>
    useEffect(() => {
        if (type === 'consumption') {
            setCsvParams({
                ...queryParams,
                startTime: queryParams.startDate
                    ? toUTC(new Date(queryParams.startDate))
                    : undefined,
                endTime: queryParams.endDate ? toUTC(new Date(queryParams.endDate)) : undefined,
                pageSize: 1000,
            })
        } else {
            setCsvParams({ ...queryParams, pageSize: 1000 })
        }
    }, [queryParams])

    useEffect(() => {
        if (disabled) {
            setTimeout(() => {
                setDisabled(false)
            }, 5000)
        }
    }, [disabled])

    return (
        <Button
            variant="ghost"
            className="hover:bg-primary-muted rounded-full hover:text-primary flex items-center gap-2"
            onClick={() => onExportClick()}
            disabled={disabled || type === 'processing'} // TODO: modify once export on data processing is supported
        >
            <ArrowDownFromLine className="w-4 h-4" />
            Export
        </Button>
    )
}

export default FeedExport
