import type { GatewayFeedResult } from '@/api/feed'
import { getProxyReportConversation } from '@/api/gatewayUsage.ts'
import ProviderIcon from '@/components/modelLibrary/ProviderIcon'
import { Badge } from '@/components/ui/badge'
import { Dialog, DialogContent, DialogHeader, DialogTitle } from '@/components/ui/dialog'
import { QueryKeys } from '@/constants/QueryKeys'
import { cn, downloadFile } from '@/lib/utils'
import { useQuery } from '@tanstack/react-query'
import { themes } from 'prism-react-renderer'
import type React from 'react'
import { useEffect, useRef } from 'react'
import { CodeBlock } from 'react-code-block'
import { Button } from '@/components/ui/button'
import { t } from 'i18next'
import { Download, Clipboard } from 'lucide-react'
import { toast } from '@/components/ui/use-toast'

interface GatewayModalProps {
    isOpen: boolean
    onClose: () => void
    callDetails: GatewayFeedResult
}

const GatewayModal: React.FC<GatewayModalProps> = ({ isOpen, onClose, callDetails }) => {
    const chatEndRef = useRef<HTMLDivElement>(null)

    const chatHistoryQuery = useQuery({
        queryKey: [QueryKeys.FEED_DETAILS, callDetails.id],
        queryFn: () => getProxyReportConversation(callDetails.id),
    })

    const date = new Date(callDetails.createdAt)

    // biome-ignore lint/correctness/useExhaustiveDependencies: <explanation>
    useEffect(() => {
        setTimeout(() => {
            if (chatEndRef.current) {
                chatEndRef.current.scrollIntoView({ behavior: 'instant' })
            }
        }, 50)
    }, [isOpen])

    const renderDuration = (callDuration: string) => {
        const parts = callDuration.split(':')
        const hours = Number.parseInt(parts[0], 10)
        const minutes = Number.parseInt(parts[1], 10)
        const seconds = Number.parseFloat(parts[2])
        const totalSeconds = hours * 3600 + minutes * 60 + seconds
        const duration = `${totalSeconds.toFixed(2)} seconds`

        return <p className="text-[15px] text-gray-550">{duration}</p>
    }

    const copyToClipboard = async () => {
        await navigator.clipboard.writeText(JSON.stringify(chatHistoryQuery.data, null, 4))
        toast({
            title: 'Copy to clipboard',
            description: 'Report copied to clipboard',
        })
    }

    const download = () => {
        downloadFile(
            JSON.stringify(chatHistoryQuery.data, null, 4),
            `Gateway-Call-${date.toISOString()}.json`,
            'text/json'
        )
    }

    return (
        <Dialog open={isOpen} onOpenChange={onClose}>
            <DialogContent className="max-w-modal-lg max-h-[1000px]">
                <DialogHeader>
                    <DialogTitle>Call details</DialogTitle>
                </DialogHeader>
                <div className="flex flex-col space-y-4">
                    <div className="flex flex-col text-sm text-gray-550">
                        <span>Client IP: {callDetails.clientIpAddress}</span>
                        <span>User Agent: {callDetails.userAgent}</span>
                        <span>Prompt Tokens: {callDetails.promptTokens}</span>
                        <span>Completion Tokens: {callDetails.completionTokens}</span>
                    </div>

                    <table className="w-full text-sm">
                        <thead>
                            <tr className="text-left text-gray-500">
                                <th>Timestamp</th>
                                <th>Model</th>
                                <th>API Key</th>
                                <th>Response Time</th>
                                <th>Total Tokens</th>
                                <th>Tags</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td>
                                    <div className="flex flex-col">
                                        <p>{`${date.toLocaleDateString(undefined, { dateStyle: 'short' })} at ${date.toLocaleTimeString(undefined, { timeStyle: 'short' })}`}</p>
                                    </div>
                                </td>
                                <td className="py-2">
                                    <div className="flex flex-row items-center">
                                        <div className="flex flex-col gap-[3px]">
                                            <div className="flex flex-row items-center">
                                                <ProviderIcon
                                                    provider={callDetails.providerType}
                                                    className="w-6 h-6 mr-2 -ml-2"
                                                />
                                                <p className="text-gray-550">
                                                    {callDetails.providerType}
                                                </p>
                                            </div>
                                            <p className="text-[15px] text-gray-450 ml-6 max-w-32 text-ellipsis line-clamp-1">
                                                {callDetails.requestModel}
                                            </p>
                                        </div>
                                    </div>
                                </td>
                                <td>
                                    <div className="flex flex-col">
                                        <p className="text-gray-550">
                                            {callDetails.apiKeyName !== null &&
                                            callDetails.apiKeyName !== ''
                                                ? callDetails.apiKeyName
                                                : 'AiriaKey'}
                                        </p>
                                    </div>
                                </td>
                                <td>
                                    <div className="flex flex-col">
                                        {renderDuration(callDetails.duration)}
                                    </div>
                                </td>
                                <td>
                                    <p className="text-[15px] text-gray-550">
                                        {callDetails.totalTokens} tokens
                                    </p>
                                </td>
                                <td>
                                    {callDetails.streamed && (
                                        <div className="flex gap-2 flex-wrap max-w-[300px] items-center content-center align-middle m-auto">
                                            <Badge className="rounded-[24px] text-sm px-3 py-[2px] w-fit text-gray-550 bg-primary-100 hover:bg-primary-100 hover:text-gray-550 justify-center">
                                                Streamed
                                            </Badge>
                                        </div>
                                    )}
                                </td>
                            </tr>
                        </tbody>
                    </table>

                    <div className="mt-4">
                        <h3 className="font-semibold mb-2">Chat History</h3>
                        <div className="space-y-4 h-[400px] overflow-y-auto px-4">
                            {chatHistoryQuery.data?.map((message, index) => (
                                <div
                                    // biome-ignore lint/suspicious/noArrayIndexKey: <explanation>
                                    key={index}
                                    className={cn(
                                        'p-4 rounded-lg',
                                        message.role === 'system' || message.role === 'assistant'
                                            ? 'bg-blue-50'
                                            : 'bg-green-50'
                                    )}
                                >
                                    <div className="font-semibold">
                                        {message.role === 'system' || message.role === 'assistant'
                                            ? 'Response'
                                            : 'Request'}
                                    </div>
                                    <CodeBlock
                                        language="js"
                                        code={message.message}
                                        theme={themes.vsLight}
                                    >
                                        <CodeBlock.Code
                                            as="div"
                                            className="mt-1 p-2 rounded-xl bg-inherit text-wrap whitespace-pre-wrap break-all overflow-y-scroll"
                                        >
                                            <div className="table-row overflow-auto">
                                                <CodeBlock.LineNumber className="table-cell pr-4 w-12 text-xs text-gray-500 text-right select-none" />
                                                <CodeBlock.LineContent className="table-cell text-sm">
                                                    <CodeBlock.Token />
                                                </CodeBlock.LineContent>
                                            </div>
                                        </CodeBlock.Code>
                                    </CodeBlock>
                                    <div className="text-xs text-gray-500 mt-2">
                                        {message.createdAt}
                                    </div>
                                </div>
                            ))}
                            <div ref={chatEndRef} />
                        </div>
                        <div className="flex w-full flex-row justify-end gap-1 mt-3 -mb-3">
                            <Button
                                type="button"
                                variant="secondary"
                                className="text-primary border-primary flex justify-between gap-1"
                                onClick={copyToClipboard}
                            >
                                {t('copy_text')}
                                <Clipboard className="w-4" />
                            </Button>
                            <Button
                                type="button"
                                className="flex justify-between gap-1"
                                onClick={download}
                            >
                                {t('download_json')}
                                <Download className="w-4" />
                            </Button>
                        </div>
                    </div>
                </div>
            </DialogContent>
        </Dialog>
    )
}

export default GatewayModal
