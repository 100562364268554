import type { CompoundFilterParameters } from './FeedHeader'
import React, { useRef, useEffect } from 'react'
import { CommandEmpty } from '../ui/command'
import type { InfiniteData, UseInfiniteQueryResult } from '@tanstack/react-query'
import { useInView } from 'framer-motion'
import type { PageData } from '../pipelineBuilder/itemDrawer/types'

export type ItemFilterComponent<T> = React.FC<{
    item: T
    index: number
    thisParam: CompoundFilterParameters
    handleSetValue: (val: string) => void
}>

interface RenderInfiniteFilterItemsProps<T> {
    queryAndUpdateFilter: [
        UseInfiniteQueryResult<InfiniteData<PageData<T>, number>, Error>,
        (newFilter: string) => void,
    ]
    ItemFilterComponent: ItemFilterComponent<T>
    thisParam: CompoundFilterParameters
    debouncedSearchTerm: string
    handleSetValue: (val: string) => void
}

export function RenderInfiniteFilterItems<T>({
    queryAndUpdateFilter,
    ItemFilterComponent,
    thisParam,
    debouncedSearchTerm,
    handleSetValue,
}: RenderInfiniteFilterItemsProps<T>) {
    const [query, updateFilter] = queryAndUpdateFilter
    const { isLoading, isError, error, isFetchingNextPage, hasNextPage, fetchNextPage, data } =
        query
    const ref = useRef<HTMLDivElement>(null)
    const inViewport = useInView(ref)
    useEffect(() => {
        if (inViewport && hasNextPage) {
            fetchNextPage()
        }
    }, [inViewport, hasNextPage, fetchNextPage])

    useEffect(() => {
        updateFilter(debouncedSearchTerm)
    }, [debouncedSearchTerm, updateFilter])

    if (isError) return <p className="text-sm">Error loading filters: {error?.message}</p>
    return (
        <div className="space-y-2">
            {!isLoading && data?.pages && data.pages.length > 0
                ? data.pages.map((page, pageIndex) => (
                      <React.Fragment key={`${thisParam.column}-${pageIndex}`}>
                          {page.items.map((item, index) => (
                              <ItemFilterComponent
                                  key={`${thisParam.column}-${index}`}
                                  item={item}
                                  index={index}
                                  thisParam={thisParam}
                                  handleSetValue={handleSetValue}
                              />
                          ))}
                      </React.Fragment>
                  ))
                : isLoading && <CommandEmpty>Loading filters...</CommandEmpty>}
            {hasNextPage && (
                <div ref={ref}>{isFetchingNextPage && <CommandEmpty>Loading.</CommandEmpty>}</div>
            )}
            {!isLoading && <CommandEmpty>No filters found.</CommandEmpty>}
        </div>
    )
}
