import { type ClassValue, clsx } from 'clsx'
import { twMerge } from 'tailwind-merge'
import { format } from 'date-fns'
import millify from 'millify'

export function cn(...inputs: ClassValue[]) {
    return twMerge(clsx(inputs))
}

export function capitalizeFirstLetter(value: string) {
    if (!value) return value
    return value.charAt(0).toUpperCase() + value.slice(1)
}

export function isJSON(value: string): boolean {
    try {
        return !!value && !!JSON.parse(value)
    } catch (_) {
        return false
    }
}

export function isMarkdown(value: string): boolean {
    // const markdownRegex = [
    //     // ### Headers
    //     /(#{1,6}\s)(.*)/,
    //     // *_Italic_*
    //     /(\*|_)+(\S+)(\*|_)+/,
    //     // Bold text with double asterisks
    //     /\*\*[^*\n]+\*\*/,
    //     // [links](link.com)
    //     /(\[.*\])(\((http)(?:s)?(:\/\/).*\))/,
    //     // ![Images](link/to/image.png "text")
    //     /(!)(\[(?:.*)?\])(\(.*(\.(jpg|png|gif|tiff|bmp))(?:(\s"|').+("|'))?\))/,
    //     // * Unordered List
    //     /(^(\W{1})(\s)(.*)(?:$)?)+/,
    //     // ```json { "code": true }```
    //     /```[a-z]*\n[\s\S]*?\n```/,
    //     // > Block Quote
    //     /(\\`{1})(.*)(\\`{1})/,
    //     /(\\`{3}\\n+)(.*)(0\\n+\\`{3})/,
    //     /(=|-|\*){3}/,
    //     // --- horizontal line
    //     // Numbered list
    //     /^\d+\.\s.+/m,
    //     // Line breaks
    //     /\n{2,}/,
    // ]
    const markdownRegex =
        /(?:#{1,6}\s.*|[*_]+\S+[*_]+|\*\*[^*\n]+\*\*|\[.*\]\((?:https?:\/\/)?.*\)|\!\[(?:.*?)?\]\(.*\.(jpg|png|gif|tiff|bmp)(?:\s".*")?\)|^(\W{1})\s.*|```[a-z]*\n[\s\S]*?\n```|(?:`{1,3}).*(?:`{1,3})|(?:=|-|\*){3}|^\d+\.\s.+|\n{2,})/m

    return markdownRegex.test(value)
}

export function isBase64Image(value: string): boolean {
    const jpgPrefix = /\/9j\/[^\s"]+/
    const pngPrefix = /iVBORw0K[^\s"]+/
    let matchedValue: string | undefined
    if (jpgPrefix.test(value)) {
        matchedValue = value.match(jpgPrefix)?.at(0)
    } else if (pngPrefix.test(value)) {
        matchedValue = value.match(pngPrefix)?.at(0)
    }

    if (!matchedValue) return false

    try {
        return btoa(atob(matchedValue)) === matchedValue
    } catch (_) {
        return false
    }
}

export function getBase64String(value: string): string {
    const jpgPrefix = /\/9j\/[^\s"]+/
    const pngPrefix = /iVBORw0K[^\s"]+/
    let matchedValue = ''
    if (jpgPrefix.test(value)) {
        matchedValue = `data:image/jpg;base64,${value.match(jpgPrefix)?.at(0)}`
    } else if (pngPrefix.test(value)) {
        matchedValue = `data:image/png;base64,${value.match(pngPrefix)?.at(0)}`
    }

    return matchedValue
}

export function downloadFile(data: string, filename: string, type: string) {
    // Create a blob with the data we want to download as a file
    const blob = new Blob([data], { type })
    // Create an anchor element and dispatch a click event on it
    // to trigger a download
    const a = document.createElement('a')
    a.download = filename
    a.href = window.URL.createObjectURL(blob)
    const clickEvt = new MouseEvent('click', {
        view: window,
        bubbles: true,
        cancelable: true,
    })
    a.dispatchEvent(clickEvt)
    a.remove()
}

export const getApiUrl = () => {
    const currentDomain = window.location.hostname
    let apiDomain

    if (currentDomain.includes('localhost') || currentDomain.includes('127.0.0.1')) {
        // For local development
        return ''
    } else if (currentDomain === 'airia.ai') {
        // For production
        apiDomain = 'api.airia.ai'
    } else {
        // For all other environments (dev, staging, etc.)
        apiDomain = currentDomain.replace(/^([^.]+)\./, '$1.api.')
    }

    const apiBaseUrl = `https://${apiDomain}`
    return `${apiBaseUrl}`
}

export const getChatUrl = () => {
    const currentDomain = window.location.hostname
    let chatDomain: string

    if (currentDomain.includes('localhost') || currentDomain.includes('127.0.0.1')) {
        // For local development
        chatDomain = 'dev.chat.airiadev.ai'
    } else if (currentDomain === 'airia.ai') {
        // For production
        chatDomain = 'chat.airia.ai'
    } else {
        // For all other environments (dev, staging, etc.)
        chatDomain = currentDomain.replace(/^([^.]+)\./, '$1.chat.')
    }

    const chatBaseUrl = `https://${chatDomain}`
    return `${chatBaseUrl}`
}

export const getAuthUrl = () => {
    const currentDomain = window.location.hostname
    let apiDomain

    if (currentDomain.includes('localhost') || currentDomain.includes('127.0.0.1')) {
        // For local development
        return ''
    } else if (currentDomain === 'airia.ai') {
        // For production
        apiDomain = 'api.airia.ai'
    } else {
        // For all other environments (dev, staging, production, etc.)
        apiDomain = currentDomain.replace(/^([^.]+)\./, '$1.api.')
    }

    const apiBaseUrl = `https://${apiDomain}`
    return `${apiBaseUrl}`
}

export const downloadCsv = (csvData: string, filename: string) => {
    const blob = new Blob([csvData], { type: 'text/csv;charset=utf-8;' })
    const link = document.createElement('a')
    if (link.download !== undefined) {
        const url = URL.createObjectURL(blob)
        link.setAttribute('href', url)
        link.setAttribute('download', filename)
        link.style.visibility = 'hidden'
        document.body.appendChild(link)
        link.click()
        document.body.removeChild(link)
    }
}

export function formatCurrency(amount: number, currency = 'USD', locale = 'en-US') {
    return new Intl.NumberFormat(locale, {
        style: 'currency',
        currency: currency,
    }).format(amount)
}

export const formatDate = (date: string) => {
    return format(date, 'MMM dd, yyyy hh:mm a')
}

export const getTimezone = () => {
    const offset = new Date().getTimezoneOffset()
    return offset < 0 ? `+${Math.abs(offset) / 60}` : `-${offset / 60}`
}

export const formatDuration = (duration: string): string | null => {
    if (!duration || duration === '') {
        return null
    }

    const parts = duration.split(':')
    const hours = Number.parseInt(parts[0], 10)
    const minutes = Number.parseInt(parts[1], 10)
    const seconds = Number.parseFloat(parts[2])
    const totalMilliseconds = (hours * 3600 + minutes * 60 + seconds) * 1000

    return millify(totalMilliseconds, {
        precision: 2,
        units: ['ms', 's', 'm', 'h'],
    })
}

export const convertSvgToFile = (svgElement: Node, fileName?: string) => {
    const svgContent = new XMLSerializer().serializeToString(svgElement)

    const blob = new Blob([svgContent], { type: 'image/svg+xml;charset=utf-8' })

    return new File([blob], fileName ?? 'upload.svg', { type: 'image/svg+xml' })
}
