import { Credential, CredentialType, createCredential } from '@/api/credentials.ts'
import {
    CredentialFormField,
    credentialForm,
} from '@/components/credentials/CredentialFormBuilder.ts'
import { Button } from '@/components/ui/button.tsx'
import { Input } from '@/components/ui/input.tsx'
import { Label } from '@/components/ui/label.tsx'
import {
    Select,
    SelectContent,
    SelectGroup,
    SelectItem,
    SelectLabel,
    SelectTrigger,
    SelectValue,
} from '@/components/ui/select.tsx'
import { capitalizeFirstLetter } from '@/lib/utils.ts'
import { useMutation } from '@tanstack/react-query'
import { useEffect, useState } from 'react'
import { Controller, useForm, useWatch } from 'react-hook-form'
import { useTranslation } from '@/lib/i18n'
import { toast } from '@/components/ui/use-toast.ts'
import { ProjectSelect } from '@/components/modelLibrary/selectProjectForm.tsx'
import { TriangleAlert } from 'lucide-react'

interface NewCredentialsFormProps {
    onSubmit: (data: Credential) => void
    onCancel?: () => void
    type?: CredentialType
    projectId?: string
}

export function NewCredentialsForm({
    onSubmit,
    type,
    projectId,
    onCancel,
}: NewCredentialsFormProps) {
    const { t } = useTranslation()
    const { register, handleSubmit, formState, control, setValue, getValues } = useForm<Credential>(
        {
            defaultValues: {
                projectId: projectId,
                type,
            },
        }
    )
    const [selectedType, setSelectedType] = useState<CredentialType | null>(type ?? null)
    const watchedProjectId = useWatch({ control, name: 'projectId' })
    const credentialsMutation = useMutation({
        mutationFn: createCredential,
        onError: (error: { status: number; message: string }) => {
            if (error.status === 409) {
                toast({
                    title: t('error_title'),
                    description: error.message,
                    variant: 'destructive',
                })
            } else {
                // Handle unexpected error structures
                toast({
                    title: t('error_title'),
                    description: t('error_description_generic'),
                    variant: 'destructive',
                })
            }
        },
    })

    useEffect(() => {
        register('credentialData')
    }, [register])

    useEffect(() => {
        if (selectedType !== null) {
            const dataFields: { key: string; value: string }[] = []
            // biome-ignore lint/complexity/noForEach: <explanation>
            credentialForm[selectedType].fields.forEach((field) => {
                dataFields.push({ key: field.name, value: '' })
            })
            setValue('credentialData', dataFields)
        }
    }, [selectedType, setValue])

    const onFormSubmit = async (data: Credential) => {
        try {
            const credential = await credentialsMutation.mutateAsync(data)
            onSubmit(credential)
        } catch (e) {
            console.log('error creating credentials', e)
        }
    }

    const onInputChange = (name: string, value: string) => {
        const data = getValues('credentialData')
        const updatedData = data.map((field) => {
            if (field.key === name) {
                return { key: field.key, value: value }
            }
            return field
        })
        setValue('credentialData', updatedData)
    }

    return (
        <>
            {credentialsMutation.isError && (
                <p className="text-sm text-error-message pt-1 overflow-hidden whitespace-nowrap text-overflow-ellipsis max-w-full">
                    {credentialsMutation.error.message}
                </p>
            )}
            <form onSubmit={handleSubmit(onFormSubmit)} className="grid grid-cols-2 gap-4">
                <div className="col-span-1 flex flex-col">
                    <Label className="mb-1" htmlFor="name">
                        {t('new_credentials.name')}
                    </Label>
                    <Input
                        required
                        id="name"
                        {...register('name', { required: true })}
                        className={credentialsMutation.isError ? 'border-error-message' : ''}
                    />
                </div>
                <div className="col-span-1 flex flex-col">
                    <Label className="mb-1" htmlFor="type">
                        {t('new_credentials.type')}
                    </Label>
                    <Controller
                        control={control}
                        name={'type'}
                        rules={{ required: true }}
                        render={({ field }) => (
                            <Select
                                {...field}
                                disabled={type !== undefined}
                                onValueChange={(val) => {
                                    field.onChange(val)
                                    setSelectedType(val as CredentialType)
                                }}
                            >
                                <SelectTrigger>
                                    <SelectValue placeholder={t('new_credentials.select_type')} />
                                </SelectTrigger>
                                <SelectContent>
                                    <SelectGroup>
                                        <SelectLabel>
                                            {t('new_credentials.select_type')}
                                        </SelectLabel>
                                        {Object.values(CredentialType)
                                            .sort()
                                            .map((type) => (
                                                <SelectItem key={type} value={type}>
                                                    {capitalizeFirstLetter(type)}
                                                </SelectItem>
                                            ))}
                                    </SelectGroup>
                                </SelectContent>
                            </Select>
                        )}
                    />
                </div>
                {selectedType &&
                    credentialForm[selectedType].fields.map((field: CredentialFormField) => (
                        <div key={field.name} className="col-span-2 flex flex-col">
                            <Label className="mb-1" htmlFor={field.name}>
                                {field.label}
                            </Label>
                            <Input
                                required
                                id={field.name}
                                onChange={(event) => onInputChange(field.name, event.target.value)}
                            />
                        </div>
                    ))}

                <div className="col-span-2">
                    <Label className="mb-1" htmlFor="projects">
                        {t('scope')}
                    </Label>
                    <Controller
                        control={control}
                        name="projectId"
                        render={({ field }) => (
                            <ProjectSelect
                                selectedProjectId={field.value}
                                setSelectedProjectId={(val) => field.onChange(val)}
                                placeholder={t('all_projects')}
                                filterIds={projectId ? [projectId] : undefined}
                            />
                        )}
                    />
                </div>
                {(watchedProjectId == null || watchedProjectId === '') && (
                    <div className="col-span-2 flex items-center text-yellow-600 bg-warning-muted p-2 rounded text-sm">
                        <TriangleAlert className="mr-2" />
                        {t('new_credentials.warning_description')}
                    </div>
                )}
                <div className="col-span-2 mt-4 flex justify-end space-x-2">
                    {onCancel && (
                        <Button variant="outline" onClick={() => onCancel()}>
                            {t('cancel')}
                        </Button>
                    )}
                    <Button type="submit" disabled={!formState.isValid}>
                        {t('create')}
                    </Button>
                </div>
            </form>
        </>
    )
}
