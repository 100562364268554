import { CreateDataSourceFile, GetDatasetFilesById, UpdateDataSourceFile } from '@/api/datasets'
import { useToast } from '@/components/ui/use-toast'
import { QueryKeys } from '@/constants/QueryKeys'
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query'
import { useState } from 'react'
import { useTranslation } from '@/lib/i18n'
import DragAndDropLegal from '../documents/dragAndDropLegal'

export const UploadLegalFiles = ({
    userId,
    datasourceId,
    onUpload,
}: {
    userId: string
    datasourceId: string
    onUpload: () => void
}): JSX.Element => {
    const queryClient = useQueryClient()
    const { toast } = useToast()
    const { t } = useTranslation()
    const [files, setFiles] = useState<File[]>([])

    const createDataSetFileMutation = useMutation({
        mutationFn: (args: { id: string; file: File; projectId: string }) =>
            CreateDataSourceFile(args.id, args.file, args.projectId, { userId }),
        onSuccess: () => {
            queryClient.invalidateQueries({
                queryKey: [QueryKeys.FILE_LIST, datasourceId],
            })
            queryClient.invalidateQueries({
                queryKey: [QueryKeys.ALL_DATASETS],
            })
            toast({
                title: t('success_title'),
                description: 'Successfully uploaded file',
            })
        },
        onError: () => {
            toast({
                title: t('error_title'),
                description: t('error_description_generic'),
                variant: 'destructive',
            })
        },
    })
    const updateDataSetFileMutation = useMutation({
        mutationFn: (args: {
            dataSourceId: string
            fileId: string
            file: File
            projectId: string
        }) =>
            UpdateDataSourceFile(args.dataSourceId, args.fileId, args.file, args.projectId, {
                userId,
            }),
        onSuccess: () => {
            queryClient.invalidateQueries({
                queryKey: [QueryKeys.FILE_LIST, datasourceId],
            })
            toast({
                title: t('success_title'),
                description: t('file_update_success'),
            })
        },
        onError: () => {
            toast({
                title: t('error_title'),
                description: t('error_description_generic'),
                variant: 'destructive',
            })
        },
    })
    const fileListQuery = useQuery({
        queryKey: [QueryKeys.FILE_LIST, datasourceId, userId],
        queryFn: () => GetDatasetFilesById(datasourceId, { userId }),
    })

    const handleUpload = async (file: File, action?: 'update' | 'addBoth') => {
        if (action === 'update') {
            const existingFile = fileListQuery.data?.find((f) => f.name === file.name)
            if (existingFile) {
                await updateDataSetFileMutation.mutateAsync({
                    dataSourceId: datasourceId,
                    fileId: existingFile.fileId ?? '',
                    file: file,
                    projectId: '',
                })
            }
        } else {
            // For new files or 'addBoth' action
            let fileName = file.name
            if (action === 'addBoth') {
                // Append timestamp to filename for 'addBoth' action
                const timestamp = Date.now()
                const nameParts = file.name.split('.')
                const extension = nameParts.pop()
                fileName = `${nameParts.join('.')}_${timestamp}.${extension}`
            }
            const newFile =
                //@ts-ignore
                action === 'addBoth' ? new File([file], fileName, { type: file.type }) : file
            await createDataSetFileMutation.mutateAsync({
                id: datasourceId,
                file: newFile,
                projectId: '',
            })
            onUpload()
        }
    }

    return (
        <DragAndDropLegal
            files={files}
            setFiles={setFiles}
            handleUpload={handleUpload}
            onUploadComplete={() => {
                setFiles([])
                queryClient.invalidateQueries({
                    queryKey: [QueryKeys.FILE_LIST, datasourceId],
                })
            }}
            existingFiles={fileListQuery.data ?? []}
        />
    )
}
