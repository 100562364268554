import { Button } from '@/components/ui/button'
import { Input } from '@/components/ui/input'
import {
    Select,
    SelectContent,
    SelectGroup,
    SelectItem,
    SelectLabel,
    SelectTrigger,
    SelectValue,
} from '@/components/ui/select'
import { cn } from '@/lib/utils'
import { X } from 'lucide-react'
import { Dispatch, FC, SetStateAction } from 'react'
import { UseCase } from './UseCase'

export type Parameter = {
    id: string
    name: string
    type: string
    default?: string
    description: string
    validOptions: string[]
}

interface ParameterProps {
    index: number
    parameters: Parameter[]
    setParameters: Dispatch<SetStateAction<Parameter[]>>
    setUseCases: Dispatch<SetStateAction<UseCase[]>>
}

export const Parameter: FC<ParameterProps> = (props) => {
    const { index, parameters, setParameters, setUseCases } = props

    return (
        <div className="flex flex-row items-center w-full">
            {parameters.length > 1 && (
                <Button
                    type="button"
                    variant="ghost"
                    className="px-3 py-1 rounded-full"
                    onClick={() => {
                        setParameters((prev) => prev.filter((_, i) => i !== index))
                        setUseCases((prev) => {
                            return prev.map((useCase) => {
                                return {
                                    ...useCase,
                                    toolParameters: useCase.examples.filter(
                                        (param) => param.toolParameterId !== parameters[index].id
                                    ),
                                }
                            })
                        })
                    }}
                >
                    <X className="h-4 w-4" />
                </Button>
            )}
            <div
                className={cn(
                    'grid grid-cols-2 gap-1 w-full bg-gray-100/50 dark:bg-gray-100/5 p-3 rounded-md',
                    parameters[index].type === 'enum' && 'grid-cols-3'
                )}
            >
                <div className={cn('col-span-1 flex flex-row gap-2 bg-background')}>
                    <Input
                        required
                        value={parameters[index].name}
                        onChange={(e) => {
                            parameters[index].name = e.currentTarget.value
                            setParameters([...parameters])
                        }}
                        placeholder="Name"
                    />
                </div>

                <div className="col-span-1 bg-background">
                    <Input
                        required
                        value={parameters[index].description}
                        onChange={(e) => {
                            parameters[index].description = e.currentTarget.value
                            setParameters([...parameters])
                        }}
                        placeholder="Description"
                    />
                </div>
                {parameters[index].type !== 'enum' && (
                    <div className="col-span-1 bg-background">
                        <Input
                            value={parameters[index].default}
                            onChange={(e) => {
                                parameters[index].default = e.currentTarget.value
                                setParameters([...parameters])
                            }}
                            placeholder={
                                parameters[index].type === 'array'
                                    ? 'Default values should be separated by commas'
                                    : 'Default Value'
                            }
                        />
                    </div>
                )}
                {parameters[index].type === 'enum' && (
                    <>
                        <div className="col-span-1 bg-background">
                            <Input
                                required
                                value={parameters[index]?.validOptions?.join(',') ?? ''}
                                onChange={(e) => {
                                    parameters[index].validOptions =
                                        e.currentTarget.value.split(',')
                                    setParameters([...parameters])
                                }}
                                placeholder="Valid options"
                            />
                        </div>
                        <div className="col-span-1 bg-background">
                            <Select
                                required
                                onValueChange={(val) => {
                                    parameters[index].default = val
                                    setParameters([...parameters])
                                }}
                                value={parameters[index].default}
                            >
                                <SelectTrigger>
                                    <SelectValue placeholder="Default Value" />
                                </SelectTrigger>
                                <SelectContent>
                                    <SelectGroup>
                                        <SelectLabel>Default</SelectLabel>
                                        {parameters[index].validOptions.map((option) => {
                                            if (option === '') return null
                                            return (
                                                <SelectItem key={option} value={option}>
                                                    {option}
                                                </SelectItem>
                                            )
                                        })}
                                    </SelectGroup>
                                </SelectContent>
                            </Select>
                        </div>
                    </>
                )}

                <div className="flex flex-row gap-2 col-span-1 bg-background">
                    <Select
                        required
                        onValueChange={(val) => {
                            parameters[index].type = val
                            setParameters([...parameters])
                        }}
                        value={parameters[index].type}
                    >
                        <SelectTrigger>
                            <SelectValue placeholder="Type" />
                        </SelectTrigger>
                        <SelectContent>
                            <SelectGroup>
                                <SelectLabel>Type</SelectLabel>
                                <SelectItem value="string">string</SelectItem>
                                <SelectItem value="integer">integer</SelectItem>
                                <SelectItem value="enum">enum</SelectItem>
                                <SelectItem value="boolean">boolean</SelectItem>
                                <SelectItem value="array">array</SelectItem>
                            </SelectGroup>
                        </SelectContent>
                    </Select>
                </div>
            </div>
        </div>
    )
}
