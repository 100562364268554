import type React from 'react'
import { useState } from 'react'
import { useQuery } from '@tanstack/react-query'
import type { ColumnDef } from '@tanstack/react-table'
import type { paginationAndSortingParams } from '@/components/pagination/paginationHeader'
import GatewayModal from '@/components/home/feed/GatewayModal'
import { type StepExecutionContext, getGatewayFeed, type GatewayFeedResult } from '@/api/feed'
import ProviderIcon from '@/components/modelLibrary/ProviderIcon'
import { QueryKeys } from '@/constants/QueryKeys.ts'
import { getAllProjects } from '@/api/projects.ts'
import AiriaIcon from '@/assets/icons/airia-icon.svg?react'
import { FeedDataTable } from '@/components/home/feed/feedDataTable/FeedDataTable.tsx'
import { formatDate, getTimezone, formatDuration } from '@/lib/utils.ts'
import FeedHeader from '@/components/pagination/FeedHeader'
import { MODEL_PROVIDERS } from '@/api/credentials'

export interface FeedTableProps {
    queryParams: paginationAndSortingParams
}

export const GatewayFeedTable: React.FC<FeedTableProps> = ({ queryParams }) => {
    const [selectedCall, setSelectedCall] = useState<
        GatewayFeedResult | StepExecutionContext | null
    >(null)
    const [isModalOpen, setIsModalOpen] = useState(false)

    const projectsQuery = useQuery({
        queryKey: [QueryKeys.PROJECTS],
        queryFn: () => getAllProjects(),
    })
    const gatewayFeedQuery = useQuery({
        queryKey: [QueryKeys.GATEWAY_FEED_ALL, queryParams],
        queryFn: () =>
            getGatewayFeed(
                queryParams.pageSize && queryParams.pageNumber
                    ? { ...queryParams }
                    : { pageSize: 25, pageNumber: 1 }
            ),
    })

    const handleRowClick = (row: { original: GatewayFeedResult }) => {
        setSelectedCall(row.original)
        setIsModalOpen(true)
    }

    const closeModal = () => {
        setIsModalOpen(false)
        setSelectedCall(null)
    }

    const columns: ColumnDef<GatewayFeedResult>[] = [
        {
            accessorKey: 'createdAt',
            header: `Timestamp (GMT${getTimezone()})`,
            cell: ({ row }) => <p>{formatDate(row.original.createdAt)}</p>,
        },
        {
            header: 'Model Provider',
            accessorKey: 'providerType',
            cell: ({ row }) => {
                return (
                    <div className="flex gap-1 items-center">
                        <ProviderIcon provider={row.original.providerType} className="w-4 h-4" />
                        <p className="text-gray-550">{row.original.providerType}</p>
                    </div>
                )
            },
        },
        {
            header: 'Model Name',
            accessorKey: 'requestModel',
        },
        {
            accessorKey: 'projectId',
            header: 'Project',
            cell: ({ row }) => {
                const project = projectsQuery.data?.find(
                    (project) => project.id === row.original.projectId
                )
                return project ? (
                    <div className={'flex gap-1 items-center'}>
                        {project?.projectIcon ? (
                            <img
                                src={project.projectIcon}
                                alt={project.name}
                                height={16}
                                width={16}
                            />
                        ) : (
                            <AiriaIcon className="w-4 h-4" />
                        )}
                        <span
                            className={
                                ' whitespace-nowrap overflow-hidden max-w-[200px] text-ellipsis inline-block'
                            }
                        >
                            {project?.name}
                        </span>
                    </div>
                ) : (
                    <span>-</span>
                )
            },
        },
        {
            header: 'API Key',
            accessorKey: 'apiKeyName',
            cell: ({ row }) => (
                <span className="text-gray-550">
                    {row.original.apiKeyName !== null && row.original.apiKeyName !== ''
                        ? row.original.apiKeyName
                        : 'AiriaKey'}
                </span>
            ),
        },
        {
            header: 'Response Time',
            accessorKey: 'duration',
            cell: ({ row }) => {
                const duration = formatDuration(row.original.duration)
                return <p className="text-gray-550">{duration}</p>
            },
        },
        {
            header: 'Total Tokens',
            accessorKey: 'totalTokens',
        },
        {
            header: 'Client IP Address',
            accessorKey: 'clientIpAddress',
        },
    ]

    return (
        <>
            <FeedDataTable
                columns={columns}
                data={gatewayFeedQuery.data?.items ?? []}
                totalCount={gatewayFeedQuery.data?.totalCount ?? 0}
                onRowClick={handleRowClick}
                Header={
                    <FeedHeader
                        type="gateway"
                        headerTitle="Gateway Monitoring"
                        queryParams={queryParams}
                        refreshQueryKeys={[QueryKeys.GATEWAY_FEED_ALL, queryParams]}
                        totalCount={gatewayFeedQuery.data?.totalCount ?? 0}
                        additionalFilteringParameters={[
                            {
                                column: 'Model Provider',
                                internalColumn: 'providerType',
                                value: [],
                                multiple: true,
                            },
                        ]}
                        additionalPrepopulatedValues={[
                            { column: 'Model Provider', values: MODEL_PROVIDERS },
                        ]}
                    />
                }
            />
            {selectedCall && (
                <GatewayModal
                    isOpen={isModalOpen}
                    onClose={closeModal}
                    callDetails={selectedCall as GatewayFeedResult}
                />
            )}
        </>
    )
}

export const getPrettyTag = (tag: string) => {
    const lowerCaseTag = tag.toLocaleLowerCase()
    switch (lowerCaseTag) {
        case 'datasearch':
            return 'RAG'
        case 'pythonstep':
            return 'Python'
        default:
            return tag
    }
}
