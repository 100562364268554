import { LibaryTool } from '@/api/library.ts'
import { Plus } from 'lucide-react'

interface ToolLibraryCardProps {
    tool: LibaryTool
    onCardClick?: (toolId: string) => void
}

export function ToolLibraryCard({ tool, onCardClick }: ToolLibraryCardProps) {
    return (
        <div
            className={`border rounded-sm border-border bg-background h-full shadow-none p-md text-left text-pretty ${onCardClick && 'cursor-pointer hover:bg-card'}`}
            onClick={() => onCardClick && onCardClick(tool.id)}
        >
            <div className={'flex flex-col justify-between h-full gap-md'}>
                <span className={'text-xl'}>{tool.name}</span>
                <span className="line-clamp-4 text-base text-gray-450">{tool.description}</span>
                <div className="flex items-end justify-end mt-auto text-nowrap">
                    <div>
                        <Plus className="text-gray-350" />
                    </div>
                </div>
            </div>
        </div>
    )
}
