import apiRequest from '@/lib/apiClient'

export type Credential = {
    id: string
    projectId?: string
    name: string
    createdAt: string
    type: CredentialType
    credentialData: { key: string; value: string }[] // { key: string; value: string }[]
}

export type CredentialList = {
    items: Credential[]
    totalCount: number
}

export enum CredentialType {
    OpenAI = 'OpenAI',
    Ollama = 'Ollama',
    Azure = 'Azure',
    HuggingFace = 'HuggingFace',
    Anthropic = 'Anthropic',
    AzureAIStudio = 'AzureAIStudio',
    OpenVINO = 'OpenVINO',
    PyTorch = 'PyTorch',
    AzureBlob = 'AzureBlob',
    S3 = 'S3',
    ScaleFlex = 'ScaleFlex',
    Replicate = 'Replicate',
    Stability = 'Stability',
    Mistral = 'Mistral',
    Perplexity = 'Perplexity',
    WatsonAix = 'WatsonAix',
    AI21 = 'AI21',
    Cohere = 'Cohere',
    Google = 'Google',
    Pinecone = 'Pinecone',
    TogetherAI = 'TogetherAI',
}

export const MODEL_PROVIDERS = [
    'AI21',
    'Anthropic',
    'Azure',
    'AzureAIStudio',
    'Cohere',
    'Google',
    'HuggingFace',
    'Mistral',
    'Ollama',
    'OpenAI',
    'OpenVINO',
    'PyTorch',
    'Replicate',
    'Stability',
    'Perplexity',
    'WatsonAix',
    'TogetherAI',
]

export const getAllCredentials = async (
    queryParams?: Record<string, string | number> | undefined
): Promise<CredentialList> => {
    return apiRequest('/v1/Credentials', { queryParams })
}

export const getAllCredentialsByProjectidandType = async (
    projectId: string,
    type: string
): Promise<CredentialList> => {
    return apiRequest(`/v1/Credentials/project/${projectId}/type/${type}`)
}

export const getCredentialById = async (id: string): Promise<Credential> => {
    return apiRequest(`/v1/Credentials/${id}`)
}

export const createCredential = async (credential: Credential): Promise<Credential> => {
    return apiRequest('/v1/Credentials', {
        method: 'POST',
        body: {
            ...credential,
            credentialData: JSON.stringify(credential.credentialData),
        },
    })
}

export const updateCredential = async (credential: Credential): Promise<void> => {
    return apiRequest(`/v1/Credentials/${credential.id}`, {
        method: 'PUT',
        body: {
            ...credential,
            credentialData: JSON.stringify(credential.credentialData),
        },
    })
}

export const deleteCredentials = async (id: string): Promise<void> => {
    return apiRequest(`/v1/Credentials/${id}`, { method: 'DELETE' })
}
