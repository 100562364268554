import apiRequest from '@/lib/apiClient'

export type UserPrompt = {
    userPromptId: string
    name: string
    message: string
}

export const getAllUserPrompts = async (): Promise<UserPrompt[]> => {
    return apiRequest('/v1/UserPrompts')
}
