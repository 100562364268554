import Search from '@/assets/icons/search.svg?react'
import { Button } from '@/components/ui/button.tsx'
import { Card, CardContent } from '@/components/ui/card.tsx'
import { Input } from '@/components/ui/input.tsx'
import { useDebounce } from '@uidotdev/usehooks'
import React, { useEffect } from 'react'

interface MegaSearchProps {
    onSearch: (query: string) => void
    placeholder?: string
}

const MegaSearch: React.FC<MegaSearchProps> = ({ placeholder, onSearch }) => {
    const [query, setQuery] = React.useState('')
    const debouncedQuery = useDebounce(query, 500)

    // biome-ignore lint/correctness/useExhaustiveDependencies: <explanation>
    useEffect(() => {
        onSearch(debouncedQuery)
    }, [debouncedQuery])

    return (
        <Card className={'rounded-full bg-background'}>
            <CardContent className={'p-2'}>
                <form
                    onSubmit={(e) => {
                        e.preventDefault()
                        onSearch(query)
                    }}
                >
                    <div className="flex w-full items-center">
                        <div className="flex items-center space-x-2 w-full">
                            <Input
                                id="model-search"
                                value={query}
                                onChange={(e) => setQuery(e.target.value)}
                                placeholder={placeholder}
                                className="w-full p-2 bg-transparent border-none shadow-none focus-visible:ring-0"
                                autoComplete={'off'}
                            />
                        </div>
                        <Button
                            type={'submit'}
                            size={'icon'}
                            className="ml-2 p-2 bg-primary text-primary-foreground rounded-full focus:outline-none focus:ring-2 focus:ring-primary focus:ring-offset-2"
                        >
                            <Search className="w-4 h-4" />
                        </Button>
                    </div>
                </form>
            </CardContent>
        </Card>
    )
}

export default MegaSearch
