import OnboardingActionIcon from '@/assets/icons/onboarding-action.svg?react'
import OnboardingMapIcon from '@/assets/icons/onboarding-map.svg?react'
import { Button } from '@/components/ui/button'
import { Dialog, DialogContent } from '@/components/ui/dialog'
import type { StepType } from '@reactour/tour'
import type { Dispatch, SetStateAction } from 'react'
import FileQuestion from '@/assets/icons/file-question.svg?react'
import { MessagesSquare } from 'lucide-react'

type OnboardingStyleBase = {
    base: {
        [key: string]: string
    }
}

export const ONBOARDING_STYLES = {
    popover: (base: OnboardingStyleBase) => ({
        ...base,
        borderRadius: '8px',
        padding: '16px',
        backgroundColor: 'hsl(var(--background))',
        '--reactour-accent': 'var(--primary)',
    }),
    maskArea: (base: OnboardingStyleBase) => ({
        ...base,
        rx: '4px',
    }),
    badge: (base: OnboardingStyleBase) => ({
        ...base,
        color: 'var(--primary-muted)',
    }),
}

export const steps: StepType[] = [
    {
        selector: '.welcome-step',
        content:
            'Welcome to Airia!\nAiria makes it easy to build and manage AI-powered applications. Let me give you a quick tour of the platform.',
        position: 'center',
        styles: {
            svgWrapper: (base) => ({
                ...base,
                color: '#000000',
                backgroundColor: '#000000',
                opacity: 1,
            }),
        },
    },
    {
        selector: '.projects-step',
        content:
            "Select a Project\nProjects are collections of the various components that make up AI applications. Think about Projects as a way to organize all of your AI initiatives and workstreams.\nSelect the Default Project we've created for you here, and we’ll take a look at what’s inside.",
        position: 'right',
    },
    {
        selector: '.pipeline-step',
        content:
            'Create Pipelines\nA Project can contain one or many components that enrich your AI workflows, or Pipelines. We’ve added a Model and a Data Source to power your first Pipeline.',
        position: 'bottom',
    },
    {
        selector: '.model-step',
        content:
            'Add Models\nNavigate to the Models tab to add approved Models from the Model Library or add a custom Model to use in your Pipelines.',
        position: 'bottom',
    },
    {
        selector: '.datasource-step',
        content:
            'Add Data Sources\nAdd an external Data Source that allows your Model to look up relevant information from trusted sources before generating a response, rather than relying solely on its pre-trained knowledge. This technique is called Retrieval Augmented Generation.',
        position: 'bottom',
    },
    {
        selector: '.prompt-step',
        content:
            'Create Prompts\nConfigure or workshop Prompts in our Prompt Workbench to ensure your applications get the relevant results. ',
        position: 'bottom',
    },
    {
        selector: '.memory-step',
        content:
            'Create Memory\nStore Model interaction data by adding a Memory object to your Pipeline.',
        position: 'bottom',
    },
    {
        selector: '.tool-step',
        content:
            'Create Tools\nExtend your Model with Tools to call external APIs that allow it to retrieve real time information or take actions.',
        position: 'bottom',
    },
    {
        selector: '.libraries-step',
        content:
            'Explore Libraries\nLibraries are your easy button to approve & add Models and Data Sources to Projects.',
        position: 'right',
    },
    {
        selector: '.users-groups-step',
        content:
            'Add additional users\nNavigate to Users to invite additional users to the Airia platform.',
        position: 'right',
    },
    {
        selector: '.additional-info-step',
        content: 'For more information\nChat with our documents or reference our user guides.',
        position: 'center',
        styles: {
            svgWrapper: (base) => ({
                ...base,
                color: '#000000',
                backgroundColor: '#000000',
                opacity: 1,
            }),
        },
    },
    {
        selector: '.finish-step',
        content: `Congratulations!\nWe've added $20 to your Airia account to help you get started. Use the chat icon or docs icon for additional information.`,
        position: 'center',
        styles: {
            svgWrapper: (base) => ({
                ...base,
                color: '#000000',
                backgroundColor: '#000000',
                opacity: 1,
            }),
        },
    },
]

export const handleClose = ({ setIsOpen }: { setIsOpen: Dispatch<SetStateAction<boolean>> }) => {
    setIsOpen(false)
    const onboarding = JSON.parse(localStorage.getItem('platform-onboarding') ?? '{}')
    localStorage.setItem(
        'platform-onboarding',
        JSON.stringify({ ...onboarding, enabled: false, skipped: true })
    )
}

//@ts-ignore
export const CustomContent = ({ content, currentStep }) => {
    const split = content.split('\n')
    return (
        <div className="flex flex-col gap-2 bg-background text-pretty">
            <p className="font-bold">{split[0]}</p>
            {currentStep === 10 && (
                <div className="flex flex-col gap-1 p-2">
                    <div className="flex flex-row items-center content-center self-center justify-center gap-5">
                        <MessagesSquare className="w-8 h-8" />
                        <div className="flex flex-row items-center gap-1">
                            <FileQuestion className="w-8 h-8" />
                        </div>
                    </div>
                </div>
            )}
            {currentStep === 11 && <p className="text-center text-6xl">$20</p>}
            {currentStep !== 11 &&
                split.map(
                    (text: string, index: number) =>
                        index !== 0 && <p key={`${index}-${text}`}>{text}</p>
                )}
            {currentStep === 11 && (
                <div className="flex flex-col gap-1 text-center">
                    <p>We've added $20 to your Airia account to help you get started.</p>
                </div>
            )}
        </div>
    )
}

export const renderPreviousButton = ({
    currentStep,
    setCurrentStep,
}: {
    currentStep: number
    setCurrentStep: Dispatch<SetStateAction<number>>
}) => {
    if (currentStep === 0) {
        return <div className="w-14 h-10 " />
    }
    return (
        <Button
            onClick={() => {
                setCurrentStep((prev) => prev - 1)
            }}
            variant="secondary"
        >
            Back
        </Button>
    )
}

export const renderNextButton = ({
    currentStep,
    setCurrentStep,
}: {
    currentStep: number
    setCurrentStep: Dispatch<SetStateAction<number>>
}) => {
    if (currentStep === 1) {
        return <div className="w-14 h-10 " />
    }
    return (
        <Button
            onClick={() => {
                setCurrentStep((prev) => prev + 1)
            }}
            variant="secondary"
        >
            {currentStep === 11 ? 'Finish' : 'Next'}
        </Button>
    )
}

interface OnboardingSelectDialogProps {
    open: boolean
    onSelectionChange: (selection: OnboardingSelection) => void
}

type OnboardingSelection = 'new' | 'experienced' | 'skip'

export const OnboardingSelectDialog: React.FC<OnboardingSelectDialogProps> = ({
    open,
    onSelectionChange,
}) => {
    const handleSelection = (selection: OnboardingSelection) => {
        onSelectionChange(selection)
    }

    return (
        <Dialog open={open}>
            <DialogContent
                hideCloseButton
                className="max-h-modal max-w-modal-lg bg-transparent border-none flex flex-col gap-10"
            >
                <p className="text-center text-pretty max-w-[662px] self-center text-7xl font-gilroy-medium text-white">
                    How do you want to get started?
                </p>
                <div className="grid grid-cols-2 gap-10 justify-center evenly">
                    <SelectionCard
                        icon={<OnboardingMapIcon className="self-center w-[100px] h-[100px]" />}
                        text="I'm new, show me around and help me get started."
                        onClick={() => handleSelection('new')}
                        className="bg-[radial-gradient(240.03%_134.39%_at_98.66%_96.88%,rgba(224,204,250,0.50)_0%,rgba(255,255,255,0.50)_43.45%,rgba(183,240,255,0.50)_100%),linear-gradient(194deg,#CAF2FF_18.92%,#ACACFF_90.74%)]"
                        textColor="text-primary"
                    />
                    <SelectionCard
                        icon={<OnboardingActionIcon className="self-center w-[100px] h-[100px]" />}
                        text="I'm experienced, help me better manage AI."
                        onClick={() => handleSelection('experienced')}
                        className="bg-[linear-gradient(242deg,rgba(255,255,255,0.20)_15.39%,rgba(100,70,235,0.20)_76.4%),radial-gradient(105.5%_110.81%_at_71.89%_0%,#0A458F_0%,#603FEC_26.5%,#64BDFE_66.86%,#FFF_100%)]"
                        textColor="text-white"
                    />
                </div>
                <Button
                    variant="outline"
                    className="w-18 self-end bg-transparent text-white mt-5 hover:text-black"
                    onClick={() => handleSelection('skip')}
                >
                    Dismiss
                </Button>
            </DialogContent>
        </Dialog>
    )
}

interface SelectionCardProps {
    icon: React.ReactNode
    text: string
    onClick: () => void
    className: string
    textColor: string
}

const SelectionCard: React.FC<SelectionCardProps> = ({
    icon,
    text,
    onClick,
    className,
    textColor,
}) => (
    <button
        type="button"
        onClick={onClick}
        className={`flex flex-row gap-[30px] grow h-64 max-w-[519px] hover:opacity-95 rounded-lg cursor-pointer justify-center items-center p-10 ${className}`}
    >
        {icon}
        <p className={`text-2xl font-gilroy-bold select-none w-64 text-start ${textColor}`}>
            {text}
        </p>
    </button>
)
