import Search from '@/assets/icons/search.svg?react'
import { t } from 'i18next'
import { Input } from '../ui/input'

export type paginationAndSortingParams = {
    projectId?: string
    pageNumber: number
    pageSize: number
    sortBy?: string
    sortDirection?: 'ASC' | 'DESC'
    filter?: string
}

export interface PaginationHeaderProps {
    filter: string
    setFilter: (filter: string) => void
}

const PaginationHeader = ({ filter, setFilter }: PaginationHeaderProps) => {
    return (
        <div className="flex flex-row gap-2">
            <Input
                autoFocus
                startIcon={Search}
                className="min-w-44 max-w-44 rounded-lg"
                placeholder={t('search')}
                value={filter}
                onChange={(e) => setFilter(e.target.value)}
            />
        </div>
    )
}

export default PaginationHeader
