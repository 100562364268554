import SelectOption from '@/components/settings/SelectOption.tsx'
import React, { useEffect, useState } from 'react'
import { Separator } from '@/components/ui/separator.tsx'
import { Button } from '@/components/ui/button.tsx'
import { downloadOperationsCsv } from '@/api/aiOperationsExecution.ts'
import { downloadCsv } from '@/lib/utils.ts'
import { startOfMonth, endOfMonth, subMonths, subDays } from 'date-fns'
import { DatePicker } from '@/components/ui/date-picker.tsx'
import {
    DropdownMenu,
    DropdownMenuContent,
    DropdownMenuItem,
    DropdownMenuTrigger,
} from '@/components/ui/dropdown-menu.tsx'
import { downloadExecutionsCsv, downloadGatewayCsv } from '@/api/feed'
import { downloadIngestionCsv } from '@/api/datasets'
import type { paginationAndSortingParams } from '@/components/pagination/FeedHeader'
import { ChevronDown } from 'lucide-react'

interface ExportFormProps {
    queryParams: paginationAndSortingParams & {
        startDate?: number | string
        endDate?: number | string
        projectIds?: string
    }
    filterCount: number
    totalCount: number
    type: 'ingestion' | 'gateway' | 'execution' | 'consumption' | 'processing'
}

const ExportForm: React.FC<ExportFormProps> = ({ filterCount, queryParams, totalCount, type }) => {
    const [scope, setScope] = useState<'current' | 'all' | 'custom'>('current')
    const [csvParams, setCsvParams] = useState<
        paginationAndSortingParams & {
            startDate?: number | string
            endDate?: number | string
        }
    >({ ...queryParams, pageSize: 1000 })
    const [start, setStart] = React.useState<Date | undefined>(undefined)
    const [end, setEnd] = React.useState<Date | undefined>(undefined)
    const [rangeOption, setRangeOption] = React.useState<
        'thisMonth' | 'lastMonth' | 'last90Days' | 'custom'
    >('thisMonth')

    const toUTC = (date: Date) => date.getTime()

    const getExportAPI = (
        type: string,
        csvParams: paginationAndSortingParams & {
            startDate?: string | number | undefined
            endDate?: string | number | undefined
        }
    ) => {
        switch (type) {
            case 'ingestion':
                return downloadIngestionCsv(csvParams)
            case 'gateway':
                return downloadGatewayCsv(csvParams)
            case 'execution':
                return downloadExecutionsCsv(csvParams)
            case 'consumption':
                return downloadOperationsCsv(
                    csvParams as paginationAndSortingParams & {
                        startDate?: number
                        endDate?: number
                        projectIds?: string
                    }
                )
            default:
                return downloadOperationsCsv(
                    csvParams as paginationAndSortingParams & {
                        startDate?: number
                        endDate?: number
                        projectIds?: string
                    }
                )
        }
    }

    // biome-ignore lint/correctness/useExhaustiveDependencies: <explanation>
    useEffect(() => {
        const now = new Date()
        let startDate: string | number | undefined
        let endDate: string | number | undefined

        if (scope === 'current') {
            startDate = queryParams.startDate ?? undefined
            endDate = queryParams.endDate ?? undefined
        } else {
            if (type !== 'consumption') {
                switch (rangeOption) {
                    case 'thisMonth':
                        startDate = startOfMonth(now).toISOString()
                        endDate = now.toISOString()
                        break
                    case 'lastMonth': {
                        const lastMonth = subMonths(now, 1)
                        startDate = startOfMonth(lastMonth).toISOString()
                        endDate = endOfMonth(lastMonth).toISOString()
                        break
                    }
                    case 'last90Days':
                        startDate = startOfMonth(subDays(now, 90)).toISOString()
                        endDate = now.toISOString()
                        break
                    case 'custom':
                        startDate = start ? start.toISOString() : undefined
                        endDate = end ? end.toISOString() : undefined
                        break
                    default:
                        startDate = undefined
                        endDate = undefined
                }
            } else {
                switch (rangeOption) {
                    case 'thisMonth':
                        startDate = toUTC(startOfMonth(now))
                        endDate = undefined
                        break
                    case 'lastMonth': {
                        const lastMonth = subMonths(now, 1)
                        startDate = toUTC(startOfMonth(lastMonth))
                        endDate = toUTC(endOfMonth(lastMonth))
                        break
                    }
                    case 'last90Days':
                        startDate = toUTC(startOfMonth(subDays(now, 90)))
                        endDate = undefined
                        break
                    case 'custom':
                        startDate = start ? toUTC(start) : undefined
                        endDate = end ? toUTC(end) : undefined
                        break
                    default:
                        startDate = undefined
                        endDate = undefined
                }
            }
        }

        if (startDate && endDate) {
            //@ts-ignore
            setCsvParams((prev) => ({
                ...prev,
                startDate,
                endDate,
            }))
        }
    }, [rangeOption, start, end])

    const onExportClick = () => {
        getExportAPI(type, csvParams).then((data) => {
            downloadCsv(data, `operations-${Date.now()}.csv`)
        })
    }

    const getDropdownTriggerLabel = () => {
        switch (rangeOption) {
            case 'thisMonth':
                return 'Current Month'
            case 'lastMonth':
                return 'Previous Month'
            case 'last90Days':
                return 'Past 90 Days'
            case 'custom':
                return 'Custom Date Range'
            default:
                return 'Select a range'
        }
    }

    return (
        <div className={'flex flex-col gap-2'}>
            <div className={'flex flex-col gap-2 p-4'}>
                <span>Export Scope</span>
                <div className={'flex gap-4'}>
                    <SelectOption
                        checked={scope === 'current'}
                        value={'current'}
                        label={'Current Page'}
                        onChange={(value) => setScope(value as 'current')}
                    />
                    <SelectOption
                        checked={scope === 'custom'}
                        value={'custom'}
                        label={'Custom Date Range'}
                        onChange={(value) => setScope(value as 'custom')}
                    />
                </div>
            </div>
            {scope === 'current' && (
                <span className={'text-sm p-4 flex flex-row gap-1'}>
                    Export will include the <p>{totalCount}</p> transactions with{' '}
                    <p>{filterCount}</p> filters applied.
                </span>
            )}
            {scope === 'custom' && (
                <div className={'flex flex-col gap-2 p-4'}>
                    <span className={'text-sm'}>
                        Export will include the most recent <b>1000 transactions</b> within the
                        following date range:
                    </span>
                    <span>Range</span>
                    <div className={'flex gap-4'}>
                        <DropdownMenu>
                            <DropdownMenuTrigger>
                                <Button variant={'outline'}>
                                    {getDropdownTriggerLabel()} <ChevronDown />
                                </Button>
                            </DropdownMenuTrigger>
                            <DropdownMenuContent>
                                <DropdownMenuItem onClick={() => setRangeOption('thisMonth')}>
                                    Current Month
                                </DropdownMenuItem>
                                <DropdownMenuItem onClick={() => setRangeOption('lastMonth')}>
                                    Previous Month
                                </DropdownMenuItem>
                                <DropdownMenuItem onClick={() => setRangeOption('last90Days')}>
                                    Past 90 Days
                                </DropdownMenuItem>
                                <DropdownMenuItem onClick={() => setRangeOption('custom')}>
                                    Custom Date Range
                                </DropdownMenuItem>
                            </DropdownMenuContent>
                        </DropdownMenu>
                        {rangeOption === 'custom' && (
                            <DatePicker placeholder={'Select start date'} onDateSelect={setStart} />
                        )}{' '}
                        {rangeOption === 'custom' && (
                            <DatePicker placeholder={'Select end date'} onDateSelect={setEnd} />
                        )}
                    </div>
                </div>
            )}
            <Separator />
            <div className={'flex justify-end gap-6'}>
                <Button onClick={() => onExportClick()}>Export</Button>
            </div>
        </div>
    )
}

export default ExportForm
