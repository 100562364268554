import { createFileRoute, type SearchSchemaInput } from '@tanstack/react-router'
import type { paginationAndSortingParams } from '@/components/pagination/paginationHeader.tsx'

export const Route = createFileRoute('/_mainLayout/settings/billing')({
    validateSearch: (
        search: Record<string, unknown> & SearchSchemaInput
    ): paginationAndSortingParams => {
        return {
            pageNumber: search.pageNumber as number,
            pageSize: search.pageSize as number,
            sortBy: search.sortBy as string,
        }
    },
})
