import type { paginationAndSortingParams } from '@/components/pagination/FeedHeader'
import apiRequest from '@/lib/apiClient'
export interface FeedResult {
    id: string
    source: string
    apiKeyName: string
    streamed: boolean
    duration: string
    requestModel: string
    responseModel: string
    providerType: string
    promptTokens: number
    completionTokens: number
    totalTokens: number
    clientIpAddress?: string
    userAgent?: string
    tags?: string[]
}

export interface PipelineFeedResult {
    id: string
    pipelineId: string
    pipelineExecutionName: string
    pipelineName?: string
    pipelineVersionId?: string
    pipelineVersionNumber?: number
    temporary: boolean
    createdAt: string
    streamed: boolean
    duration: string
    requestModel: string
    responseModel: string
    providerType: string
    promptTokens: number
    completionTokens: number
    totalTokens: number
    tags: string[]
    apiKeyName: string
    projectId: string
    systemPrompt?: string
    systemPromptVersionId?: string
    systemPromptVersionNumber?: string
    success: boolean
    executionSteps: ExecutionSteps[]
}

export interface ExecutionSteps {
    modelName: string
    provider: string
    inputTokens: number
    outputTokens: number
    apiKeyName: string
    systemPrompt?: string
    systemPromptVersionId?: string
    systemPromptVersionNumber?: string
}

export interface GatewayFeedResult {
    id: string
    tenantId: string
    projectId?: string
    createdAt: string
    apiKeyName?: string
    streamed: boolean
    duration: string
    requestModel?: string
    responseModel?: string
    providerType: string
    responseProviderType: string
    promptTokens: number
    completionTokens: number
    totalTokens: number
    userAgent?: string
    clientIpAddress?: string
}

export interface DataVectorSearchExecution {
    createdAt: string
    tenantId: string
    executionId: string
    executionSourceType: string
    pipelineId: string
    pipelineVersion: string
    projectId: string
    retrievalType: string
    inputType?: string
    vectorSearchQueryResultsCount: number
    vectorSearchResponseResultsCount: number
    vectorSearchResponseResultsSizeInMb: number
    databaseReadUnits?: string
    databaseReadUnitsPrice?: string
    databaseWriteUnitsPrice?: string
    balanceUsed?: string
    embeddingProvider: string
    totalEmbeddingTokensUsed: number
    totalEmbeddingsTokenAmountConsumed: string
    databaseProvider: string
    projectName: string
    pipelineName: string
    duration: string
}

export interface StepExecutionContext {
    [key: string]: {
        stepId: string
        success: boolean
    }
}

export interface PipelineFeedResultList {
    items: PipelineFeedResult[]
    totalCount: number
}

export interface GatewayFeedResultList {
    items: GatewayFeedResult[]
    totalCount: number
}

export const getPipelineFeed = async (
    queryParams?: Record<string, string | number> | undefined
): Promise<PipelineFeedResultList> => {
    if (queryParams?.projectId === null) {
        // biome-ignore lint/performance/noDelete: <explanation>
        delete queryParams?.projectId
    }
    return apiRequest('/v1/Feed/pipelines', { queryParams })
}

export const getPipelineFeedDetails = async (id: string): Promise<StepExecutionContext[]> => {
    return apiRequest(`/v1/Feed/pipelines/${id}`)
}

export const getGatewayFeed = async (
    queryParams?: Record<string, string | number> | undefined
): Promise<GatewayFeedResultList> => {
    if (queryParams?.projectId == null) {
        // biome-ignore lint/performance/noDelete: <explanation>
        delete queryParams?.projectId
    }
    return apiRequest('/v1/Feed/gateway', { queryParams })
}

export const getGatewayFeedDetails = async (id: string): Promise<StepExecutionContext[]> => {
    return apiRequest(`/v1/Feed/gateway/${id}`)
}

export const getDataProcessingFeed = async (
    queryParams?: paginationAndSortingParams
): Promise<{
    items: DataVectorSearchExecution[]
    totalCount: number
}> => {
    return apiRequest('/api/marketplace/v1/DataVectorSearchExecutions', { queryParams })
}

export const downloadExecutionsCsv = (
    queryParams?: paginationAndSortingParams & {
        startTime?: number
        endTime?: number
        projectIds?: string
    }
): Promise<string> => {
    return apiRequest('/v1/Feed/pipelines/export/csv', {
        headers: {
            'Content-Type': 'text/csv',
        },
        queryParams: queryParams,
    })
}

export const downloadGatewayCsv = (
    queryParams?: paginationAndSortingParams & {
        startTime?: number
        endTime?: number
        projectIds?: string
    }
): Promise<string> => {
    return apiRequest('/v1/Feed/gateway/export/csv', {
        headers: {
            'Content-Type': 'text/csv',
        },
        queryParams: queryParams,
    })
}
