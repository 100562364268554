import Check from '@/assets/icons/check.svg?react'
import { Button } from '@/components/ui/button.tsx'
import React, { ReactElement } from 'react'

interface PageBannerProps {
    title: string
    icon: ReactElement
    onButtonClick: () => void
}

const PageBanner: React.FC<PageBannerProps> = ({ onButtonClick, icon, title }) => {
    return (
        <div
            className={
                'w-full bg-gradient-to-r from-[#F3F9FF] to-[#DDDBFF] flex justify-between px-32 py-16'
            }
        >
            <div className={'flex flex-col justify-between'}>
                <h1 className={'text-5xl text-black'}>{title}</h1>
                <div>
                    <Button onClick={() => onButtonClick()}>
                        Got it
                        <Check className={'ml-2 h-4 w-4'} />
                    </Button>
                </div>
            </div>
            <div>{icon}</div>
        </div>
    )
}

export default PageBanner
