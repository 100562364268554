import React from 'react'
import { SideNavItem } from './SideNavItem'
import { useTour } from '@reactour/tour'
import { Project } from '@/api/projects'
import { Submenu } from './MenuList'
import { useSideNavStore } from '@/stores/SideNavStore'
import { ExternalLink, Pin } from 'lucide-react'
import { cn, getChatUrl } from '@/lib/utils'

export type CustomSubMenuItemProps = {
    menuLabel: string
    subMenu: Submenu
    visibleProjects: Project[]
}

export const CustomSubMenuItem: React.FC<CustomSubMenuItemProps> = ({
    menuLabel,
    subMenu,
    visibleProjects,
}) => {
    const { path, params, id, disabled, label } = subMenu
    const { setCurrentStep, isOpen: isTourOpen } = useTour()
    const { setRecentProjectIds, recentProjectIds } = useSideNavStore()
    const chatUrl = getChatUrl()

    switch (menuLabel) {
        case 'Projects': {
            const project = visibleProjects?.find((project) => project.id === id)
            if (!project) return null

            const RECENT_PROJECT_IDS_KEY = 'recentProjectIds'
            const LAST_ACCESSED_PROJECT_KEY = 'lastAccessedProject'

            const updateRecentProjects = (selectedProjectId: string) => {
                const updatedRecent = [
                    selectedProjectId,
                    ...recentProjectIds.filter((id) => id !== selectedProjectId),
                ].slice(0, 5)

                setRecentProjectIds(updatedRecent)

                localStorage.setItem(RECENT_PROJECT_IDS_KEY, JSON.stringify(updatedRecent))
                localStorage.setItem(LAST_ACCESSED_PROJECT_KEY, selectedProjectId)
            }

            return (
                <SideNavItem
                    disabled={disabled}
                    path={path}
                    params={params}
                    key={label}
                    title={label}
                    className="ml-4"
                    endAdornment={
                        project && project.isPinned ? (
                            <Pin className="w-4 h-4 text-primary-900" />
                        ) : undefined
                    }
                    onClick={() => {
                        isTourOpen && setCurrentStep((currentStep) => currentStep + 1)
                        updateRecentProjects(id ?? '')
                    }}
                />
            )
        }
        case 'Apps': {
            return (
                <a
                    className={cn(
                        'flex rounded-[8px] justify-between items-center text-sm hover:bg-primary-100 transition-colors px-2 h-8 ml-4 select-none'
                    )}
                    href={chatUrl}
                    target="_blank"
                    rel="noreferrer"
                >
                    Airia Chat
                    <ExternalLink className="w-4 h-4" />
                </a>
            )
        }
        default:
            return null
    }
}
