import { Credential, CredentialType } from '@/api/credentials.ts'

export const credentialForm: CredentialFormBuilder = {
    OpenAI: {
        fields: [
            {
                label: 'OpenAI API Key',
                name: 'OpenAIAPIKey',
                type: 'text',
                required: true,
            },
        ],
    },
    Ollama: {
        fields: [
            {
                label: 'Ollama API Key',
                name: 'OllamaAPIKey',
                type: 'text',
                required: true,
            },
        ],
    },
    Azure: {
        fields: [
            {
                label: 'Azure API Key',
                name: 'AzureAPIKey',
                type: 'text',
                required: true,
            },
        ],
    },
    HuggingFace: {
        fields: [
            {
                label: 'Hugging Face API Key',
                name: 'HuggingFaceAPIKey',
                type: 'text',
                required: true,
            },
        ],
    },
    Anthropic: {
        fields: [
            {
                label: 'Anthropic API Key',
                name: 'AnthropicAPIKey',
                type: 'text',
                required: true,
            },
        ],
    },
    AzureAIStudio: {
        fields: [
            {
                label: 'Azure AI Studio API Key',
                name: 'AzureAIStudioApiKey',
                type: 'text',
                required: true,
            },
        ],
    },
    OpenVINO: {
        fields: [
            {
                label: 'OpenVINO API Key',
                name: 'OpenVinoAPIKey',
                type: 'text',
                required: true,
            },
        ],
    },
    PyTorch: {
        fields: [
            {
                label: 'PyTorch API Key',
                name: 'PyTorchAPIKey',
                type: 'text',
                required: true,
            },
        ],
    },
    AzureBlob: {
        fields: [
            {
                label: 'Azure Blob API Key',
                name: 'AzureBlobAPIKey',
                type: 'text',
                required: true,
            },
        ],
    },
    S3: {
        fields: [
            {
                label: 'S3 API Key',
                name: 'S3APIKey',
                type: 'text',
                required: true,
            },
        ],
    },
    ScaleFlex: {
        fields: [
            {
                label: 'ScaleFlex API Key',
                name: 'ScaleFlexAPIKey',
                type: 'text',
                required: true,
            },
        ],
    },
    Replicate: {
        fields: [
            {
                label: 'Replicate API Key',
                name: 'ReplicateAPIKey',
                type: 'text',
                required: true,
            },
        ],
    },
    Stability: {
        fields: [
            {
                label: 'Stability API Key',
                name: 'StabilityAPIKey',
                type: 'text',
                required: true,
            },
        ],
    },
    Mistral: {
        fields: [
            {
                label: 'Mistral API Key',
                name: 'MistralAPIKey',
                type: 'text',
                required: true,
            },
        ],
    },
    Perplexity: {
        fields: [
            {
                label: 'Perplexity API Key',
                name: 'PerplexityAPIKey',
                type: 'text',
                required: true,
            },
        ],
    },
    WatsonAix: {
        fields: [
            {
                label: 'WatsonAix API Key',
                name: 'WatsonAPIKey',
                type: 'text',
                required: true,
            },
        ],
    },
    AI21: {
        fields: [
            {
                label: 'AI21 API Key',
                name: 'AI21APIKey',
                type: 'text',
                required: true,
            },
        ],
    },
    Cohere: {
        fields: [
            {
                label: 'Cohere API Key',
                name: 'CohereAPIKey',
                type: 'text',
                required: true,
            },
        ],
    },
    Google: {
        fields: [
            {
                label: 'Google API Key',
                name: 'GoogleAPIKey',
                type: 'text',
                required: true,
            },
        ],
    },
    Pinecone: {
        fields: [
            {
                label: 'Pinecone API Key',
                name: 'PineconeAPIKey',
                type: 'text',
                required: true,
            },
        ],
    },
    TogetherAI: {
        fields: [
            {
                label: 'Together AI API Key',
                name: 'TogetherAIAPIKey',
                type: 'text',
                required: true,
            },
        ],
    },
}

export const getApiKey = (credential: Credential) => {
    let apiKey = ''
    switch (credential.type) {
        case CredentialType.OpenAI:
            apiKey = credential.credentialData.find((c) => c.key === 'OpenAIAPIKey')?.value ?? ''
            break
        case CredentialType.Ollama:
            apiKey = credential.credentialData.find((c) => c.key === 'OllamaAPIKey')?.value ?? ''
            break
        case CredentialType.Anthropic:
            apiKey = credential.credentialData.find((c) => c.key === 'AnthropicAPIKey')?.value ?? ''
            break
        case CredentialType.Mistral:
            apiKey = credential.credentialData.find((c) => c.key === 'MistralAPIKey')?.value ?? ''
            break
        case CredentialType.Perplexity:
            apiKey =
                credential.credentialData.find((c) => c.key === 'PerplexityAPIKey')?.value ?? ''
            break
        case CredentialType.AzureAIStudio:
            apiKey =
                credential.credentialData.find((c) => c.key === 'AzureAIStudioApiKey')?.value ?? ''
            break
        case CredentialType.OpenVINO:
            apiKey = credential.credentialData.find((c) => c.key === 'OpenVinoAPIKey')?.value ?? ''
            break
        case CredentialType.PyTorch:
            apiKey = credential.credentialData.find((c) => c.key === 'PyTorchAPIKey')?.value ?? ''
            break
        case CredentialType.Replicate:
            apiKey = credential.credentialData.find((c) => c.key === 'ReplicateAPIKey')?.value ?? ''
            break
        case CredentialType.Stability:
            apiKey = credential.credentialData.find((c) => c.key === 'StabilityAPIKey')?.value ?? ''
            break
        case CredentialType.WatsonAix:
            apiKey = credential.credentialData.find((c) => c.key === 'WatsonAPIKey')?.value ?? ''
            break
        case CredentialType.AI21:
            apiKey = credential.credentialData.find((c) => c.key === 'AI21APIKey')?.value ?? ''
            break
        case CredentialType.Cohere:
            apiKey = credential.credentialData.find((c) => c.key === 'CohereAPIKey')?.value ?? ''
            break
        case CredentialType.Google:
            apiKey = credential.credentialData.find((c) => c.key === 'GoogleAPIKey')?.value ?? ''
            break
        case CredentialType.Pinecone:
            apiKey = credential.credentialData.find((c) => c.key === 'PineconeAPIKey')?.value ?? ''
            break
        case CredentialType.TogetherAI:
            apiKey =
                credential.credentialData.find((c) => c.key === 'TogetherAIAPIKey')?.value ?? ''
            break

        default:
            break
    }
    return apiKey
}

// Keys should match identically to the CredentialType enum in src/api/credentials.ts
export interface CredentialFormBuilder {
    OpenAI: CredentialForm
    Ollama: CredentialForm
    Azure: CredentialForm
    HuggingFace: CredentialForm
    Anthropic: CredentialForm
    AzureAIStudio: CredentialForm
    OpenVINO: CredentialForm
    PyTorch: CredentialForm
    AzureBlob: CredentialForm
    S3: CredentialForm
    ScaleFlex: CredentialForm
    Replicate: CredentialForm
    Stability: CredentialForm
    Mistral: CredentialForm
    Perplexity: CredentialForm
    WatsonAix: CredentialForm
    AI21: CredentialForm
    Cohere: CredentialForm
    Google: CredentialForm
    Pinecone: CredentialForm
    TogetherAI: CredentialForm
}

export interface CredentialForm {
    fields: CredentialFormField[]
}

export interface CredentialFormField {
    label: string
    name: string
    type: 'text' | 'password'
    required: boolean
}
