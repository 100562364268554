import { PaginationState, Table } from '@tanstack/react-table'

import { Button } from '@/components/ui/button'
import {
    Select,
    SelectContent,
    SelectItem,
    SelectTrigger,
    SelectValue,
} from '@/components/ui/select'
import { Dispatch, SetStateAction } from 'react'
import { ChevronLeft, ChevronRight } from 'lucide-react'

interface DataTablePaginationProps<TData> {
    table: Table<TData>
    rowsPerPage?: number
    totalCount?: number
    setPagination?: Dispatch<SetStateAction<PaginationState>>
}

export function DataTablePagination<TData>({
    table,
    rowsPerPage,
    totalCount,
    setPagination,
}: DataTablePaginationProps<TData>) {
    const pageCount = Math.ceil((totalCount ?? 0) / table.getState().pagination.pageSize)

    return (
        <div className={'flex justify-between items-center mb-2 py-2'}>
            <div className={'flex'}>
                {!rowsPerPage ||
                    (setPagination && (
                        <div className={'flex gap-2 items-center'}>
                            <p className={'text-accent-foreground text-sm whitespace-nowrap'}>
                                Rows per page
                            </p>
                            <Select
                                value={`${table.getState().pagination.pageSize}`}
                                onValueChange={(value) => {
                                    setPagination
                                        ? setPagination((prev) => ({
                                              ...prev,
                                              pageSize: Number(value),
                                          }))
                                        : table.setPageSize(Number(value))
                                }}
                            >
                                <SelectTrigger>
                                    <SelectValue
                                        placeholder={table.getState().pagination.pageSize}
                                    />
                                </SelectTrigger>
                                <SelectContent side="top">
                                    {[10, 25, 50, 100].map((pageSize) => (
                                        <SelectItem key={pageSize} value={`${pageSize}`}>
                                            {pageSize}
                                        </SelectItem>
                                    ))}
                                </SelectContent>
                            </Select>
                        </div>
                    ))}
            </div>
            <div className={'flex items-center gap-4'}>
                <span className={'whitespace-nowrap text-sm'}>
                    Page {totalCount !== 0 ? table.getState().pagination.pageIndex + 1 : 0} of{' '}
                    {pageCount}
                </span>
                <div className={'flex items-center'}>
                    <Button
                        variant="ghost"
                        type="button"
                        onClick={() => table.previousPage()}
                        disabled={!table.getCanPreviousPage()}
                    >
                        <span className="sr-only fixed">Go to previous page</span>
                        <ChevronLeft className={'h-4 w-4'} />
                    </Button>
                    <Button
                        variant="ghost"
                        type="button"
                        onClick={() => table.nextPage()}
                        disabled={
                            table.getState().pagination.pageIndex === pageCount - 1 ||
                            totalCount === 0
                        }
                    >
                        <span className="sr-only fixed">Go to next page</span>
                        <ChevronRight className={'h-4 w-4'} />
                    </Button>
                </div>
            </div>
        </div>
    )
}
