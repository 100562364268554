import React from 'react'
import { Plus } from 'lucide-react'
import { useQueryClient } from '@tanstack/react-query'
import { useNavigate } from '@tanstack/react-router'
import { CreateNewProject } from '../administration/CreateNewProject'
import { QueryKeys } from '@/constants/QueryKeys'

export type CustomMenuActionProps = {
    menuLabel: string
}

const CustomMenuAction: React.FC<CustomMenuActionProps> = ({ menuLabel }) => {
    const queryClient = useQueryClient()
    const navigate = useNavigate()

    switch (menuLabel) {
        case 'Projects': {
            return (
                <CreateNewProject
                    variant={'ghost'}
                    size="custom"
                    customSizeClassName="h-4 w-4 p-0 text-black"
                    Icon={Plus}
                    onSuccess={() => {
                        queryClient.invalidateQueries({
                            queryKey: [QueryKeys.PROJECTS],
                        })
                        navigate({ to: '/' })
                    }}
                />
            )
        }
        default:
            return null
    }
}

export default CustomMenuAction
