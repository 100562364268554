import { getAllApiKeys } from '@/api/apiKeys'
import { uploadImage } from '@/api/attachments'
import { GetAllDatasets } from '@/api/datasets'
import { getAllMemory } from '@/api/memory'
import { GetAllModels } from '@/api/models'
import { getAllPipelines } from '@/api/pipelinesConfig'
import { type Project, getProjectById, updateProject } from '@/api/projects'
import { getAllPrompts } from '@/api/prompts'
import NodeTable from '@/components/administration/NodeTable'
import {
    Accordion,
    AccordionContent,
    AccordionItem,
    AccordionTrigger,
} from '@/components/ui/accordion'
import { Button } from '@/components/ui/button'
import { CardContent } from '@/components/ui/card'
import { toast } from '@/components/ui/use-toast'
import { QueryKeys } from '@/constants/QueryKeys.ts'
import { useQuery, useQueryClient } from '@tanstack/react-query'
import { createFileRoute, useRouter } from '@tanstack/react-router'
import { Check, CircleSlash2, Pencil, Upload, X } from 'lucide-react'
import { type ChangeEvent, useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import { useTranslation } from '@/lib/i18n'
import DefaultProjectIcon from '@/assets/icons/projects/default-project-icon.svg?react'

export const Route = createFileRoute('/_mainLayout/projects/$projectId/edit')({
    loader: async ({ params }) => getProjectById(params.projectId),
    component: () => {
        const params = Route.useParams()
        return <EditProject key={`edit-project-${params.projectId}`} />
    },
})

function EditProject() {
    const Project = Route.useLoaderData()
    const objectsArray = [
        { name: 'pipelines', value: 'pipelines' },
        { name: 'models', value: 'models' },
        { name: 'data_sources', value: 'dataSources' },
        { name: 'prompts', value: 'prompts' },
        { name: 'api_keys', value: 'apiKeys' },
        { name: 'memory', value: 'memories' },
    ]
    const { register, handleSubmit, getValues, setValue } = useForm<Project>({
        defaultValues: {
            ...Project,
            models: [],
            memories: [],
            dataSources: [],
            pipelines: [],
            apiKeys: [],
            prompts: [],
            credentials: [],
        },
    })
    const [editName, setEditName] = useState(false)
    const [editDescription, setEditDescription] = useState(false)
    const { t } = useTranslation()
    const router = useRouter()

    const queryClient = useQueryClient()

    // Queries
    const ModelQuery = useQuery({
        queryKey: [QueryKeys.ALL_MODELS, Project.id],
        queryFn: () => GetAllModels({ projectId: Project.id }),
    })

    const MemoryQuery = useQuery({
        queryKey: [QueryKeys.ALL_MEMORY, Project.id],
        queryFn: () => getAllMemory({ projectId: Project.id }),
    })
    const DatasetsQuery = useQuery({
        queryKey: [QueryKeys.ALL_DATASETS, Project.id],
        queryFn: () => GetAllDatasets({ projectId: Project.id }),
    })
    const PipelinesQuery = useQuery({
        queryKey: [QueryKeys.ALL_PIPELINES, Project.id],
        queryFn: async () => getAllPipelines({ projectId: Project.id }),
    })
    const ApiKeysQuery = useQuery({
        queryKey: [QueryKeys.ALL_API_KEYS, Project.id],
        queryFn: () => getAllApiKeys({ projectId: Project.id }),
    })
    const PromptsQuery = useQuery({
        queryKey: [QueryKeys.ALL_PROMPTS, Project.id],
        queryFn: () => getAllPrompts({ projectId: Project.id }),
    })

    const refresh = async () => {
        await queryClient.invalidateQueries({ queryKey: [QueryKeys.PROJECTS] })
        await router.invalidate()
        setEditDescription(false)
        setEditName(false)
    }
    const onBack = () => router.history.back()

    const [fileToUpload, setFileToUpload] = useState<{ file: File; previewUrl: string }>()

    const getQueryData = (type: string) => {
        switch (type) {
            case 'pipelines':
                return PipelinesQuery.data?.items
            case 'models':
                return ModelQuery.data?.items
            case 'dataSources':
                return DatasetsQuery.data?.items
            case 'prompts':
                return PromptsQuery.data?.items
            case 'apiKeys':
                return ApiKeysQuery.data?.items
            case 'memories':
                return MemoryQuery.data?.items
            default:
                return null
        }
    }

    const handleAddIcon = async (e: ChangeEvent<HTMLInputElement>) => {
        if (e.target.files) {
            const filesArray = Array.from(e.target.files)
            const newItems = filesArray.map((file) => ({
                file,
                previewUrl: URL.createObjectURL(file),
            }))
            setFileToUpload(newItems[0])
            const res = await uploadImage({ file: newItems[0].file })
            await updateProject({
                ...getValues(),
                projectIconId: res.id,
            })
            setValue('projectIconId', res.id)
        }
    }

    useEffect(() => {
        refresh()
    }, [])

    const onSubmit = async (data: Project) => {
        try {
            await updateProject({
                ...data,
            })
        } catch (e) {
            console.log(e)
            toast({
                title: 'Error',
                description: 'The project could not be updated, please try again',
                variant: 'destructive',
            })
        }
        refresh()
    }

    return (
        <CardContent>
            <form onSubmit={handleSubmit(onSubmit)}>
                <div className="w-full flex flex-col gap-3">
                    <div>
                        {editName ? (
                            <div className="flex flex-row content-center items-center justify-start gap-5">
                                <input
                                    placeholder="Project Name"
                                    id="name"
                                    className="border-b-2 border-gray-300 focus:border-slate-500 text-xl block bg-transparent focus:outline-none"
                                    {...register('name')}
                                    required
                                />
                                <div className="flex flex-row gap-2">
                                    <Button size="icon" variant="outline">
                                        <Check color="green" className="h-4 w-4" />
                                    </Button>
                                    <Button
                                        size="icon"
                                        variant="outline"
                                        type="button"
                                        onClick={() => setEditName(false)}
                                    >
                                        <CircleSlash2 color="red" className="h-4 w-4" />
                                    </Button>
                                </div>
                            </div>
                        ) : (
                            <div className="flex flex-row content-center items-center justify-between gap-5">
                                <div className="flex flex-row items-center gap-5">
                                    <p className="text-xl">{Project.name}</p>
                                    <Button
                                        size="icon"
                                        variant="outline"
                                        type="button"
                                        onClick={() => setEditName(true)}
                                    >
                                        <Pencil className="h-4 w-4" />
                                    </Button>
                                </div>
                                <Button type="button" variant={'ghost'} onClick={onBack}>
                                    <X />
                                </Button>
                            </div>
                        )}
                    </div>
                    <div>
                        {editDescription ? (
                            <div className="flex flex-row content-center items-center justify-start gap-5">
                                Description:
                                <textarea
                                    id="description"
                                    className="border-b-2 border-gray-300 focus:border-slate-500 text-lg block bg-transparent focus:outline-none w-1/3"
                                    {...register('description')}
                                    required
                                />
                                <div className="flex flex-row gap-2">
                                    <Button size="icon" variant="outline">
                                        <Check color="green" className="h-4 w-4" />
                                    </Button>
                                    <Button
                                        size="icon"
                                        variant="outline"
                                        type="button"
                                        onClick={() => setEditDescription(false)}
                                    >
                                        <CircleSlash2 color="red" className="h-4 w-4" />
                                    </Button>
                                </div>
                            </div>
                        ) : (
                            <div className="flex flex-row content-center items-center justify-start gap-5">
                                <p className="font-bold text-md">Description: </p>
                                <p className="text-md w-1/3">{Project.description}</p>
                                <Button
                                    size="icon"
                                    variant="outline"
                                    type="button"
                                    onClick={() => setEditDescription(true)}
                                >
                                    <Pencil className="h-4 w-4" />
                                </Button>
                            </div>
                        )}
                    </div>
                    <div>
                        <h3 className="text-lg">Icon: </h3>
                        <div className="flex flex-row gap-2 items-center">
                            {Project.projectIcon || fileToUpload?.previewUrl ? (
                                <img
                                    src={fileToUpload?.previewUrl ?? Project?.projectIcon}
                                    alt={t('imgAlt.airiaLogo')}
                                    className=" w-16 h-16 rounded-md object-cover"
                                />
                            ) : (
                                <DefaultProjectIcon className="w-16 h-16" />
                            )}
                            <input
                                type="file"
                                id="appIconUpload"
                                style={{ display: 'none' }}
                                onChange={handleAddIcon}
                            />

                            <Button
                                variant="ghost"
                                onClick={() => document?.getElementById('appIconUpload')?.click()}
                            >
                                <Upload />
                            </Button>
                        </div>
                    </div>
                    <div>
                        <Accordion type="multiple" className="w-full">
                            {objectsArray.map((object) => (
                                <AccordionItem value={object.value} key={object.name}>
                                    <AccordionTrigger className="">
                                        <div className="flex flex-row gap-1">
                                            <span>{object.name}</span>
                                        </div>
                                    </AccordionTrigger>
                                    <AccordionContent>
                                        {object.value !== 'apiKeys' && (
                                            <div className="flex justify-end mb-2 p-1"></div>
                                        )}
                                        <NodeTable
                                            nodeType={object.value}
                                            //@ts-ignore
                                            data={getQueryData(object.value)}
                                        />
                                    </AccordionContent>
                                </AccordionItem>
                            ))}
                        </Accordion>
                    </div>
                </div>
            </form>
        </CardContent>
    )
}
